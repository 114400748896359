import { QuestionAndAnswer } from "./content-types";

export const questionsAndAnswers: QuestionAndAnswer[] = [
  {
    question: "What do these reforms mean for Māori and iwi? / Te Reo translation",
    answerParagraphs: [
      {
        parts: [
          "The government decided to reform the way council managed three water services following an outbreak of gastroenteritis present in drinking water in Havelock North in 2016. Government introduced in 2020 the Water Services Bill to which iwi and hapū raised several issues including impact on Marae by way of compliance, access to and loss of control of puna. Whilst the reforms are generally viewed as an improvement on the current system, it is integral the transition provides for the views of iwi, hapu and marae to ensure customary use and right is protected and transformation through our values systems enabled. ",
          "The reforms seek to address long-term affordability and equitable access issues across all communities in terms of the delivery of drinking water, storm water and wastewater services. ",
          {
            text: "Read more here.",
            url: "https://www.dia.govt.nz/Water-services-reform-iwi-maori-interests",
          },
        ],
      },
    ],
  },
  {
    question: "Who is Te Manawa o Ngā Wai and how can you help us?",
    answerParagraphs: [
      {
        parts: [
          "Te Manawa o Nga Wai are the Iwi Secretariat for the (previous) Entity B tasked with keeping iwi, hapū and whānau informed of the changes under the reforms through their respective iwi entity. We also seek to understand the priorities and issues of our people, advocate and identify opportunities of participation. ",
          "For iwi entities we provide technical support and regular communications to keep you informed of the transition as it relates to legislation changes and issues that may impact your respective hapu and marae. The current representation model also provides for a collaborative approach to the reforms alongside other iwi within the water services area. We also have funding available to assist your entity to engage with your hapū and marae, develop Te Mana o Te Wai statements and provide technical training. ",
          "If you’re a hapū entity we are able to promote your values and three water needs at place within the reform programme. We recognise the importance of hapū centric models to inform the strategic direction and implementation of the water services entities. There are also opportunities to participate in the reforms through your respective iwi including in the development of te mana o te wai statements and identifying issues and opportunities for your Marae. ",
          "If you’re a marae we have provided funding through your iwi entity to undertake water infrastructure needs assessments to gauge the current state of your marae. This data can then assist your trust or your iwi entity to advocate for funding to enable infrastructure improvements and upgrades for your Marae. We are also in the process of identifying best practice and sustainable water solutions for Marae and communities.",
        ],
      },
    ],
  },
  {
    question: "What about our marae and papakāinga in rural areas?",
    answerParagraphs: [
      {
        parts: [
          "Many Māori rural communities have a long history of having poor water services. ",
          {
            text: "Marae and Papkāinga rural water services advisory group",
            url: "https://www.dia.govt.nz/Water-services-reform-Marae-and-Papakainga-Rural-Water-Services-Technical-Advisory-Group",
          },
          " has been set up to advise about challenges that rural Māori communities have had in accessing water services, especially drinking water. The group will help guide water service entities to fulfill their roles and duties, including their obligations to Māori communities, and identify opportunities for inequities to be addressed by water services entities, Taumata Arowai and central government. It also includes our own Waiariki whānau Joe Tahana and Des Heke who were elected onto the roopu by Te Manawa o Nga Wai representatives. The outcomes from this roopu along with the information and data from the Marae Infrastructure needs assessments will help inform sound advice and lobbying for the upgrades and access to safe drinking water for our Marae. ",
          {
            text: "Read more here.",
            url: "https://www.dia.govt.nz/Water-services-reform-iwi-maori-interests#Marae-and-Papakainga",
          },
        ],
      },
    ],
  },
  {
    question: "Who is Taumata Arowai and what do they do?",
    answerParagraphs: [
      {
        parts: [
          "Taumata Arowai is the new water services regulator for Aotearoa New Zealand committed to ensuring all communities have access to safe and reliable drinking water. Their role is to set standards and make sure that drinking water suppliers provide safe drinking water. They also monitor and report on the environmental performance of wastewater and stormwater networks. ",
          {
            text: "Read more here.",
            url: "https://www.taumataarowai.govt.nz/",
          },
        ],
      },
    ],
  },
  {
    question: "How can I receive updates on your mahi?",
    answerParagraphs: [
      {
        parts: [
          "It is important for us that all our whanau hapu and Iwi are kept informed of how these reforms impact you at place. In the first instance we recommend you connect with your respective Iwi entity otherwise please send your name and email address your iwi and hapu/marae affiliation and email address to here ",
          {
            text: "admin.tmonw@tearawa.iwi.nz",
            url: "mailto:admin.tmonw@tearawa.iwi.nz",
          },
        ],
      },
    ],
  },
];

export default questionsAndAnswers;
