import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUserNonAdminMutation = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    email
    first_name
    last_name
    avatar_url
    phone_number
    account_verified
    iwiID
    user_type
    title
    job_title
    user_attribute
    status
    createdAt
    updatedAt
    userIwiCollectiveId
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateUserMutationVariables, APITypes.CreateUserMutation>;
