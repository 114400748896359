import { Link } from "./content-types";
import React from "react";

export const renderParagraphPart = (part: string | Link, index: number) => {
  if (typeof part === "string") {
    return <span key={index}>{part}</span>;
  } else {
    return (
      <a
        key={index}
        href={part.url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-brand-primary font-extrabold visited:font-extrabold visited:text-brand-secondary px-1">
        {part.text}
      </a>
    );
  }
};
