import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import TextInputCombo from "@src/components/forms/text-input-combo";
import Button from "@src/components/ui/button";
import * as yup from "yup";
import { useApiCall } from "@src/hooks/api-call";
import AlertModal, { AlertType } from "@src/components/overlays/alert-modal";
import LoadingSpinner from "@src/components/overlays/LoadingSpinner";
import { apiVerifyAccountQuery } from "@src/app/public-pages/auth/verify/modules/api-queries";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resendSignUpCode } from "aws-amplify/auth";

const INITIAL_VALUES = {
  code: "",
};

const VALIDATION_SCHEMA = yup.object().shape({
  code: yup.string().required(),
});

export const VerifyForm = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const email = params.get("email") || "";

  // api hook
  const {
    execute: executeVerify,
    isLoading: isVerifyLoading,
    errorMessage: verifyErrorMessage,
    result,
  } = useApiCall(apiVerifyAccountQuery, {
    onSuccess: (data) => {
      console.log("Success", data);
      setSuccessMessage("Account verified successfully");
    },
    onError: (error) => {
      // this is available for use, but if logic depends on the error message, it's better to handle some things in the useEffect
      console.log("Error", error);
    },
  });

  // This has to be handled here because the error message is parsed in the api-call hook and surfaced as error message
  useEffect(() => {
    if (verifyErrorMessage) {
      setErrorMessage(verifyErrorMessage);
    }
  }, [verifyErrorMessage]);

  return (
    <div>
      {(isVerifyLoading || isLoading) && <LoadingSpinner />}
      {successMessage && (
        <AlertModal
          alertType={AlertType.success}
          message={successMessage}
          title={"Verified"}
          onClose={() => {
            setSuccessMessage("");
            navigate("/auth/login");
          }}
        />
      )}
      {errorMessage && (
        <AlertModal
          title={"Error"}
          message={errorMessage}
          onClose={() => setErrorMessage("")}
          alertType={AlertType.error}
        />
      )}
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await executeVerify(email, values.code);
          } finally {
            setSubmitting(false);
          }
        }}
        validationSchema={VALIDATION_SCHEMA}>
        {({ isSubmitting }) => (
          <Form className={"flex flex-col gap-4"}>
            {isSubmitting && <LoadingSpinner />}
            <TextInputCombo name={"code"} type={"text"} label={"Verification Code"} />
            <Button label={"Verify"} type={"submit"} disabled={isSubmitting} />
            {/*<FormDebug />*/}
            <button
              onClick={async () => {
                try {
                  setIsLoading(true);
                  const result = await resendSignUpCode({ username: email });
                  console.log(result);
                } catch (e) {
                  console.log(e);
                } finally {
                  setIsLoading(false);
                }
              }}
              type={"button"}
              className="flex w-full justify-center rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              {"Resend Code"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VerifyForm;
