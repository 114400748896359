import { generateClient } from "@aws-amplify/api";
import { getIwiCollective, listIwiCollectives } from "@src/graphql/queries";

export const iwiCollectiveApi = {
  listIwiCollectives: async () => {
    const client = generateClient();
    const result = await client.graphql({
      query: listIwiCollectives,
      authMode: "userPool",
    });

    return result.data.listIwiCollectives.items;
  },
  getCollectiveById: async (id: string) => {
    const client = generateClient();
    const result = await client.graphql({
      query: getIwiCollective,
      variables: { id },
      authMode: "userPool",
    });

    return result.data.getIwiCollective;
  },
};
