import { Archive, EditIcon, Share2 } from "lucide-react";
import { BackspaceIcon } from "@heroicons/react/24/outline";
import IconButton from "@src/components/ui/icon-button";
import useAuthStore from "@src/stores/auth-store";
import { IBulletinDetails } from "@src/types/model/IBulletin";
import { UserGroup, UserTypes } from "@src/constant/user";

type BulletinActionsProps = {
  handleShare: () => void;
  handleUnShare: () => void;
  handleDelete: () => void;
  handleEdit: () => void;
  bulletinDetails: IBulletinDetails;
};

export const BulletinActions = (props: BulletinActionsProps) => {
  const { userProfile, userGroups } = useAuthStore((state) => state);
  const isShared = !!props.bulletinDetails.iwi?.id;
  const roleCanShare = userProfile?.user_type === UserTypes.Router;
  const isWriter = userProfile?.user_type === UserTypes.Writer;
  const canDelete = userGroups.includes(UserGroup.Admins) || isWriter;

  return (
    <div className="flex gap-3 items-center">
      {/* if bulletin is not shared - show share button */}
      {!isShared && roleCanShare && (
        <IconButton title={"Share Bulletin"} IconComponent={Share2} onClick={props.handleShare} />
      )}

      {/* if bulletin is shared, show unshare button */}
      {isShared && roleCanShare && (
        <IconButton
          title={"Unshare Bulletin"}
          IconComponent={Archive}
          onClick={props.handleUnShare}
        />
      )}

      {isWriter && (
        <IconButton
          title={"Edit"}
          IconComponent={EditIcon}
          onClick={props.handleEdit}
          bgColor={"bg-blue-700"}
        />
      )}

      {canDelete && (
        <IconButton
          title={"Delete"}
          IconComponent={BackspaceIcon}
          onClick={props.handleDelete}
          bgColor={"bg-red-700"}
        />
      )}
    </div>
  );
};

export default BulletinActions;
