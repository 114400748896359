import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import TextInputCombo from "@src/components/forms/text-input-combo";
import Button from "@src/components/ui/button";
import * as yup from "yup";
import { useApiCall } from "@src/hooks/api-call";

// import { apiLoginQuery } from "@src/app/public-pages/auth/login/modules/api-queries";

import AlertModal, { AlertType } from "@src/components/overlays/alert-modal";
import LoadingSpinner from "@src/components/overlays/LoadingSpinner";
import { redirect, useNavigate, useSearchParams } from "react-router-dom";
import useAuthStore from "@src/stores/auth-store";
import { signIn, type SignInInput, signOut } from "aws-amplify/auth";
import { apiLoginQuery } from "@src/app/public-pages/auth/login/modules/api-queries";

const VALIDATION_SCHEMA = yup.object().shape({
  email: yup.string().email("Valid email required").required("Email is required"),
  password: yup.string().required("Password is required"),
});

export const LoginForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { loginSuccess } = useAuthStore((state) => state);

  const [params] = useSearchParams();
  const emailInput = params.get("email") || "";

  const INITIAL_VALUES = {
    email: emailInput,
    password: "",
  };

  const {
    execute: executeLogin,
    isLoading: isLoginLoading,
    errorMessage: loginError,
  } = useApiCall(
    async (input: SignInInput) => {
      try {
        await signOut();
      } catch (e) {
        console.log(e);
      }
      return await signIn(input);
    },
    {
      onSuccess: async (data) => {
        try {
          setIsLoading(true);
          await loginSuccess();
          navigate("/user");
        } catch (e: any) {
          console.log(e);
        } finally {
          setIsLoading(false);
        }
      },
      onError: async (e) => {
        // UserAlreadyAuthenticatedException: There is already a signed in user.
        console.log(e);
        await signOut();
      },
    }
  );

  useEffect(() => {
    if (loginError) {
      setErrorMessage(loginError);
    }
  }, [loginError]);

  return (
    <div>
      {(isLoginLoading || isLoading) && <LoadingSpinner />}
      {loginError && (
        <AlertModal
          title={"Error"}
          message={errorMessage}
          onClose={() => setErrorMessage("")}
          alertType={AlertType.error}
        />
      )}
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={async (values, { setSubmitting }) => {
          setIsLoading(true);
          try {
            const res = await executeLogin({ password: values.password, username: values.email });
            console.log(res);
            if (res.status !== 200) {
              redirect("/auth/login");
            }

            // if account is in password_reset mode (set from amplify), redirect
            if (!res?.data?.isSignedIn && res?.data?.nextStep.signInStep === "RESET_PASSWORD") {
              navigate("/auth/forgot-password");
            }

            // if account is in CONFIRM_SIGN_UP mode (set from amplify), redirect
            if (!res?.data?.isSignedIn && res?.data?.nextStep.signInStep === "CONFIRM_SIGN_UP") {
              navigate("/auth/verify?email=" + values.email);
            }
          } catch (e) {
            console.log(e);
          } finally {
            setSubmitting(false);
            setIsLoading(false);
          }
        }}
        validationSchema={VALIDATION_SCHEMA}>
        {({ isSubmitting }) => (
          <Form className={"flex flex-col gap-4"}>
            <TextInputCombo name={"email"} type={"email"} label={"Email address"} />
            <TextInputCombo name={"password"} type={"password"} label={"Password"} />
            <Button label={"Sign In"} type={"submit"} disabled={isSubmitting}></Button>
            <div className={"flex justify-between"}>
              <div className={"flex items-center cursor-pointer"}>
                <p className="text-sm leading-6 text-gray-500">Not a member?</p>
                <div
                  onClick={() => {
                    // NOTE: this is disabled as users do not need to sign up
                    // navigate("/auth/sign-up");
                  }}
                  className={
                    "cursor-pointer px-2 text-sm font-semibold text-brand-primary hover:text-brand-accent"
                  }>
                  Request
                </div>
              </div>
              <div className={"cursor-pointer"} onClick={() => navigate("/auth/forgot-password")}>
                <p className="text-xs leading-6 text-brand-primary">Forgot password</p>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
