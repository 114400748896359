import React, { Fragment, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { SideNav } from "@src/components/navigation/modules/side-nav";
import ProfileAvatar from "@src/components/ui/ProfileAvatar";
import useAdminUiStore from "@src/stores/admin-ui-store";

const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(" ");
};

export const UserLayout = () => {
  const navigate = useNavigate();
  const { isSidebarOpen, toggleSidebar } = useAdminUiStore((state) => state);

  const userNavigation = [
    {
      id: "Profile",
      name: "Your Profile",
      url: "/user/profile",
      action: () => navigate("/user/profile"),
    },
    {
      id: "",
      name: "Sign out",
      url: "/login",
      action: () => {
        console.log("logout");
      },
    },
  ];

  const selectMenuHandler = (e: any) => {
    // get menu item
    const menuName = (e.target as HTMLElement).textContent ?? "";
    const selectedMenuItem = userNavigation.find((item) => item.name === menuName);

    // action
    selectedMenuItem?.action();
  };

  return (
    <>
      <div>
        <Transition.Root show={isSidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={toggleSidebar}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            {/* SideNav Expand on smaller screens */}
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full">
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-brand-primary">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => {
                        toggleSidebar();
                      }}>
                      <span className="sr-only">Close sidebar</span>
                      <ArrowLeftIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <SideNav />
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div style={{ zIndex: 1 }} className={"hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0"}>
          <SideNav />
        </div>
        <div className="md:pl-64 flex flex-col flex-1">
          <div className={`sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow md:hidden`}>
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => toggleSidebar()}>
              <span className="sr-only">Open sidebar</span>
              <ArrowRightIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex flex-row-reverse">
              <div className="ml-4 flex items-center md:ml-6">
                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button
                      className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">Open user menu</span>
                      <ProfileAvatar access_image_url={""} />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items
                      className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <div
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700 cursor-pointer",
                              )}
                              onClick={selectMenuHandler}>
                              {item.name}
                            </div>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main>
            <div className="py-6">
              {/*<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">*/}
              {/*  <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>*/}
              {/*</div>*/}
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                {/* Replace content */}
                <div className="py-4 overflow-hidden">
                  <Outlet />
                  {/*<div className="border-4 border-dashed border-gray-200 rounded-lg h-96" />*/}
                </div>
                {/* /End replace */}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default UserLayout;
