export enum UserTypes {
  Writer = "Writer",
  Router = "Router",
  Reader = "Reader",
}

export const AppUserObjects = {
  Writer: "TMoNW",
  Router: "Iwi Admin",
  Reader: "Iwi/Hapū Member",
};

export enum UserGroup {
  Users = "Users",
  Admins = "Admins",
}

export enum UserAttribute {
  Operations = "Operations",
  Governance = "Governance",
}

export enum BulletinState {
  Live = "Live",
  Archived = "Archived",
}
