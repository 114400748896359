import { generateClient } from "@aws-amplify/api";
import { createUser } from "@src/graphql/mutations";
import {
  getUser,
  usersByIwiID,
  listUsers,
  listIwiCollectives,
  listIwis,
} from "@src/graphql/queries";
import { post, del } from "aws-amplify/api";
import { IAppUserDetails } from "@src/types/model/user";
import { iwiApi } from "@src/api/iwi-api";
import { iwiCollectiveApi } from "@src/api/iwi-collective-api";
import { CreateUserInput, User } from "@src/API";
import { fetchAuthSession } from "aws-amplify/auth";
import { createUserNonAdminMutation } from "./user-mutation";
import { ApiCallType, RestRequest } from "@src/util/rest-request";

export const userApi = {
  // create user through signUp
  createUser: async (payload: CreateUserInput): Promise<string> => {
    const client = generateClient();
    const result = await client.graphql({
      query: createUserNonAdminMutation,
      variables: {
        input: {
          ...payload,
        },
      },
    });
    return result.data.createUser.id;
  },

  createUserAdmin: async (payload: CreateUserInput): Promise<string> => {
    const client = generateClient();
    const result = await client.graphql({
      query: createUser,
      variables: {
        input: {
          ...payload,
        },
      },
      authMode: "userPool",
    });
    return result.data.createUser.id;
  },
  getUser: async (id: string): Promise<User | null> => {
    const client = generateClient();
    const result = await client.graphql({
      query: getUser,
      variables: { id },
      authMode: "userPool",
    });

    return result.data.getUser as User;
  },

  listUsers: async (): Promise<IAppUserDetails[]> => {
    const client = generateClient();
    const result = await client.graphql({
      query: listUsers,
      authMode: "userPool",
    });

    const users = (result.data?.listUsers?.items as User[]) ?? [];

    const iwiCollectives = await client.graphql({
      query: listIwiCollectives,
      authMode: "userPool",
    });

    const iwis = await client.graphql({
      query: listIwis,
      authMode: "userPool",
    });

    const userDetails = Promise.all(
      users.map(async (i) => {
        const iwiCollective = iwiCollectives.data.listIwiCollectives.items.find(
          (ic) => ic.id === i.userIwiCollectiveId
        );

        const iwi = iwis.data.listIwis.items.find((iw) => iw.id === i.iwiID);

        return {
          ...i,
          iwi: iwi ? { id: iwi.id, name: iwi.name } : { id: "", name: "" },
          IwiCollective: iwiCollective
            ? { id: iwiCollective.id, name: iwiCollective.name }
            : { id: "", name: "" },
        } as IAppUserDetails;
      })
    );

    return userDetails;
  },

  getUserDetails: async (id: string): Promise<IAppUserDetails> => {
    // get user record
    const user = await userApi.getUser(id);

    // hack: this is terrible. Fix it later
    if (user?.iwiID === "0" || user?.iwiID === null) {
      return {
        ...user!,
        iwi: { id: "", name: "" },
        collective: { id: "", name: "" },
      } as any;
    }

    // get iwi record
    const iwi = await iwiApi.getIwi(user?.iwiID!);

    // get iwi collective, that contains that iwi
    const col = await iwiCollectiveApi.getCollectiveById(iwi?.iwicollectiveID!);

    const payload: any = {
      ...user!,
      iwi: {
        id: iwi?.id!,
        name: iwi?.name!,
      },
      iwiCollective: { id: col?.id!, name: col?.name! },
    };

    return payload as IAppUserDetails;
  },
  getUsersByIwiId: async (iwiId: string) => {
    const client = generateClient();
    const result = await client.graphql({
      query: usersByIwiID,
      variables: { iwiID: iwiId },
      authMode: "userPool",
    });

    return result.data.usersByIwiID.items;
  },
  updateUserProfile: async (payload: any) => {
    const updateUserProfileOperation = post({
      apiName: "tmonwRestApi",
      path: "/users/update",
      options: {
        headers: {
          "Content-Type": "application/json",
        },
        body: payload,
      },
    });

    return await updateUserProfileOperation.response;
  },
  deleteUser: async (userId: string) => {
    const session = await fetchAuthSession();
    const authToken = session.tokens?.idToken?.toString()!;
    const deleteUserOperation = del({
      apiName: "tmonwRestApi",
      path: `/admin/users/${userId}`,
      // amplify override has been configured. This is the way to add headers to the request
      options: {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken}`,
        },
      },
    });
    return await deleteUserOperation.response;
  },
  updateUserByAdmin: async (payload: any) => {
    const session = await fetchAuthSession();
    const authToken = session.tokens?.idToken?.toString()!;
    const updateUserByAdminOperation = post({
      apiName: "tmonwRestApi",
      path: "/admin/users/update",
      options: {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken}`,
        },
        body: payload,
      },
    });
    return await updateUserByAdminOperation.response;
  },
  initializeNewAccount: async (payload: { id: string }) => {
    console.log({ payloadFromService: payload });
    const session = await fetchAuthSession();
    const authToken = session.tokens?.idToken?.toString()!;
    const updateUserByAdminOperation = post({
      apiName: "tmonwRestApi",
      path: "/admin/users/initialize-new-account",
      options: {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken}`,
        },
        body: payload,
      },
    });
    return await updateUserByAdminOperation.response;
  },
  sendWelcomeEmail: async (id: string) => {
    // return await new RestRequest(
    //   `/users/send-welcome-email/${id}`,
    //   ApiCallType.POST,
    //   {}
    // ).response();
    const session = await fetchAuthSession();
    const authToken = session.tokens?.idToken?.toString()!;
    const sendEmail = post({
      apiName: "tmonwRestApi",
      path: `/users/send-welcome-email/${id}`,
      options: {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken}`,
        },
      },
    });
    return await sendEmail.response;
  },
};
