/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createIwiShare = /* GraphQL */ `mutation CreateIwiShare(
  $input: CreateIwiShareInput!
  $condition: ModelIwiShareConditionInput
) {
  createIwiShare(input: $input, condition: $condition) {
    id
    attribute
    sharedWithRole
    userID
    iwiID
    bulletinID
    iwi {
      id
      name
      iwicollectiveID
      createdAt
      updatedAt
      __typename
    }
    bulletin {
      id
      title
      description
      image_url
      state
      createdAt
      targetIwiCollectiveID
      updatedAt
      bulletinUserId
      __typename
    }
    user {
      id
      email
      first_name
      last_name
      avatar_url
      phone_number
      account_verified
      iwiID
      user_type
      title
      job_title
      user_attribute
      status
      createdAt
      updatedAt
      userIwiCollectiveId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIwiShareMutationVariables,
  APITypes.CreateIwiShareMutation
>;
export const updateIwiShare = /* GraphQL */ `mutation UpdateIwiShare(
  $input: UpdateIwiShareInput!
  $condition: ModelIwiShareConditionInput
) {
  updateIwiShare(input: $input, condition: $condition) {
    id
    attribute
    sharedWithRole
    userID
    iwiID
    bulletinID
    iwi {
      id
      name
      iwicollectiveID
      createdAt
      updatedAt
      __typename
    }
    bulletin {
      id
      title
      description
      image_url
      state
      createdAt
      targetIwiCollectiveID
      updatedAt
      bulletinUserId
      __typename
    }
    user {
      id
      email
      first_name
      last_name
      avatar_url
      phone_number
      account_verified
      iwiID
      user_type
      title
      job_title
      user_attribute
      status
      createdAt
      updatedAt
      userIwiCollectiveId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIwiShareMutationVariables,
  APITypes.UpdateIwiShareMutation
>;
export const deleteIwiShare = /* GraphQL */ `mutation DeleteIwiShare(
  $input: DeleteIwiShareInput!
  $condition: ModelIwiShareConditionInput
) {
  deleteIwiShare(input: $input, condition: $condition) {
    id
    attribute
    sharedWithRole
    userID
    iwiID
    bulletinID
    iwi {
      id
      name
      iwicollectiveID
      createdAt
      updatedAt
      __typename
    }
    bulletin {
      id
      title
      description
      image_url
      state
      createdAt
      targetIwiCollectiveID
      updatedAt
      bulletinUserId
      __typename
    }
    user {
      id
      email
      first_name
      last_name
      avatar_url
      phone_number
      account_verified
      iwiID
      user_type
      title
      job_title
      user_attribute
      status
      createdAt
      updatedAt
      userIwiCollectiveId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIwiShareMutationVariables,
  APITypes.DeleteIwiShareMutation
>;
export const createIwiCollectiveShare = /* GraphQL */ `mutation CreateIwiCollectiveShare(
  $input: CreateIwiCollectiveShareInput!
  $condition: ModelIwiCollectiveShareConditionInput
) {
  createIwiCollectiveShare(input: $input, condition: $condition) {
    id
    bulletinID
    bulletin {
      id
      title
      description
      image_url
      state
      createdAt
      targetIwiCollectiveID
      updatedAt
      bulletinUserId
      __typename
    }
    iwiCollectiveID
    iwiCollective {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    User {
      id
      email
      first_name
      last_name
      avatar_url
      phone_number
      account_verified
      iwiID
      user_type
      title
      job_title
      user_attribute
      status
      createdAt
      updatedAt
      userIwiCollectiveId
      __typename
    }
    createdAt
    updatedAt
    iwiCollectiveShareUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIwiCollectiveShareMutationVariables,
  APITypes.CreateIwiCollectiveShareMutation
>;
export const updateIwiCollectiveShare = /* GraphQL */ `mutation UpdateIwiCollectiveShare(
  $input: UpdateIwiCollectiveShareInput!
  $condition: ModelIwiCollectiveShareConditionInput
) {
  updateIwiCollectiveShare(input: $input, condition: $condition) {
    id
    bulletinID
    bulletin {
      id
      title
      description
      image_url
      state
      createdAt
      targetIwiCollectiveID
      updatedAt
      bulletinUserId
      __typename
    }
    iwiCollectiveID
    iwiCollective {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    User {
      id
      email
      first_name
      last_name
      avatar_url
      phone_number
      account_verified
      iwiID
      user_type
      title
      job_title
      user_attribute
      status
      createdAt
      updatedAt
      userIwiCollectiveId
      __typename
    }
    createdAt
    updatedAt
    iwiCollectiveShareUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIwiCollectiveShareMutationVariables,
  APITypes.UpdateIwiCollectiveShareMutation
>;
export const deleteIwiCollectiveShare = /* GraphQL */ `mutation DeleteIwiCollectiveShare(
  $input: DeleteIwiCollectiveShareInput!
  $condition: ModelIwiCollectiveShareConditionInput
) {
  deleteIwiCollectiveShare(input: $input, condition: $condition) {
    id
    bulletinID
    bulletin {
      id
      title
      description
      image_url
      state
      createdAt
      targetIwiCollectiveID
      updatedAt
      bulletinUserId
      __typename
    }
    iwiCollectiveID
    iwiCollective {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    User {
      id
      email
      first_name
      last_name
      avatar_url
      phone_number
      account_verified
      iwiID
      user_type
      title
      job_title
      user_attribute
      status
      createdAt
      updatedAt
      userIwiCollectiveId
      __typename
    }
    createdAt
    updatedAt
    iwiCollectiveShareUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIwiCollectiveShareMutationVariables,
  APITypes.DeleteIwiCollectiveShareMutation
>;
export const createBulletin = /* GraphQL */ `mutation CreateBulletin(
  $input: CreateBulletinInput!
  $condition: ModelBulletinConditionInput
) {
  createBulletin(input: $input, condition: $condition) {
    id
    title
    description
    image_url
    state
    createdAt
    targetIwiCollectiveID
    User {
      id
      email
      first_name
      last_name
      avatar_url
      phone_number
      account_verified
      iwiID
      user_type
      title
      job_title
      user_attribute
      status
      createdAt
      updatedAt
      userIwiCollectiveId
      __typename
    }
    targetIwiCollective {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    attachments {
      nextToken
      __typename
    }
    iwiShares {
      nextToken
      __typename
    }
    updatedAt
    bulletinUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBulletinMutationVariables,
  APITypes.CreateBulletinMutation
>;
export const updateBulletin = /* GraphQL */ `mutation UpdateBulletin(
  $input: UpdateBulletinInput!
  $condition: ModelBulletinConditionInput
) {
  updateBulletin(input: $input, condition: $condition) {
    id
    title
    description
    image_url
    state
    createdAt
    targetIwiCollectiveID
    User {
      id
      email
      first_name
      last_name
      avatar_url
      phone_number
      account_verified
      iwiID
      user_type
      title
      job_title
      user_attribute
      status
      createdAt
      updatedAt
      userIwiCollectiveId
      __typename
    }
    targetIwiCollective {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    attachments {
      nextToken
      __typename
    }
    iwiShares {
      nextToken
      __typename
    }
    updatedAt
    bulletinUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBulletinMutationVariables,
  APITypes.UpdateBulletinMutation
>;
export const deleteBulletin = /* GraphQL */ `mutation DeleteBulletin(
  $input: DeleteBulletinInput!
  $condition: ModelBulletinConditionInput
) {
  deleteBulletin(input: $input, condition: $condition) {
    id
    title
    description
    image_url
    state
    createdAt
    targetIwiCollectiveID
    User {
      id
      email
      first_name
      last_name
      avatar_url
      phone_number
      account_verified
      iwiID
      user_type
      title
      job_title
      user_attribute
      status
      createdAt
      updatedAt
      userIwiCollectiveId
      __typename
    }
    targetIwiCollective {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    attachments {
      nextToken
      __typename
    }
    iwiShares {
      nextToken
      __typename
    }
    updatedAt
    bulletinUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBulletinMutationVariables,
  APITypes.DeleteBulletinMutation
>;
export const createAttachment = /* GraphQL */ `mutation CreateAttachment(
  $input: CreateAttachmentInput!
  $condition: ModelAttachmentConditionInput
) {
  createAttachment(input: $input, condition: $condition) {
    id
    fileUrl
    fileName
    fileType
    size
    createdAt
    bulletinID
    bulletin {
      id
      title
      description
      image_url
      state
      createdAt
      targetIwiCollectiveID
      updatedAt
      bulletinUserId
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAttachmentMutationVariables,
  APITypes.CreateAttachmentMutation
>;
export const updateAttachment = /* GraphQL */ `mutation UpdateAttachment(
  $input: UpdateAttachmentInput!
  $condition: ModelAttachmentConditionInput
) {
  updateAttachment(input: $input, condition: $condition) {
    id
    fileUrl
    fileName
    fileType
    size
    createdAt
    bulletinID
    bulletin {
      id
      title
      description
      image_url
      state
      createdAt
      targetIwiCollectiveID
      updatedAt
      bulletinUserId
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAttachmentMutationVariables,
  APITypes.UpdateAttachmentMutation
>;
export const deleteAttachment = /* GraphQL */ `mutation DeleteAttachment(
  $input: DeleteAttachmentInput!
  $condition: ModelAttachmentConditionInput
) {
  deleteAttachment(input: $input, condition: $condition) {
    id
    fileUrl
    fileName
    fileType
    size
    createdAt
    bulletinID
    bulletin {
      id
      title
      description
      image_url
      state
      createdAt
      targetIwiCollectiveID
      updatedAt
      bulletinUserId
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAttachmentMutationVariables,
  APITypes.DeleteAttachmentMutation
>;
export const createIwiCollective = /* GraphQL */ `mutation CreateIwiCollective(
  $input: CreateIwiCollectiveInput!
  $condition: ModelIwiCollectiveConditionInput
) {
  createIwiCollective(input: $input, condition: $condition) {
    id
    name
    Iwi {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIwiCollectiveMutationVariables,
  APITypes.CreateIwiCollectiveMutation
>;
export const updateIwiCollective = /* GraphQL */ `mutation UpdateIwiCollective(
  $input: UpdateIwiCollectiveInput!
  $condition: ModelIwiCollectiveConditionInput
) {
  updateIwiCollective(input: $input, condition: $condition) {
    id
    name
    Iwi {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIwiCollectiveMutationVariables,
  APITypes.UpdateIwiCollectiveMutation
>;
export const deleteIwiCollective = /* GraphQL */ `mutation DeleteIwiCollective(
  $input: DeleteIwiCollectiveInput!
  $condition: ModelIwiCollectiveConditionInput
) {
  deleteIwiCollective(input: $input, condition: $condition) {
    id
    name
    Iwi {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIwiCollectiveMutationVariables,
  APITypes.DeleteIwiCollectiveMutation
>;
export const createIwi = /* GraphQL */ `mutation CreateIwi(
  $input: CreateIwiInput!
  $condition: ModelIwiConditionInput
) {
  createIwi(input: $input, condition: $condition) {
    id
    name
    Users {
      nextToken
      __typename
    }
    iwicollectiveID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIwiMutationVariables,
  APITypes.CreateIwiMutation
>;
export const updateIwi = /* GraphQL */ `mutation UpdateIwi(
  $input: UpdateIwiInput!
  $condition: ModelIwiConditionInput
) {
  updateIwi(input: $input, condition: $condition) {
    id
    name
    Users {
      nextToken
      __typename
    }
    iwicollectiveID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIwiMutationVariables,
  APITypes.UpdateIwiMutation
>;
export const deleteIwi = /* GraphQL */ `mutation DeleteIwi(
  $input: DeleteIwiInput!
  $condition: ModelIwiConditionInput
) {
  deleteIwi(input: $input, condition: $condition) {
    id
    name
    Users {
      nextToken
      __typename
    }
    iwicollectiveID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIwiMutationVariables,
  APITypes.DeleteIwiMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    email
    first_name
    last_name
    avatar_url
    phone_number
    account_verified
    iwiID
    user_type
    title
    job_title
    user_attribute
    status
    IwiCollective {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    userIwiCollectiveId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    email
    first_name
    last_name
    avatar_url
    phone_number
    account_verified
    iwiID
    user_type
    title
    job_title
    user_attribute
    status
    IwiCollective {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    userIwiCollectiveId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    email
    first_name
    last_name
    avatar_url
    phone_number
    account_verified
    iwiID
    user_type
    title
    job_title
    user_attribute
    status
    IwiCollective {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    userIwiCollectiveId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
