import React from "react";

type ButtonProps = {
  type: "submit" | "button";
  label: string;
  disabled?: boolean;
};
export const Button = (props: ButtonProps) => {
  return (
    <button
      type={props.type}
      disabled={props.disabled}
      className="flex w-full justify-center rounded-md bg-brand-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
      {props.label}
    </button>
  );
};

export default Button;
