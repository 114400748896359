import React from "react";

type ChipProps = {
  title: string;
};
export const Chip = (props: ChipProps) => {
  return (
    <div
      className={
        "absolute -top-2 right-2 flex max-w-28 select-none items-center whitespace-nowrap rounded-lg border border-green-700 py-0.5 px-3 font-sans text-xs font-semibold uppercase text-white bg-green-500 justify-center"
      }>
      <span className="">{props.title}</span>
    </div>
  );
};

export default Chip;
