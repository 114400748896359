import React from "react";
import BulletinForm from "./modules/update-bulletin-form";
import Breadcrumbs from "@src/components/ui/breadcrumbs";
import { updateBulletinsBreadcrumbItems } from "@src/constant";

const UpdateBulletin: React.FC = () => {
  return (
    <>
      <Breadcrumbs items={updateBulletinsBreadcrumbItems} />
      <BulletinForm />
    </>
  );
};

export default UpdateBulletin;
