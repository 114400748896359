import React from 'react'
import Breadcrumbs from '@src/components/ui/breadcrumbs'
import { createUserBreadcrumbItems } from '@src/constant'
import UserForm from './modules/create-user-form';

export type UserFormData = {
    title: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    job_title: string;
    password: string;
    confirm_password: string;
    user_type: string;
    iwi_collective: string;
    iwi: string;
    attribute: string;
}

const CreateUser = () => {

    return (
        <>
            <Breadcrumbs items={createUserBreadcrumbItems} />
            <UserForm />
        </>

    )
}

export default CreateUser
