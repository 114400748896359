import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import TextInputCombo from "@src/components/forms/text-input-combo";
import ProfileAvatar from "@src/components/ui/ProfileAvatar";
import useAuthStore from "@src/stores/auth-store";
import { ETargetFileType } from "@src/types/storage";
import { useFileStorage } from "@src/hooks/storage-hooks";
import AlertModal, { AlertType } from "@src/components/overlays/alert-modal";
import LoadingSpinner from "@src/components/overlays/LoadingSpinner";
import { userApi } from "@src/api/user-api";
import { storageUtils } from "@src/util/storage-utils";

const UpdateProfileForm = () => {
  const userProfile = useAuthStore((state) => state.userProfile);
  const [avatarFile, setAvatarFile] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState<string | null>(null);
  const [message, setMessage] = useState<{ type: AlertType; message: string }>();
  const { uploadFile } = useFileStorage();
  const [avatarUrl, setAvatarUrl] = useState("");

  const handleAvatarChange = (event: any) => {
    const file = event.target.files[0];
    setAvatarFile(file);
    setAvatarPreview(URL.createObjectURL(file));
  };

  useEffect(() => {
    const getImageFromKey = async () => {
      const imageFromKey = await storageUtils.getFileAccessUrl(userProfile?.avatar_url ?? "");
      setAvatarUrl(imageFromKey.url.href);
    };
    getImageFromKey();
  }, [userProfile]);

  const validationSchema = yup.object().shape({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    phone_number: yup.string().required("Phone number is required"),
  });

  return (
    <>
      {message && (
        <AlertModal
          message={message.message}
          title={message.type === AlertType.error ? "Error" : "Success"}
          onClose={() => {
            setMessage(undefined);
            if (message.type === AlertType.success) {
              setTimeout(() => {
                window.location.reload();
              }, 200);
            }
          }}
          alertType={message.type}
        />
      )}
      <Formik
        initialValues={{
          first_name: userProfile?.first_name ?? "",
          last_name: userProfile?.last_name ?? "",
          phone_number: userProfile?.phone_number ?? "",
          email: userProfile?.email ?? "",
          avatar_url: userProfile?.avatar_url ?? "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          try {
            setIsLoading(true);
            let payload = {
              id: userProfile?.id,
              first_name: values.first_name,
              last_name: values.last_name,
              phone_number: values.phone_number,
              avatar_url: values.avatar_url,
            };

            if (avatarFile) {
              console.log(avatarFile);
              const fileName = `${ETargetFileType.USER_PROFILE_IMAGE}/${userProfile?.id}`;
              await uploadFile(avatarFile, fileName);
              payload.avatar_url = fileName;
            }

            const response = await userApi.updateUserProfile(payload);
            setMessage({ type: AlertType.success, message: "Profile Updated successfully!" });
            await useAuthStore.getState().loginSuccess();
            console.log("User profile updated successfully", response);
          } catch (error) {
            console.log("Error uploading file or updating user profile:", error);
          } finally {
            setIsLoading(false);
          }
        }}>
        {() => (
          <Form className="mx-auto max-w-md space-y-6 py-12">
            <div className="flex flex-col items-center space-y-4">
              <label htmlFor="avatarUpload">
                <ProfileAvatar
                  className="w-[130px] h-[130px] cursor-pointer"
                  access_image_url={avatarPreview || avatarUrl || ""}
                />
              </label>
              <input
                id="avatarUpload"
                type="file"
                name="avatar_url"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleAvatarChange}
              />
            </div>
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <TextInputCombo name="first_name" type="text" label="First name" />
                </div>
                <div className="space-y-2">
                  <TextInputCombo name="last_name" type="text" label="Last name" />
                </div>
              </div>
              <div className="space-y-2">
                <TextInputCombo name="phone_number" type="text" label="Phone Number" />
              </div>
              <div className="space-y-2">
                <TextInputCombo name="email" type="text" label="Email" disabled />
              </div>
            </div>
            <button
              disabled={isLoading}
              className="bg-brand-primary text-white w-full p-2 rounded-md"
              type="submit">
              Update
            </button>
          </Form>
        )}
      </Formik>
      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default UpdateProfileForm;
