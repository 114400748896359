import React from "react";
import DualTitle from "@src/app/public-pages/who-we-are/modules/dual-title";
import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import { renderParagraphPart } from "@src/app/public-pages/faq/modules/render-paragraph-part";
import questionsAndAnswers from "@src/app/public-pages/faq/modules/content";
import Divider from "@src/components/ui/divider";
import { useNavigate } from "react-router-dom";

export const FaqPage = () => {
  const navigate = useNavigate();

  return (
    <section className="bg-gray-50 overflow-hidden">
      <section className="bg-gray-50 font-sans mb-8">
        <section className={"md:px-10 md:pt-8"}>
          <div
            className={
              "font-sans pt-8 pb-1 px-4 bg-gradient-to-r from-white via-white to-[#03b2d927] md:bg-none md:text-right md:flex md:flex-row-reverse md:justify-around"
            }>
            <div className={""}>
              <p
                className={
                  "hidden p-4 text-sm tracking-widest text-[rgba(61,_61,_61,_0.87)] max-w-3xl leading-6 text-justify md:block"
                }>
                Can’t find the answer you’re looking for? Reach out to us via our
                <a
                  className="text-brand-primary font-bold visited:font-extrabold visited:text-brand-primaryBlue px-1"
                  onClick={() => navigate("/contact-us")}
                  href="/contact-us">
                  contact us form.
                </a>
              </p>
            </div>
            <DualTitle title1={"Frequently asked questions"} title2={""} />
          </div>
          <div
            className={
              "p-8 text-sm tracking-widest text-[rgba(61,_61,_61,_0.87)] max-w-3xl leading-6 text-justify md:hidden"
            }>
            <p className={""}>
              Can’t find the answer you’re looking for? Reach out to us via our
              <a
                className="text-brand-primary font-bold visited:font-extrabold visited:text-brand-primaryBlue px-1"
                onClick={() => navigate("/contact-us")}
                href="/contact-us">
                contact us form.
              </a>
            </p>
          </div>
        </section>

        <main className="grid grid-cols-1 gap-x-16 px-8 md:px-20">
          {questionsAndAnswers.map((item, index) => (
            <Disclosure as="div" key={index} className="pt-2">
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className="py-4 flex w-full justify-between text-left text-gray-900">
                      <span className="text-base font-semibold leading-7">{item.question}</span>
                      <span className="flex h-7 items-center">
                        {open ? (
                          <MinusIcon className="h-6 w-6" aria-hidden="true" />
                        ) : (
                          <PlusIcon className="h-6 w-6" aria-hidden="true" />
                        )}
                      </span>
                    </Disclosure.Button>
                    {!open ? <Divider /> : null}
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    {item.answerParagraphs.map((paragraph, pIndex) => (
                      <p className="font-extralight leading-loose mb-3 pl-4" key={pIndex}>
                        {paragraph.parts.map(renderParagraphPart)}
                      </p>
                    ))}
                    {open ? <Divider /> : null}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </main>
      </section>
    </section>
  );
};

export default FaqPage;
