import React, { useState } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog } from "@headlessui/react";
import { useNavigate, useLocation } from "react-router";
import { ReactComponent as LandscapeLogo } from "@src/assets/logo/te-manawa-ō-ngā-wai-landscape-logo-full-color-rgb.svg";
import { Link } from "react-router-dom";
import { NavigationItem } from "@src/components/navigation/modules/nav-types";
import MenuItem from "@src/components/navigation/modules/menu-item";
import { LoginButton } from "@src/components/ui/login-button";

const navigation: NavigationItem[] = [
  { name: "Home", url: "/", translation: "TMONW" },
  { name: "Who We Are", url: "/who-we-are", translation: "Ko Wai Mātou" },
  { name: "What We Do", url: "/what-we-do", translation: "Tā Tātou Mahi" },
  { name: "Contact us", url: "/contact-us", translation: "Whakapā Mai" },
  { name: "FAQ", url: "/faq", translation: "He pātai atu anō" },
];

export const PublicNavigation = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <header className="relative inset-x-0 top-0 z-50 bg-cover bg-no-repeat bg-center">
      <nav className="flex items-center justify-between px-6 py-5 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1" onClick={() => navigate("/")}>
          <Link to="/" className="-m-1.5 ml-8 p-1.5">
            <span className="sr-only">Your Company</span>
            <LandscapeLogo className="h-14 w-auto" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
            onClick={() => setMobileMenuOpen(true)}>
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        {/* Menu Items! */}
        <div className="hidden lg:flex lg:gap-x-6 lg:mr-10 lg:justify-between items-center">
          {navigation.map((item) => (
            <MenuItem key={item.name} item={item} isActive={location.pathname === item.url} />
          ))}
          <LoginButton />
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="text-sm font-normal tracking-tight fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <LandscapeLogo className="h-8 w-auto" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}>
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <div
                    key={item.name}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(item.url);
                      setMobileMenuOpen(false);
                    }}
                    className="cursor-pointer -mx-3 block rounded-lg px-3 py-2 text-base font-light leading-7 text-gray-900 hover:bg-gray-50">
                    {item.name}
                  </div>
                ))}
              </div>
              <div className="py-6">
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/auth/login");
                  }}
                  className="text-sm font-semibold tracking-tight -mx-3 block rounded-lg px-3 py-2.5  leading-7 hover:bg-gray-50 cursor-pointer">
                  Log in
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default PublicNavigation;
