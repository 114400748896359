/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateIwiShareInput = {
  id?: string | null,
  attribute?: string | null,
  sharedWithRole: string,
  userID: string,
  iwiID: string,
  bulletinID: string,
  createdAt?: string | null,
};

export type ModelIwiShareConditionInput = {
  attribute?: ModelStringInput | null,
  sharedWithRole?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  iwiID?: ModelIDInput | null,
  bulletinID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelIwiShareConditionInput | null > | null,
  or?: Array< ModelIwiShareConditionInput | null > | null,
  not?: ModelIwiShareConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type IwiShare = {
  __typename: "IwiShare",
  id: string,
  attribute?: string | null,
  sharedWithRole: string,
  userID: string,
  iwiID: string,
  bulletinID: string,
  iwi?: Iwi | null,
  bulletin?: Bulletin | null,
  user?: User | null,
  createdAt?: string | null,
  updatedAt: string,
};

export type Iwi = {
  __typename: "Iwi",
  id: string,
  name: string,
  Users?: ModelUserConnection | null,
  iwicollectiveID?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type User = {
  __typename: "User",
  id: string,
  email: string,
  first_name: string,
  last_name: string,
  avatar_url?: string | null,
  phone_number: string,
  account_verified: boolean,
  iwiID?: string | null,
  user_type?: string | null,
  title?: string | null,
  job_title?: string | null,
  user_attribute?: string | null,
  status?: string | null,
  IwiCollective?: IwiCollective | null,
  createdAt: string,
  updatedAt: string,
  userIwiCollectiveId?: string | null,
};

export type IwiCollective = {
  __typename: "IwiCollective",
  id: string,
  name: string,
  Iwi?: ModelIwiConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelIwiConnection = {
  __typename: "ModelIwiConnection",
  items:  Array<Iwi | null >,
  nextToken?: string | null,
};

export type Bulletin = {
  __typename: "Bulletin",
  id: string,
  title: string,
  description: string,
  image_url?: string | null,
  state?: string | null,
  createdAt?: string | null,
  targetIwiCollectiveID: string,
  User?: User | null,
  targetIwiCollective?: IwiCollective | null,
  attachments?: ModelAttachmentConnection | null,
  iwiShares?: ModelIwiShareConnection | null,
  updatedAt: string,
  bulletinUserId?: string | null,
};

export type ModelAttachmentConnection = {
  __typename: "ModelAttachmentConnection",
  items:  Array<Attachment | null >,
  nextToken?: string | null,
};

export type Attachment = {
  __typename: "Attachment",
  id: string,
  fileUrl: string,
  fileName: string,
  fileType?: string | null,
  size?: string | null,
  createdAt?: string | null,
  bulletinID: string,
  bulletin?: Bulletin | null,
  updatedAt: string,
};

export type ModelIwiShareConnection = {
  __typename: "ModelIwiShareConnection",
  items:  Array<IwiShare | null >,
  nextToken?: string | null,
};

export type UpdateIwiShareInput = {
  id: string,
  attribute?: string | null,
  sharedWithRole?: string | null,
  userID?: string | null,
  iwiID?: string | null,
  bulletinID?: string | null,
  createdAt?: string | null,
};

export type DeleteIwiShareInput = {
  id: string,
};

export type CreateIwiCollectiveShareInput = {
  id?: string | null,
  bulletinID: string,
  iwiCollectiveID: string,
  iwiCollectiveShareUserId?: string | null,
};

export type ModelIwiCollectiveShareConditionInput = {
  bulletinID?: ModelIDInput | null,
  iwiCollectiveID?: ModelIDInput | null,
  and?: Array< ModelIwiCollectiveShareConditionInput | null > | null,
  or?: Array< ModelIwiCollectiveShareConditionInput | null > | null,
  not?: ModelIwiCollectiveShareConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  iwiCollectiveShareUserId?: ModelIDInput | null,
};

export type IwiCollectiveShare = {
  __typename: "IwiCollectiveShare",
  id: string,
  bulletinID: string,
  bulletin?: Bulletin | null,
  iwiCollectiveID: string,
  iwiCollective?: IwiCollective | null,
  User?: User | null,
  createdAt: string,
  updatedAt: string,
  iwiCollectiveShareUserId?: string | null,
};

export type UpdateIwiCollectiveShareInput = {
  id: string,
  bulletinID?: string | null,
  iwiCollectiveID?: string | null,
  iwiCollectiveShareUserId?: string | null,
};

export type DeleteIwiCollectiveShareInput = {
  id: string,
};

export type CreateBulletinInput = {
  id?: string | null,
  title: string,
  description: string,
  image_url?: string | null,
  state?: string | null,
  createdAt?: string | null,
  targetIwiCollectiveID: string,
  bulletinUserId?: string | null,
};

export type ModelBulletinConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  image_url?: ModelStringInput | null,
  state?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  targetIwiCollectiveID?: ModelIDInput | null,
  and?: Array< ModelBulletinConditionInput | null > | null,
  or?: Array< ModelBulletinConditionInput | null > | null,
  not?: ModelBulletinConditionInput | null,
  updatedAt?: ModelStringInput | null,
  bulletinUserId?: ModelIDInput | null,
};

export type UpdateBulletinInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  image_url?: string | null,
  state?: string | null,
  createdAt?: string | null,
  targetIwiCollectiveID?: string | null,
  bulletinUserId?: string | null,
};

export type DeleteBulletinInput = {
  id: string,
};

export type CreateAttachmentInput = {
  id?: string | null,
  fileUrl: string,
  fileName: string,
  fileType?: string | null,
  size?: string | null,
  createdAt?: string | null,
  bulletinID: string,
};

export type ModelAttachmentConditionInput = {
  fileUrl?: ModelStringInput | null,
  fileName?: ModelStringInput | null,
  fileType?: ModelStringInput | null,
  size?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  bulletinID?: ModelIDInput | null,
  and?: Array< ModelAttachmentConditionInput | null > | null,
  or?: Array< ModelAttachmentConditionInput | null > | null,
  not?: ModelAttachmentConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateAttachmentInput = {
  id: string,
  fileUrl?: string | null,
  fileName?: string | null,
  fileType?: string | null,
  size?: string | null,
  createdAt?: string | null,
  bulletinID?: string | null,
};

export type DeleteAttachmentInput = {
  id: string,
};

export type CreateIwiCollectiveInput = {
  id?: string | null,
  name: string,
};

export type ModelIwiCollectiveConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelIwiCollectiveConditionInput | null > | null,
  or?: Array< ModelIwiCollectiveConditionInput | null > | null,
  not?: ModelIwiCollectiveConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateIwiCollectiveInput = {
  id: string,
  name?: string | null,
};

export type DeleteIwiCollectiveInput = {
  id: string,
};

export type CreateIwiInput = {
  id?: string | null,
  name: string,
  iwicollectiveID?: string | null,
};

export type ModelIwiConditionInput = {
  name?: ModelStringInput | null,
  iwicollectiveID?: ModelIDInput | null,
  and?: Array< ModelIwiConditionInput | null > | null,
  or?: Array< ModelIwiConditionInput | null > | null,
  not?: ModelIwiConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateIwiInput = {
  id: string,
  name?: string | null,
  iwicollectiveID?: string | null,
};

export type DeleteIwiInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  email: string,
  first_name: string,
  last_name: string,
  avatar_url?: string | null,
  phone_number: string,
  account_verified: boolean,
  iwiID?: string | null,
  user_type?: string | null,
  title?: string | null,
  job_title?: string | null,
  user_attribute?: string | null,
  status?: string | null,
  userIwiCollectiveId?: string | null,
};

export type ModelUserConditionInput = {
  email?: ModelStringInput | null,
  first_name?: ModelStringInput | null,
  last_name?: ModelStringInput | null,
  avatar_url?: ModelStringInput | null,
  phone_number?: ModelStringInput | null,
  account_verified?: ModelBooleanInput | null,
  iwiID?: ModelIDInput | null,
  user_type?: ModelStringInput | null,
  title?: ModelStringInput | null,
  job_title?: ModelStringInput | null,
  user_attribute?: ModelStringInput | null,
  status?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userIwiCollectiveId?: ModelIDInput | null,
  id?: ModelStringInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateUserInput = {
  id: string,
  email?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  avatar_url?: string | null,
  phone_number?: string | null,
  account_verified?: boolean | null,
  iwiID?: string | null,
  user_type?: string | null,
  title?: string | null,
  job_title?: string | null,
  user_attribute?: string | null,
  status?: string | null,
  userIwiCollectiveId?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type ModelIwiShareFilterInput = {
  id?: ModelIDInput | null,
  attribute?: ModelStringInput | null,
  sharedWithRole?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  iwiID?: ModelIDInput | null,
  bulletinID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelIwiShareFilterInput | null > | null,
  or?: Array< ModelIwiShareFilterInput | null > | null,
  not?: ModelIwiShareFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelIwiCollectiveShareFilterInput = {
  id?: ModelIDInput | null,
  bulletinID?: ModelIDInput | null,
  iwiCollectiveID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelIwiCollectiveShareFilterInput | null > | null,
  or?: Array< ModelIwiCollectiveShareFilterInput | null > | null,
  not?: ModelIwiCollectiveShareFilterInput | null,
  iwiCollectiveShareUserId?: ModelIDInput | null,
};

export type ModelIwiCollectiveShareConnection = {
  __typename: "ModelIwiCollectiveShareConnection",
  items:  Array<IwiCollectiveShare | null >,
  nextToken?: string | null,
};

export type ModelBulletinFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  image_url?: ModelStringInput | null,
  state?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  targetIwiCollectiveID?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBulletinFilterInput | null > | null,
  or?: Array< ModelBulletinFilterInput | null > | null,
  not?: ModelBulletinFilterInput | null,
  bulletinUserId?: ModelIDInput | null,
};

export type ModelBulletinConnection = {
  __typename: "ModelBulletinConnection",
  items:  Array<Bulletin | null >,
  nextToken?: string | null,
};

export type ModelAttachmentFilterInput = {
  id?: ModelIDInput | null,
  fileUrl?: ModelStringInput | null,
  fileName?: ModelStringInput | null,
  fileType?: ModelStringInput | null,
  size?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  bulletinID?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAttachmentFilterInput | null > | null,
  or?: Array< ModelAttachmentFilterInput | null > | null,
  not?: ModelAttachmentFilterInput | null,
};

export type ModelIwiCollectiveFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelIwiCollectiveFilterInput | null > | null,
  or?: Array< ModelIwiCollectiveFilterInput | null > | null,
  not?: ModelIwiCollectiveFilterInput | null,
};

export type ModelIwiCollectiveConnection = {
  __typename: "ModelIwiCollectiveConnection",
  items:  Array<IwiCollective | null >,
  nextToken?: string | null,
};

export type ModelIwiFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  iwicollectiveID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelIwiFilterInput | null > | null,
  or?: Array< ModelIwiFilterInput | null > | null,
  not?: ModelIwiFilterInput | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  first_name?: ModelStringInput | null,
  last_name?: ModelStringInput | null,
  avatar_url?: ModelStringInput | null,
  phone_number?: ModelStringInput | null,
  account_verified?: ModelBooleanInput | null,
  iwiID?: ModelIDInput | null,
  user_type?: ModelStringInput | null,
  title?: ModelStringInput | null,
  job_title?: ModelStringInput | null,
  user_attribute?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
  userIwiCollectiveId?: ModelIDInput | null,
};

export type ModelSubscriptionIwiShareFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  attribute?: ModelSubscriptionStringInput | null,
  sharedWithRole?: ModelSubscriptionStringInput | null,
  userID?: ModelSubscriptionIDInput | null,
  iwiID?: ModelSubscriptionIDInput | null,
  bulletinID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionIwiShareFilterInput | null > | null,
  or?: Array< ModelSubscriptionIwiShareFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIwiCollectiveShareFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  bulletinID?: ModelSubscriptionIDInput | null,
  iwiCollectiveID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionIwiCollectiveShareFilterInput | null > | null,
  or?: Array< ModelSubscriptionIwiCollectiveShareFilterInput | null > | null,
  iwiCollectiveShareUserId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionBulletinFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  image_url?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  targetIwiCollectiveID?: ModelSubscriptionIDInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBulletinFilterInput | null > | null,
  or?: Array< ModelSubscriptionBulletinFilterInput | null > | null,
  bulletinUserId?: ModelStringInput | null,
};

export type ModelSubscriptionAttachmentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  fileUrl?: ModelSubscriptionStringInput | null,
  fileName?: ModelSubscriptionStringInput | null,
  fileType?: ModelSubscriptionStringInput | null,
  size?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  bulletinID?: ModelSubscriptionIDInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAttachmentFilterInput | null > | null,
  or?: Array< ModelSubscriptionAttachmentFilterInput | null > | null,
};

export type ModelSubscriptionIwiCollectiveFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionIwiCollectiveFilterInput | null > | null,
  or?: Array< ModelSubscriptionIwiCollectiveFilterInput | null > | null,
};

export type ModelSubscriptionIwiFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  iwicollectiveID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionIwiFilterInput | null > | null,
  or?: Array< ModelSubscriptionIwiFilterInput | null > | null,
};

export type ModelSubscriptionUserFilterInput = {
  email?: ModelSubscriptionStringInput | null,
  first_name?: ModelSubscriptionStringInput | null,
  last_name?: ModelSubscriptionStringInput | null,
  avatar_url?: ModelSubscriptionStringInput | null,
  phone_number?: ModelSubscriptionStringInput | null,
  account_verified?: ModelSubscriptionBooleanInput | null,
  iwiID?: ModelSubscriptionIDInput | null,
  user_type?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  job_title?: ModelSubscriptionStringInput | null,
  user_attribute?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
  userIwiCollectiveId?: ModelSubscriptionIDInput | null,
  id?: ModelStringInput | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type CreateIwiShareMutationVariables = {
  input: CreateIwiShareInput,
  condition?: ModelIwiShareConditionInput | null,
};

export type CreateIwiShareMutation = {
  createIwiShare?:  {
    __typename: "IwiShare",
    id: string,
    attribute?: string | null,
    sharedWithRole: string,
    userID: string,
    iwiID: string,
    bulletinID: string,
    iwi?:  {
      __typename: "Iwi",
      id: string,
      name: string,
      iwicollectiveID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    bulletin?:  {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateIwiShareMutationVariables = {
  input: UpdateIwiShareInput,
  condition?: ModelIwiShareConditionInput | null,
};

export type UpdateIwiShareMutation = {
  updateIwiShare?:  {
    __typename: "IwiShare",
    id: string,
    attribute?: string | null,
    sharedWithRole: string,
    userID: string,
    iwiID: string,
    bulletinID: string,
    iwi?:  {
      __typename: "Iwi",
      id: string,
      name: string,
      iwicollectiveID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    bulletin?:  {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteIwiShareMutationVariables = {
  input: DeleteIwiShareInput,
  condition?: ModelIwiShareConditionInput | null,
};

export type DeleteIwiShareMutation = {
  deleteIwiShare?:  {
    __typename: "IwiShare",
    id: string,
    attribute?: string | null,
    sharedWithRole: string,
    userID: string,
    iwiID: string,
    bulletinID: string,
    iwi?:  {
      __typename: "Iwi",
      id: string,
      name: string,
      iwicollectiveID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    bulletin?:  {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateIwiCollectiveShareMutationVariables = {
  input: CreateIwiCollectiveShareInput,
  condition?: ModelIwiCollectiveShareConditionInput | null,
};

export type CreateIwiCollectiveShareMutation = {
  createIwiCollectiveShare?:  {
    __typename: "IwiCollectiveShare",
    id: string,
    bulletinID: string,
    bulletin?:  {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null,
    iwiCollectiveID: string,
    iwiCollective?:  {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    iwiCollectiveShareUserId?: string | null,
  } | null,
};

export type UpdateIwiCollectiveShareMutationVariables = {
  input: UpdateIwiCollectiveShareInput,
  condition?: ModelIwiCollectiveShareConditionInput | null,
};

export type UpdateIwiCollectiveShareMutation = {
  updateIwiCollectiveShare?:  {
    __typename: "IwiCollectiveShare",
    id: string,
    bulletinID: string,
    bulletin?:  {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null,
    iwiCollectiveID: string,
    iwiCollective?:  {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    iwiCollectiveShareUserId?: string | null,
  } | null,
};

export type DeleteIwiCollectiveShareMutationVariables = {
  input: DeleteIwiCollectiveShareInput,
  condition?: ModelIwiCollectiveShareConditionInput | null,
};

export type DeleteIwiCollectiveShareMutation = {
  deleteIwiCollectiveShare?:  {
    __typename: "IwiCollectiveShare",
    id: string,
    bulletinID: string,
    bulletin?:  {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null,
    iwiCollectiveID: string,
    iwiCollective?:  {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    iwiCollectiveShareUserId?: string | null,
  } | null,
};

export type CreateBulletinMutationVariables = {
  input: CreateBulletinInput,
  condition?: ModelBulletinConditionInput | null,
};

export type CreateBulletinMutation = {
  createBulletin?:  {
    __typename: "Bulletin",
    id: string,
    title: string,
    description: string,
    image_url?: string | null,
    state?: string | null,
    createdAt?: string | null,
    targetIwiCollectiveID: string,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null,
    targetIwiCollective?:  {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      nextToken?: string | null,
    } | null,
    iwiShares?:  {
      __typename: "ModelIwiShareConnection",
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    bulletinUserId?: string | null,
  } | null,
};

export type UpdateBulletinMutationVariables = {
  input: UpdateBulletinInput,
  condition?: ModelBulletinConditionInput | null,
};

export type UpdateBulletinMutation = {
  updateBulletin?:  {
    __typename: "Bulletin",
    id: string,
    title: string,
    description: string,
    image_url?: string | null,
    state?: string | null,
    createdAt?: string | null,
    targetIwiCollectiveID: string,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null,
    targetIwiCollective?:  {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      nextToken?: string | null,
    } | null,
    iwiShares?:  {
      __typename: "ModelIwiShareConnection",
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    bulletinUserId?: string | null,
  } | null,
};

export type DeleteBulletinMutationVariables = {
  input: DeleteBulletinInput,
  condition?: ModelBulletinConditionInput | null,
};

export type DeleteBulletinMutation = {
  deleteBulletin?:  {
    __typename: "Bulletin",
    id: string,
    title: string,
    description: string,
    image_url?: string | null,
    state?: string | null,
    createdAt?: string | null,
    targetIwiCollectiveID: string,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null,
    targetIwiCollective?:  {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      nextToken?: string | null,
    } | null,
    iwiShares?:  {
      __typename: "ModelIwiShareConnection",
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    bulletinUserId?: string | null,
  } | null,
};

export type CreateAttachmentMutationVariables = {
  input: CreateAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type CreateAttachmentMutation = {
  createAttachment?:  {
    __typename: "Attachment",
    id: string,
    fileUrl: string,
    fileName: string,
    fileType?: string | null,
    size?: string | null,
    createdAt?: string | null,
    bulletinID: string,
    bulletin?:  {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdateAttachmentMutationVariables = {
  input: UpdateAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type UpdateAttachmentMutation = {
  updateAttachment?:  {
    __typename: "Attachment",
    id: string,
    fileUrl: string,
    fileName: string,
    fileType?: string | null,
    size?: string | null,
    createdAt?: string | null,
    bulletinID: string,
    bulletin?:  {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type DeleteAttachmentMutationVariables = {
  input: DeleteAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type DeleteAttachmentMutation = {
  deleteAttachment?:  {
    __typename: "Attachment",
    id: string,
    fileUrl: string,
    fileName: string,
    fileType?: string | null,
    size?: string | null,
    createdAt?: string | null,
    bulletinID: string,
    bulletin?:  {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type CreateIwiCollectiveMutationVariables = {
  input: CreateIwiCollectiveInput,
  condition?: ModelIwiCollectiveConditionInput | null,
};

export type CreateIwiCollectiveMutation = {
  createIwiCollective?:  {
    __typename: "IwiCollective",
    id: string,
    name: string,
    Iwi?:  {
      __typename: "ModelIwiConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateIwiCollectiveMutationVariables = {
  input: UpdateIwiCollectiveInput,
  condition?: ModelIwiCollectiveConditionInput | null,
};

export type UpdateIwiCollectiveMutation = {
  updateIwiCollective?:  {
    __typename: "IwiCollective",
    id: string,
    name: string,
    Iwi?:  {
      __typename: "ModelIwiConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteIwiCollectiveMutationVariables = {
  input: DeleteIwiCollectiveInput,
  condition?: ModelIwiCollectiveConditionInput | null,
};

export type DeleteIwiCollectiveMutation = {
  deleteIwiCollective?:  {
    __typename: "IwiCollective",
    id: string,
    name: string,
    Iwi?:  {
      __typename: "ModelIwiConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateIwiMutationVariables = {
  input: CreateIwiInput,
  condition?: ModelIwiConditionInput | null,
};

export type CreateIwiMutation = {
  createIwi?:  {
    __typename: "Iwi",
    id: string,
    name: string,
    Users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
    } | null,
    iwicollectiveID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateIwiMutationVariables = {
  input: UpdateIwiInput,
  condition?: ModelIwiConditionInput | null,
};

export type UpdateIwiMutation = {
  updateIwi?:  {
    __typename: "Iwi",
    id: string,
    name: string,
    Users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
    } | null,
    iwicollectiveID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteIwiMutationVariables = {
  input: DeleteIwiInput,
  condition?: ModelIwiConditionInput | null,
};

export type DeleteIwiMutation = {
  deleteIwi?:  {
    __typename: "Iwi",
    id: string,
    name: string,
    Users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
    } | null,
    iwicollectiveID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    email: string,
    first_name: string,
    last_name: string,
    avatar_url?: string | null,
    phone_number: string,
    account_verified: boolean,
    iwiID?: string | null,
    user_type?: string | null,
    title?: string | null,
    job_title?: string | null,
    user_attribute?: string | null,
    status?: string | null,
    IwiCollective?:  {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    userIwiCollectiveId?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    email: string,
    first_name: string,
    last_name: string,
    avatar_url?: string | null,
    phone_number: string,
    account_verified: boolean,
    iwiID?: string | null,
    user_type?: string | null,
    title?: string | null,
    job_title?: string | null,
    user_attribute?: string | null,
    status?: string | null,
    IwiCollective?:  {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    userIwiCollectiveId?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    email: string,
    first_name: string,
    last_name: string,
    avatar_url?: string | null,
    phone_number: string,
    account_verified: boolean,
    iwiID?: string | null,
    user_type?: string | null,
    title?: string | null,
    job_title?: string | null,
    user_attribute?: string | null,
    status?: string | null,
    IwiCollective?:  {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    userIwiCollectiveId?: string | null,
  } | null,
};

export type GetIwiShareQueryVariables = {
  id: string,
};

export type GetIwiShareQuery = {
  getIwiShare?:  {
    __typename: "IwiShare",
    id: string,
    attribute?: string | null,
    sharedWithRole: string,
    userID: string,
    iwiID: string,
    bulletinID: string,
    iwi?:  {
      __typename: "Iwi",
      id: string,
      name: string,
      iwicollectiveID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    bulletin?:  {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type ListIwiSharesQueryVariables = {
  filter?: ModelIwiShareFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIwiSharesQuery = {
  listIwiShares?:  {
    __typename: "ModelIwiShareConnection",
    items:  Array< {
      __typename: "IwiShare",
      id: string,
      attribute?: string | null,
      sharedWithRole: string,
      userID: string,
      iwiID: string,
      bulletinID: string,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type IwiSharesByIwiIDQueryVariables = {
  iwiID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIwiShareFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type IwiSharesByIwiIDQuery = {
  iwiSharesByIwiID?:  {
    __typename: "ModelIwiShareConnection",
    items:  Array< {
      __typename: "IwiShare",
      id: string,
      attribute?: string | null,
      sharedWithRole: string,
      userID: string,
      iwiID: string,
      bulletinID: string,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type IwiSharesByBulletinIDQueryVariables = {
  bulletinID: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIwiShareFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type IwiSharesByBulletinIDQuery = {
  iwiSharesByBulletinID?:  {
    __typename: "ModelIwiShareConnection",
    items:  Array< {
      __typename: "IwiShare",
      id: string,
      attribute?: string | null,
      sharedWithRole: string,
      userID: string,
      iwiID: string,
      bulletinID: string,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIwiCollectiveShareQueryVariables = {
  id: string,
};

export type GetIwiCollectiveShareQuery = {
  getIwiCollectiveShare?:  {
    __typename: "IwiCollectiveShare",
    id: string,
    bulletinID: string,
    bulletin?:  {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null,
    iwiCollectiveID: string,
    iwiCollective?:  {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    iwiCollectiveShareUserId?: string | null,
  } | null,
};

export type ListIwiCollectiveSharesQueryVariables = {
  filter?: ModelIwiCollectiveShareFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIwiCollectiveSharesQuery = {
  listIwiCollectiveShares?:  {
    __typename: "ModelIwiCollectiveShareConnection",
    items:  Array< {
      __typename: "IwiCollectiveShare",
      id: string,
      bulletinID: string,
      iwiCollectiveID: string,
      createdAt: string,
      updatedAt: string,
      iwiCollectiveShareUserId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBulletinQueryVariables = {
  id: string,
};

export type GetBulletinQuery = {
  getBulletin?:  {
    __typename: "Bulletin",
    id: string,
    title: string,
    description: string,
    image_url?: string | null,
    state?: string | null,
    createdAt?: string | null,
    targetIwiCollectiveID: string,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null,
    targetIwiCollective?:  {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      nextToken?: string | null,
    } | null,
    iwiShares?:  {
      __typename: "ModelIwiShareConnection",
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    bulletinUserId?: string | null,
  } | null,
};

export type ListBulletinsQueryVariables = {
  filter?: ModelBulletinFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBulletinsQuery = {
  listBulletins?:  {
    __typename: "ModelBulletinConnection",
    items:  Array< {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BulletinsByTargetIwiCollectiveQueryVariables = {
  targetIwiCollectiveID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBulletinFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BulletinsByTargetIwiCollectiveQuery = {
  bulletinsByTargetIwiCollective?:  {
    __typename: "ModelBulletinConnection",
    items:  Array< {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAttachmentQueryVariables = {
  id: string,
};

export type GetAttachmentQuery = {
  getAttachment?:  {
    __typename: "Attachment",
    id: string,
    fileUrl: string,
    fileName: string,
    fileType?: string | null,
    size?: string | null,
    createdAt?: string | null,
    bulletinID: string,
    bulletin?:  {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type ListAttachmentsQueryVariables = {
  filter?: ModelAttachmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAttachmentsQuery = {
  listAttachments?:  {
    __typename: "ModelAttachmentConnection",
    items:  Array< {
      __typename: "Attachment",
      id: string,
      fileUrl: string,
      fileName: string,
      fileType?: string | null,
      size?: string | null,
      createdAt?: string | null,
      bulletinID: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AttachmentsByBulletinIDQueryVariables = {
  bulletinID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAttachmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AttachmentsByBulletinIDQuery = {
  attachmentsByBulletinID?:  {
    __typename: "ModelAttachmentConnection",
    items:  Array< {
      __typename: "Attachment",
      id: string,
      fileUrl: string,
      fileName: string,
      fileType?: string | null,
      size?: string | null,
      createdAt?: string | null,
      bulletinID: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIwiCollectiveQueryVariables = {
  id: string,
};

export type GetIwiCollectiveQuery = {
  getIwiCollective?:  {
    __typename: "IwiCollective",
    id: string,
    name: string,
    Iwi?:  {
      __typename: "ModelIwiConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListIwiCollectivesQueryVariables = {
  filter?: ModelIwiCollectiveFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIwiCollectivesQuery = {
  listIwiCollectives?:  {
    __typename: "ModelIwiCollectiveConnection",
    items:  Array< {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIwiQueryVariables = {
  id: string,
};

export type GetIwiQuery = {
  getIwi?:  {
    __typename: "Iwi",
    id: string,
    name: string,
    Users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
    } | null,
    iwicollectiveID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListIwisQueryVariables = {
  filter?: ModelIwiFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIwisQuery = {
  listIwis?:  {
    __typename: "ModelIwiConnection",
    items:  Array< {
      __typename: "Iwi",
      id: string,
      name: string,
      iwicollectiveID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type IwisByIwicollectiveIDQueryVariables = {
  iwicollectiveID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIwiFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type IwisByIwicollectiveIDQuery = {
  iwisByIwicollectiveID?:  {
    __typename: "ModelIwiConnection",
    items:  Array< {
      __typename: "Iwi",
      id: string,
      name: string,
      iwicollectiveID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    email: string,
    first_name: string,
    last_name: string,
    avatar_url?: string | null,
    phone_number: string,
    account_verified: boolean,
    iwiID?: string | null,
    user_type?: string | null,
    title?: string | null,
    job_title?: string | null,
    user_attribute?: string | null,
    status?: string | null,
    IwiCollective?:  {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    userIwiCollectiveId?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByIwiIDQueryVariables = {
  iwiID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByIwiIDQuery = {
  usersByIwiID?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateIwiShareSubscriptionVariables = {
  filter?: ModelSubscriptionIwiShareFilterInput | null,
};

export type OnCreateIwiShareSubscription = {
  onCreateIwiShare?:  {
    __typename: "IwiShare",
    id: string,
    attribute?: string | null,
    sharedWithRole: string,
    userID: string,
    iwiID: string,
    bulletinID: string,
    iwi?:  {
      __typename: "Iwi",
      id: string,
      name: string,
      iwicollectiveID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    bulletin?:  {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateIwiShareSubscriptionVariables = {
  filter?: ModelSubscriptionIwiShareFilterInput | null,
};

export type OnUpdateIwiShareSubscription = {
  onUpdateIwiShare?:  {
    __typename: "IwiShare",
    id: string,
    attribute?: string | null,
    sharedWithRole: string,
    userID: string,
    iwiID: string,
    bulletinID: string,
    iwi?:  {
      __typename: "Iwi",
      id: string,
      name: string,
      iwicollectiveID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    bulletin?:  {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteIwiShareSubscriptionVariables = {
  filter?: ModelSubscriptionIwiShareFilterInput | null,
};

export type OnDeleteIwiShareSubscription = {
  onDeleteIwiShare?:  {
    __typename: "IwiShare",
    id: string,
    attribute?: string | null,
    sharedWithRole: string,
    userID: string,
    iwiID: string,
    bulletinID: string,
    iwi?:  {
      __typename: "Iwi",
      id: string,
      name: string,
      iwicollectiveID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    bulletin?:  {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateIwiCollectiveShareSubscriptionVariables = {
  filter?: ModelSubscriptionIwiCollectiveShareFilterInput | null,
};

export type OnCreateIwiCollectiveShareSubscription = {
  onCreateIwiCollectiveShare?:  {
    __typename: "IwiCollectiveShare",
    id: string,
    bulletinID: string,
    bulletin?:  {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null,
    iwiCollectiveID: string,
    iwiCollective?:  {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    iwiCollectiveShareUserId?: string | null,
  } | null,
};

export type OnUpdateIwiCollectiveShareSubscriptionVariables = {
  filter?: ModelSubscriptionIwiCollectiveShareFilterInput | null,
};

export type OnUpdateIwiCollectiveShareSubscription = {
  onUpdateIwiCollectiveShare?:  {
    __typename: "IwiCollectiveShare",
    id: string,
    bulletinID: string,
    bulletin?:  {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null,
    iwiCollectiveID: string,
    iwiCollective?:  {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    iwiCollectiveShareUserId?: string | null,
  } | null,
};

export type OnDeleteIwiCollectiveShareSubscriptionVariables = {
  filter?: ModelSubscriptionIwiCollectiveShareFilterInput | null,
};

export type OnDeleteIwiCollectiveShareSubscription = {
  onDeleteIwiCollectiveShare?:  {
    __typename: "IwiCollectiveShare",
    id: string,
    bulletinID: string,
    bulletin?:  {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null,
    iwiCollectiveID: string,
    iwiCollective?:  {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    iwiCollectiveShareUserId?: string | null,
  } | null,
};

export type OnCreateBulletinSubscriptionVariables = {
  filter?: ModelSubscriptionBulletinFilterInput | null,
  bulletinUserId?: string | null,
};

export type OnCreateBulletinSubscription = {
  onCreateBulletin?:  {
    __typename: "Bulletin",
    id: string,
    title: string,
    description: string,
    image_url?: string | null,
    state?: string | null,
    createdAt?: string | null,
    targetIwiCollectiveID: string,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null,
    targetIwiCollective?:  {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      nextToken?: string | null,
    } | null,
    iwiShares?:  {
      __typename: "ModelIwiShareConnection",
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    bulletinUserId?: string | null,
  } | null,
};

export type OnUpdateBulletinSubscriptionVariables = {
  filter?: ModelSubscriptionBulletinFilterInput | null,
  bulletinUserId?: string | null,
};

export type OnUpdateBulletinSubscription = {
  onUpdateBulletin?:  {
    __typename: "Bulletin",
    id: string,
    title: string,
    description: string,
    image_url?: string | null,
    state?: string | null,
    createdAt?: string | null,
    targetIwiCollectiveID: string,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null,
    targetIwiCollective?:  {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      nextToken?: string | null,
    } | null,
    iwiShares?:  {
      __typename: "ModelIwiShareConnection",
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    bulletinUserId?: string | null,
  } | null,
};

export type OnDeleteBulletinSubscriptionVariables = {
  filter?: ModelSubscriptionBulletinFilterInput | null,
  bulletinUserId?: string | null,
};

export type OnDeleteBulletinSubscription = {
  onDeleteBulletin?:  {
    __typename: "Bulletin",
    id: string,
    title: string,
    description: string,
    image_url?: string | null,
    state?: string | null,
    createdAt?: string | null,
    targetIwiCollectiveID: string,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      avatar_url?: string | null,
      phone_number: string,
      account_verified: boolean,
      iwiID?: string | null,
      user_type?: string | null,
      title?: string | null,
      job_title?: string | null,
      user_attribute?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      userIwiCollectiveId?: string | null,
    } | null,
    targetIwiCollective?:  {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      nextToken?: string | null,
    } | null,
    iwiShares?:  {
      __typename: "ModelIwiShareConnection",
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    bulletinUserId?: string | null,
  } | null,
};

export type OnCreateAttachmentSubscriptionVariables = {
  filter?: ModelSubscriptionAttachmentFilterInput | null,
};

export type OnCreateAttachmentSubscription = {
  onCreateAttachment?:  {
    __typename: "Attachment",
    id: string,
    fileUrl: string,
    fileName: string,
    fileType?: string | null,
    size?: string | null,
    createdAt?: string | null,
    bulletinID: string,
    bulletin?:  {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateAttachmentSubscriptionVariables = {
  filter?: ModelSubscriptionAttachmentFilterInput | null,
};

export type OnUpdateAttachmentSubscription = {
  onUpdateAttachment?:  {
    __typename: "Attachment",
    id: string,
    fileUrl: string,
    fileName: string,
    fileType?: string | null,
    size?: string | null,
    createdAt?: string | null,
    bulletinID: string,
    bulletin?:  {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteAttachmentSubscriptionVariables = {
  filter?: ModelSubscriptionAttachmentFilterInput | null,
};

export type OnDeleteAttachmentSubscription = {
  onDeleteAttachment?:  {
    __typename: "Attachment",
    id: string,
    fileUrl: string,
    fileName: string,
    fileType?: string | null,
    size?: string | null,
    createdAt?: string | null,
    bulletinID: string,
    bulletin?:  {
      __typename: "Bulletin",
      id: string,
      title: string,
      description: string,
      image_url?: string | null,
      state?: string | null,
      createdAt?: string | null,
      targetIwiCollectiveID: string,
      updatedAt: string,
      bulletinUserId?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnCreateIwiCollectiveSubscriptionVariables = {
  filter?: ModelSubscriptionIwiCollectiveFilterInput | null,
};

export type OnCreateIwiCollectiveSubscription = {
  onCreateIwiCollective?:  {
    __typename: "IwiCollective",
    id: string,
    name: string,
    Iwi?:  {
      __typename: "ModelIwiConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateIwiCollectiveSubscriptionVariables = {
  filter?: ModelSubscriptionIwiCollectiveFilterInput | null,
};

export type OnUpdateIwiCollectiveSubscription = {
  onUpdateIwiCollective?:  {
    __typename: "IwiCollective",
    id: string,
    name: string,
    Iwi?:  {
      __typename: "ModelIwiConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteIwiCollectiveSubscriptionVariables = {
  filter?: ModelSubscriptionIwiCollectiveFilterInput | null,
};

export type OnDeleteIwiCollectiveSubscription = {
  onDeleteIwiCollective?:  {
    __typename: "IwiCollective",
    id: string,
    name: string,
    Iwi?:  {
      __typename: "ModelIwiConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateIwiSubscriptionVariables = {
  filter?: ModelSubscriptionIwiFilterInput | null,
};

export type OnCreateIwiSubscription = {
  onCreateIwi?:  {
    __typename: "Iwi",
    id: string,
    name: string,
    Users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
    } | null,
    iwicollectiveID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateIwiSubscriptionVariables = {
  filter?: ModelSubscriptionIwiFilterInput | null,
};

export type OnUpdateIwiSubscription = {
  onUpdateIwi?:  {
    __typename: "Iwi",
    id: string,
    name: string,
    Users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
    } | null,
    iwicollectiveID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteIwiSubscriptionVariables = {
  filter?: ModelSubscriptionIwiFilterInput | null,
};

export type OnDeleteIwiSubscription = {
  onDeleteIwi?:  {
    __typename: "Iwi",
    id: string,
    name: string,
    Users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
    } | null,
    iwicollectiveID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    email: string,
    first_name: string,
    last_name: string,
    avatar_url?: string | null,
    phone_number: string,
    account_verified: boolean,
    iwiID?: string | null,
    user_type?: string | null,
    title?: string | null,
    job_title?: string | null,
    user_attribute?: string | null,
    status?: string | null,
    IwiCollective?:  {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    userIwiCollectiveId?: string | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    email: string,
    first_name: string,
    last_name: string,
    avatar_url?: string | null,
    phone_number: string,
    account_verified: boolean,
    iwiID?: string | null,
    user_type?: string | null,
    title?: string | null,
    job_title?: string | null,
    user_attribute?: string | null,
    status?: string | null,
    IwiCollective?:  {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    userIwiCollectiveId?: string | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    email: string,
    first_name: string,
    last_name: string,
    avatar_url?: string | null,
    phone_number: string,
    account_verified: boolean,
    iwiID?: string | null,
    user_type?: string | null,
    title?: string | null,
    job_title?: string | null,
    user_attribute?: string | null,
    status?: string | null,
    IwiCollective?:  {
      __typename: "IwiCollective",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    userIwiCollectiveId?: string | null,
  } | null,
};
