import DualTitle from "../who-we-are/modules/dual-title";
import ContactUsForm from "@src/app/public-pages/contact-us/modules/contact-us-form";

const contactUsParagraph =
  "If you're seeking additional information about Te Manawa o Ngā Wai or the Affordable Water Reforms, and how they may affect you, please feel free to reach out by filling out the contact form.";

export const ContactUsPage: React.FC = () => {
  return (
    <section className="bg-gray-50 md:mt-12 px-16 pb-16 lg:pt-16 flex flex-col lg:flex-row lg:justify-evenly">
      <div className={"mt-12 max-w-2xl"}>
        <DualTitle title1={"Whakapā mai"} title2={"Contact us"} />
        <p className="mb-10 font-sans font-light leading-loose tracking-wide lg:col-span-2 lg:row-span-1">
          {contactUsParagraph}
        </p>
      </div>
      <div className="max-w-3xl">
        <ContactUsForm />
      </div>
    </section>
  );
};
