import { saveAs } from 'file-saver';

const downloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
    try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        saveAs(blob, fileName);
    } catch (error) {
        console.error("Error downloading file:", error);
    }
};

export default downloadFile;