import React from "react";
import { ErrorMessage, Field } from "formik";

type TextInputComboProps = {
  name: string;
  type: "email" | "text" | "password";
  label: string;
  disabled?: boolean;
};
export const TextInputCombo = (props: TextInputComboProps) => {
  return (
    <div>
      <label htmlFor={props.name} className="block text-sm font-medium leading-6 text-gray-700">
        {props.label}
      </label>
      <div className="mt-2 col">
        <Field
          name={props.name}
          disabled={props.disabled}
          type={props.type}
          className={
            "pl-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          }
        />
        <ErrorMessage name={props.name}>
          {(msg) => <div className="text-red-600 text-sm mt-1">{msg}</div>}
        </ErrorMessage>
      </div>
    </div>
  );
};

export default TextInputCombo;
