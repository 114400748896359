import React from "react";

type DualTitleProps = {
  title1: string;
  title2: string;
};
export const DualTitle: React.FC<DualTitleProps> = (props) => {
  return (
    <div className={"mb-4"}>
      <div className={"inline-flex bg-brand-highlightBlue px-4 py-3 mb-6"}>
        <h2 className={"text-2xl lg:text-5xl tracking-tight font-semibold text-gray-700"}>
          {props.title1}
        </h2>
      </div>
      <h4 className={"font-bold text-gray-700 px-2"}>{props.title2}</h4>
    </div>
  );
};

export default DualTitle;
