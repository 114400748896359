import React from "react";
import { createBrowserRouter, redirect, RouterProvider } from "react-router-dom";
// public imports
import PublicLayout from "@src/app/public-pages/public-layout";
import publicIndex from "@src/app/public-pages/index";
import LoginPage from "@src/app/public-pages/auth/login/login-page";
import SignUpPage from "@src/app/public-pages/auth/sign-up/sign-up-page";
import VerifyPage from "@src/app/public-pages/auth/verify/verify-page";

// user imports
import userIndex from "@src/app/user-pages";
import UserLayout from "@src/app/user-pages/user-layout";

import config from "@src/amplifyconfiguration.json";
import { Amplify } from "aws-amplify";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { WhoWeArePage } from "@src/app/public-pages/who-we-are";
import WhatWeDoPage from "@src/app/public-pages/what-we-do/what-we-do-page";
import { ContactUsPage } from "@src/app/public-pages/contact-us/contact-us";
import BulletinDetails from "@src/app/user-pages/bulletin-details";
import CreateBulletins from "@src/app/user-pages/create-bulletin";
import CreateUser from "@src/app/user-pages/create-user";
import UpdateUser from "@src/app/user-pages/update-user";
import UsersPage from "@src/app/user-pages/users";
import Userprofile from "@src/app/user-pages/user-profile";
import ForgotPasswordPage from "@src/app/public-pages/auth/forgot-password/forgot-password-page";
import useAuthStore from "@src/stores/auth-store";
import UpdateBulletin from "./user-pages/update-bulletin";
import FaqPage from "@src/app/public-pages/faq";

Amplify.configure(config);

const authUser = async () => {
  try {
    return await getCurrentUser();
  } catch (e) {
    console.log(e);
    // return redirect to re-route
    return redirect("/");
  }
};

const getUserRole = () => {
  const { userProfile } = useAuthStore.getState();
  return userProfile?.user_type;
};

const authAdmin = async () => {
  try {
    const user = await getCurrentUser();
    const session = await fetchAuthSession();
    if (user && session) {
      const groups = (session.tokens?.idToken?.payload["cognito:groups"] as Array<string>) || [];
      if (groups && groups.includes("Admins")) {
        return user;
      } else {
        throw new Error("User is not an admin");
      }
    }
  } catch (e) {
    console.log(e);
    return redirect("/user");
  }
};

const router = createBrowserRouter([
  {
    // no auth required
    id: "root",
    path: "/",
    loader: () => null,
    Component: PublicLayout,
    children: [
      {
        index: true,
        Component: publicIndex,
      },
      {
        path: "who-we-are",
        Component: WhoWeArePage,
      },
      {
        path: "what-we-do",
        Component: WhatWeDoPage,
      },
      {
        path: "contact-us",
        Component: ContactUsPage,
      },
      {
        path: "faq",
        Component: FaqPage,
      },
    ],
  },
  {
    // no auth required for this or its children
    id: "auth",
    path: "/auth",
    Component: PublicLayout,
    children: [
      {
        path: "login",
        // action: loginAction,
        Component: LoginPage,
      },
      // NOTE: this is disabled to prevent users being able to sign up
      // {
      //   path: "sign-up",
      //   // action: signUpAction,
      //   Component: SignUpPage,
      // },
      {
        path: "verify",
        // action: signUpAction,
        Component: VerifyPage,
      },
      {
        path: "forgot-password",
        // action: forgotPasswordAction,
        Component: ForgotPasswordPage,
      },
    ],
  },
  {
    // this route + children protected by user loader
    id: "user",
    path: "/user",
    loader: authUser,
    Component: UserLayout,
    children: [
      {
        index: true,
        Component: userIndex,
      },

      {
        path: "user/profile",
        Component: Userprofile,
      },
      {
        path: "bulletins/:id",
        Component: BulletinDetails,
      },
      {
        path: "bulletins/update/:id",
        Component: UpdateBulletin,
        loader: async () => {
          const role = getUserRole();
          if (role === "Writer") {
            return true;
          }
          return redirect("/user");
        },
      },
      {
        path: "bulletins/create",
        Component: CreateBulletins,
      },
    ],
  },
  {
    id: "admin",
    path: "/admin",
    loader: authAdmin,
    Component: UserLayout,
    children: [
      {
        path: "users",
        Component: UsersPage,
      },
      {
        path: "users/create",
        Component: CreateUser,
      },
      {
        path: "users/update",
        Component: UpdateUser,
      },
    ],
  },
]);

export const BaseRouter = () => {
  return <RouterProvider router={router}></RouterProvider>;
};

export default BaseRouter;
