import { Polygon, Popup } from "react-leaflet";

//@ts-ignore
export const CustomMarker = ({ feature, cssRules }) => {
  const { Entity, AREA_SQ_KM, Iwi } = feature.properties;
  // @ts-ignore
  const coordinates = feature.geometry.coordinates[0].map((coord) => [coord[1], coord[0]]); // Swap lat-lng for Leaflet

  return (
    // @ts-ignore
    <Polygon positions={coordinates} color={cssRules.color}>
      <Popup>
        <div className="text-body font-paragraph">
          <div className="mb-2">Zone: {Entity ? "Entity: " + Entity : "N/A"}</div>

          <div className="mb-2">
            Area (Ha): {AREA_SQ_KM ? AREA_SQ_KM?.toLocaleString() + " km\u00B2" : "N/A"}
          </div>
          <ol
            className="max-h-[150px]"
            style={{
              overflowY: "auto",
            }}>
            {Iwi && Iwi.length > 0 ? (
              Iwi.map((iwi: string) => <li key={iwi}>{iwi}</li>)
            ) : (
              <li>N/A</li>
            )}
          </ol>
        </div>
      </Popup>
    </Polygon>
  );
};
