import React from "react";
import VerifyForm from "@src/app/public-pages/auth/verify/modules/verify-form";
import ForgotPasswordScreen from "@src/app/public-pages/auth/forgot-password/modules/forgot-password-screen";
import ForgotPasswordForm from "@src/app/public-pages/auth/forgot-password/modules/forgot-password-form";

export const ForgotPasswordPage = () => {
  return (
    <div className={"flex justify-center"}>
      <div className="flex min-h-full flex-1 relative overflow-hidden">
        <div className="flex flex-1 flex-col justify-center">
          {/*<Logo className={"absolute opacity-5 -z-20 rotate-cw"} />*/}
          <div className="mx-auto w-full max-w-sm">
            <div>
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Forgot Password
              </h2>
              <div className={"flex items-center"}>
                <p className="mt-2 text-sm leading-6 text-gray-500">
                  Let's verify your account
                </p>
                <div
                  className={
                    "mt-2 cursor-pointer px-2 text-sm font-semibold text-brand-primary hover:text-brand-accent"
                  }></div>
              </div>
            </div>

            <div className="mt-10 mb-5">
              <ForgotPasswordForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
