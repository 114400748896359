/*

Usage example:

const {
  execute: executeVerify,
  isLoading: isVerifyLoading,
  errorMessage: verifyErrorMessage,
} = useApiCall(apiVerifyAccountQuery, {
  errorHandlers: {
    CodeMismatchException: error => {
      setErrorMessage("Incorrect validation code. Please try again.");
    },
    onSuccess: data => {
      console.log("Success", data);
      setSuccessMessage("Account verified successfully");
    },
    onError: error => {
      // this is available for use, but if logic depends on the error message, it's better to handle some things in the useEffect
      console.log("Error", error);
    },
    errorHandlers: {
      // [UserNotFoundException: User does not exist.]
      UserNotFoundException: () => {
        setErrorMessage("Account does not exist or password incorrect");
      },
    },
  },
});

results are never awaited, need to check the result status and data in the calling component. Current errorHandlers are valid for AWS Auth errors.
Testing TBC for http calls

*/

import { useState } from "react";

interface ApiCallOptions<T> {
  errorHandlers?: Record<string, (error: any) => void>;
  onSuccess?: (data: T) => void;
  onError?: (data: T) => void;
}

export interface ApiResponse<T> {
  data?: T;
  status: number;
  errorMessage?: string;
}

export const useApiCall = <T, A extends any[]>(
  apiFunc: (...args: A) => Promise<T>,
  options: ApiCallOptions<T> = {}
) => {
  const [result, setResult] = useState<ApiResponse<any>>({} as ApiResponse<T>);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const execute = async (...args: A): Promise<ApiResponse<T>> => {
    setIsLoading(true);
    setErrorMessage(""); // Reset previous errors

    try {
      const response = await apiFunc(...args); // Execute the passed API function with arguments
      const payload = { data: response, status: 200 }; // Create the payload

      // If a successHandler is provided, call it with the data
      if (options.onSuccess) {
        options.onSuccess(response);
      }

      setResult(payload as ApiResponse<T>); // Set the result from the API call
      return payload; // Assuming successful operation if no error is thrown
    } catch (error: any) {
      // Call the onError handler if provided
      if (options.onError) {
        options.onError(error);
      }

      if (options.errorHandlers) {
        const errorName = error.name; // Get the name of the error
        if (errorName && options.errorHandlers[errorName]) {
          options.errorHandlers[errorName](error); // Call the corresponding handler
        } else {
          setErrorMessage(error.message || "An error occurred while making the request");
        }
      } else {
        setErrorMessage(error.message || "An error occurred while making the request");
      }

      const returnObject = { status: error.response?.status || 500, errorMessage }; // Return error status and message
      setResult(returnObject);
      return returnObject; // Return error status and message
    } finally {
      setIsLoading(false); // Set loading to false in either case
      // setResult(result);
    }
  };

  return { result, isLoading, errorMessage, execute };
};
