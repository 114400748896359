import Jamie from "@src/assets/images/who-we-are-page/profile/jamie-tuuta-profile.jpg";
import Parekawhia from "@src/assets/images/who-we-are-page/profile/parekawhia-profile.jpg";
import David from "@src/assets/images/who-we-are-page/profile/david-taipari-profile.jpg";
import Donna from "@src/assets/images/who-we-are-page/profile/donna-gardiner-profile.jpg";
import Geoff from "@src/assets/images/who-we-are-page/profile/geoff-rolleston-profile.jpg";
import George from "@src/assets/images/who-we-are-page/profile/george-asher-profile.png";
import { MapComponent } from "./modules/map-component";
import React from "react";
import DualTitle from "./modules/dual-title";

const governanceRepresentatives = [
  {
    name: "Jamie Tuuta",
    role: "Board Chair",
    imageUrl: Jamie,
    waka: "Aotea, Kurahaupo, Tokomaru",
  },
  {
    name: "Geoff Rolleston",
    role: "Governance Representative",
    imageUrl: Geoff,
    waka: "Te Arawa",
  },
  {
    name: "Parekawhia McLean",
    role: "Governance Representative",
    imageUrl: Parekawhia,
    waka: "Tainui",
  },
  {
    name: "David Taipari",
    role: "Governance Representative",
    imageUrl: David,
    waka: "Tainui",
  },
  {
    name: "Donna Gardiner",
    role: "Governance Representative",
    imageUrl: Donna,
    waka: "Takitimu (Tauranga ki te tonga)",
  },
  {
    name: "George Asher",
    role: "Governance Representative",
    imageUrl: George,
    waka: "Te Arawa",
  },
  {
    name: "Te Rangikaheke Bidois",
    role: "Governance Representative (Alternate)",
    imageUrl: "",
    waka: "Te Arawa (Alternate)",
  },
];

export const WhoWeArePage: React.FC = () => {
  return (
    <section className="bg-gray-50 overflow-hidden">
      {/*Who We Are*/}
      <section
        className={"md:flex md:p-12 md:bg-gradient-to-r from-white via-white to-[#03b2d927]"}>
        <div className={"flex-1"}>
          <div
            className={
              "font-sans pt-8 pb-1 px-4 bg-gradient-to-r from-white via-white to-[#03b2d927] md:bg-none"
            }>
            <DualTitle title1={"Ko wai tātou"} title2={"Who we are"} />
          </div>
          <div
            className={
              "p-4 text-sm tracking-widest text-[rgba(61,_61,_61,_0.87)] max-w-3xl leading-6 text-justify"
            }>
            <p className={"mb-4"}>
              Te Manawa ō Ngā Wai (TMoNW) is the iwi secretariat established for the previous Entity
              B, who is responsible to the63 iwi, 701 hapū and 428 marae in the previous Entity B
              area. TMoNW was established in June 2021 by Tā Tumu Te Heu Heu and Tā Toby Curtis as a
              call to action in anticipation of the incoming reforms. At that time TMoNW was called
              the Entity B Iwi Secretariat acknowledging the placement of these iwi as per the
              drafting of the Water Services Entities Bill and the expectation that it would reach
              Royal Assent.
            </p>
            <p>
              Following the passing of Tā Toby Curtis the name that he had gifted to this kaupapa
              was adopted by our iwi and we are now known as Te Manawa ō Ngā Wai.
            </p>
          </div>
        </div>
        <div className={"flex-1 z-10"}>
          <MapComponent />
        </div>
      </section>

      {/* Team section */}
      <section className={"md:px-10"}>
        <div
          className={
            "font-sans pt-8 pb-1 px-4 bg-gradient-to-r from-white via-white to-[#03b2d927] md:bg-none md:text-right md:flex md:flex-row md:justify-between"
          }>
          <div className={""}>
            <p
              className={
                "hidden p-4 text-sm tracking-widest text-[rgba(61,_61,_61,_0.87)] max-w-3xl leading-6 text-justify md:block"
              }>
              Our governance body members represent their respective rohe and are responsible for
              setting the strategic direction of TMoNW and its work programme.
            </p>
          </div>
          <DualTitle title1={"Te Manawa ō Ngā Wai"} title2={"Governance representatives"} />
        </div>
        <div
          className={
            "p-4 text-sm tracking-widest text-[rgba(61,_61,_61,_0.87)] max-w-3xl leading-6 text-justify md:hidden"
          }>
          <p className={""}>
            Our governance body members represent their respective rohe and are responsible for
            setting the strategic direction of TMoNW and its work programme.
          </p>
        </div>
        <div>
          <ul role="list" className="grid grid-cols-3 md:grid-cols-7">
            {governanceRepresentatives.map((person) => (
              <li key={person.name} className="">
                <div className={`flex flex-1 flex-col p-2`}>
                  {person.imageUrl ? (
                    <img
                      className="mx-auto h-20 w-20 flex-shrink-0 rounded-full "
                      src={person.imageUrl}
                      alt=""
                    />
                  ) : (
                    <span className="mx-auto inline-flex h-20 w-20 items-center justify-center rounded-full bg-gray-500">
                      <span className="font-medium leading-none text-white truncate p-2">
                        {person.name}
                      </span>
                    </span>
                  )}

                  <div className={"flex flex-col justify-center text-center"}>
                    <h3 className="mt-4 text-xs font-medium text-gray-900">{person.name}</h3>
                    <dl className="mt-1 flex flex-grow flex-col justify-between">
                      <dt className="sr-only">Title</dt>
                      <dd className="text-xs text-gray-500 italic">{person.role}</dd>
                      <dd className="text-xs text-gray-500 mt-1">{person.waka}</dd>
                      <dt className="sr-only">Role</dt>
                    </dl>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>

      {/* Secretariat */}
      <section className={"md:px-10 md:py-8 mb-12"}>
        <div
          className={
            "font-sans pt-8 pb-1 px-4 bg-gradient-to-r from-white via-white to-[#03b2d927] md:bg-none md:text-right md:flex md:flex-row-reverse md:justify-between"
          }>
          <div className={""}>
            <p
              className={
                "hidden p-4 text-sm tracking-widest text-[rgba(61,_61,_61,_0.87)] max-w-4xl leading-6 text-justify md:block"
              }>
              Our secretariat team is responsible for implementing the Te manawa o ngā wai work
              plan. The team, including Deliah Balle (Te Arawa, Tainui, Aotea), Arihia Hoani-Waaka
              (Mataatua), and Maurea Waaka (Tainui), provides technical and advocacy support,
              engages with hapū and iwi, and fulfills all reporting requirements to iwi, hapū,
              central government, and the board.
            </p>
          </div>
          <DualTitle title1={"Te Manawa ō Ngā Wai"} title2={"Secretariat"} />
        </div>
        <div
          className={
            "p-4 text-sm tracking-widest text-[rgba(61,_61,_61,_0.87)] max-w-3xl leading-6 text-justify md:hidden"
          }>
          <p className={""}>
            Our secretariat team is responsible for implementing the Te manawa o ngā wai work plan.
            The team, including Deliah Balle (Te Arawa, Tainui, Aotea), Arihia Hoani-Waaka
            (Mataatua), and Maurea Waaka (Tainui), provides technical and advocacy support, engages
            with hapū and iwi, and fulfills all reporting requirements to iwi, hapū, central
            government, and the board.
          </p>
        </div>
      </section>
    </section>
  );
};
