import React, { useEffect, useState } from "react";
import UserTableHeader from "./user-table-header";
import UserTableRow from "./user-table-row";
import { IAppUserDetails } from "@src/types/model/user";
import ReactPaginate from "react-paginate";
import { ChevronLeft, ChevronRight } from "lucide-react";

interface Props {
  data: IAppUserDetails[];
}

const UserTable: React.FC<Props> = (props: Props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const pageCount = Math.ceil(props.data.length / pageSize);

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
  };

  const handlePageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPage = parseInt(e.target.value) - 1;
    setCurrentPage(selectedPage);
  };


  const pageData = props.data.slice(
    currentPage * pageSize,
    (currentPage + 1) * pageSize
  );

  return (
    <div className="container mx-auto">
      <div className="p-2 overflow-hidden rounded-lg bg-white border border-black/10 ">
        <div className="overflow-x-auto">
          <div className="border-t border-white/10 pt-4">
            <table className=" w-full whitespace-nowrap text-left">
              <UserTableHeader />
              <tbody className="divide-y divide-white/5">
                {pageData?.map((user) => <UserTableRow key={`user_${user.id}`} user={user} />)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mt-5 w-full">
        <div>
          <span>Page </span>
          <select value={currentPage + 1} className="border px-2 py-1 rounded-lg mr-2" onChange={handlePageChange}>
            {[...Array(pageCount)].map((_, page) => (
              <option key={page + 1} value={page + 1}>{page + 1}</option>
            ))}
          </select>
          <span>of {pageCount}</span>
        </div>
        <div>
          <ReactPaginate
            className="flex paginationDots"
            previousLabel={<ChevronLeft size={15} />}
            nextLabel={<ChevronRight size={15} />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={0.5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageClassName={"page-item"}
            previousClassName={"page-item"}
            nextClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
            forcePage={currentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default UserTable;