import React, { useState } from "react";
import { Form, Formik } from "formik";
import LoadingSpinner from "@src/components/overlays/LoadingSpinner";
import Button from "@src/components/ui/button";
import TextInputCombo from "@src/components/forms/text-input-combo";
import * as yup from "yup";
import AlertModal, { AlertType } from "@src/components/overlays/alert-modal";
import { useNavigate } from "react-router-dom";
import TransientModalWrapper from "@src/components/overlays/transient-modal-wrapper";
import {
  confirmResetPassword,
  type ConfirmResetPasswordInput,
  resetPassword,
  type ResetPasswordOutput,
} from "aws-amplify/auth";

const VERIFY_INITIAL = {
  email: "",
};

const VERIFY_SCHEMA = yup.object().shape({
  email: yup.string().email().required(),
});

const CODE_SCHEMA = yup.object().shape({
  code: yup.string().required(),
  // email: yup.string().email().required(),
  newPassword: yup
    .string()
    .required("Password is required")
    .matches(/^(?=.*[0-9]).{6,}$/, "Password must be at least 6 characters long and contain at least 1 number"),
});

export const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const [verifyCodeError, setVerifyCodeError] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isVerifyCodeModalOpen, setIsVerifyCodeModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = useState(false);
  const CODE_INITIAL = {
    code: "",
    email: email,
    newPassword: "",
  };

  const handleResetPasswordNextSteps = (output: ResetPasswordOutput) => {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case "CONFIRM_RESET_PASSWORD_WITH_CODE":
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        console.log(`Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`);
        setIsVerifyEmailModalOpen(false);
        setIsVerifyEmailModalOpen(true);
        break;
      case "DONE":
        console.log("Successfully reset password.");
        break;
    }
  };

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {successMessage && (
        <AlertModal
          alertType={AlertType.success}
          message={successMessage}
          title={"Verified"}
          onClose={() => {
            setSuccessMessage("");
            navigate("/auth/login");
          }}
        />
      )}
      {errorMessage && (
        <AlertModal title={"Error"} message={errorMessage} onClose={() => setErrorMessage("")}
                    alertType={AlertType.error} />
      )}
      <TransientModalWrapper
        title={"Verify Email Code"}
        children={
          <Formik
            initialValues={CODE_INITIAL}
            onSubmit={async (values, { setSubmitting }) => {
              // reset error state
              setIsLoading(true)
              setVerifyCodeError("");
              try {
                const input: ConfirmResetPasswordInput = {
                  confirmationCode: values.code,
                  newPassword: values.newPassword,
                  username: values.email,
                };
                await confirmResetPassword(input);
                setSuccessMessage("Password reset successfully");
                setIsVerifyCodeModalOpen(false);
              } catch (e: any) {
                setVerifyCodeError(e.message);
              } finally {
                setIsLoading(false)
              }
            }}
            validationSchema={CODE_SCHEMA}>
            {({ isSubmitting }) => (
              <Form className={"flex flex-col gap-4"}>
                <TextInputCombo name={"code"} type={"text"} label={"Code"} />
                {/* <TextInputCombo name={"email"} type={"text"} label={"Email Address"} /> */}
                <TextInputCombo name={"newPassword"} type={"password"} label={"New Password"} />
                {verifyCodeError && <div className="text-red-600 text-sm mt-1">{verifyCodeError}</div>}
                <Button label={"Verify"} type={"submit"} disabled={isSubmitting} />
              </Form>
            )}
          </Formik>
        }
        isOpen={isVerifyEmailModalOpen}
        setIsOpen={setIsVerifyEmailModalOpen}
      />

      <Formik
        initialValues={VERIFY_INITIAL}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setIsLoading(true);
            setEmail(values.email);
            const output = await resetPassword({ username: values.email });
            handleResetPasswordNextSteps(output);
          } catch (e: any) {
            setErrorMessage(e.message);
          } finally {
            setIsLoading(false);
          }
        }}
        validationSchema={VERIFY_SCHEMA}>
        {({ isSubmitting }) => (
          <Form className={"flex flex-col gap-4"}>
            <TextInputCombo name={"email"} type={"text"} label={"Email address"} />
            <Button label={"Verify"} type={"submit"} disabled={isSubmitting} />
            {/*<FormDebug />*/}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPasswordForm;
