import React from "react";
import AnimatedCheckListItem from "./animation-check-list-item";

interface IContentContainerProps {
  serviceName: string;
  serviceNameAttribues: string[];
  serviceOutcomes: string;
  serviceDeliverables: string[];
}

const WorkStreamsStack: React.FC<IContentContainerProps> = (props) => {
  return (
    <article className="font-sans grid grid-cols-1 lg:grid-cols-5 lg:grid-rows-3 gap-6  sm:mt-20 pb-10">
      <header className="lg:col-span-3 h-fit flex flex-col flex-wrap justify-start">
        <h3 className="text-xl mr-5 pb-2 tracking-widest">{props.serviceName}</h3>
        <ul className="flex flex-wrap  sm:text-3xl">
          {props.serviceNameAttribues.map((attribute, index) => (
            <li
              key={index}
              className="mr-1 font-semibold bg-brand-primaryHover text-brand-primaryBlue text-sm tracking-widest mb-2 px-2.5 py-0.5 rounded ">
              {attribute}
            </li>
          ))}
        </ul>
      </header>
      <header className="lg:col-span-3 lg:row-span-2 lg:col-start-1 lg:row-start-2">
        <h4 className="text-xl pb-3">Outcomes</h4>
        <p className="text-justify sm:text-left font-light leading-loose text-gray-800">
          {props.serviceOutcomes}
        </p>
      </header>
      <article className="lg:px-8 lg:row-span-3 lg:col-start-4 lg:col-span-2 lg:row-start-1">
        <h4 className="text-xl tracking-widest lg:ml-6">Deliverables</h4>
        <ul className="list-none mt-16 mb-8 space-y-2 text-lg leading-8">
          {props.serviceDeliverables.map((deliverable, index) => (
            <AnimatedCheckListItem key={index} deliverable={deliverable} />
          ))}
        </ul>
      </article>
    </article>
  );
};

export default WorkStreamsStack;
