import React from 'react'
import Breadcrumbs from '@src/components/ui/breadcrumbs'
import { updateUserBreadcrumbItems } from '@src/constant'
import UserForm from './modules/update-user-form'

const UpdateUser = () => {
    return (
        <div>
            <Breadcrumbs items={updateUserBreadcrumbItems} />
            <UserForm />
        </div>
    )
}

export default UpdateUser
