import React from "react";
import { ErrorMessage, Field, useFormikContext } from "formik";

type SelectInputProps = {
  name: string;
  options: string[];
  label: string;
  onChange?: (val: any) => void;
};

export const SelectInput = (props: SelectInputProps) => {
  const { setFieldValue } = useFormikContext();

  const handleSelectChange = (e: { target: { value: any } }) => {
    const selectedValue = e.target.value;
    setFieldValue(props.name, selectedValue);
  };

  return (
    <div>
      <label htmlFor={props.name} className="block text-sm font-medium leading-6 text-gray-900">
        {props.label}
      </label>
      <div className="mt-2 col"></div>
      <Field
        as="select"
        name={props.name}
        onChange={handleSelectChange}
        className={
          "pl-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
        }>
        <option>Select an option</option>
        {props.options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </Field>
      <ErrorMessage name={props.name}>
        {(msg) => <div className="text-red-600 text-sm mt-1">{msg}</div>}
      </ErrorMessage>
    </div>
  );
};

export default SelectInput;
