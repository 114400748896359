import React from "react";
import { useInView } from "react-intersection-observer";
import { CheckIcon } from "@heroicons/react/24/outline";

interface IContentContainerProps {
  deliverable: string;
}

const AnimatedCheckListItem: React.FC<IContentContainerProps> = (props) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <li ref={ref} className="flex items-center">
      <CheckIcon
        className={`${
          inView ? "animate-bounce-once" : ""
        } w-1/12 lg:w-1/6 h-6 text-blue-600 mr-1 mt-1`}
      />
      <p className="w-5/6 text-xs sm:text-sm md:text-base font-light text-gray-800">
        {props.deliverable}
      </p>
    </li>
  );
};

export default AnimatedCheckListItem;
