import NZMapMobile from "@src/assets/images/home/NewZealandMapMobile.png";
import { useNavigate } from "react-router";

export const MapComponent: React.FC = () => {
  const navigate = useNavigate();
  const handleClick = (destination: string) => {
    navigate(`/${destination}`);
  };

  return (
    <div className="grid grid-cols-5 h-full">
      <article className="col-span-3 flex flex-col justify-center">
        <div className={"lg:ml-[2vw] flex flex-col lg:gap-6 lg:pt-6"}>
          {/*<h4 className="text-md tracking-wider font-semibold text-[#03b2d9] font-sans">*/}
          {/*  Who we are*/}
          {/*</h4>*/}
          <div className={"mb-4"}>
            <h1 className="text-2xl lg:text-5xl tracking-tight font-semibold text-gray-700 font-sans mb-2">
              Ko wai mātou?
            </h1>
            <div className={"bg-[#03b2D9] opacity-20 h-2 w-1/3 sm:w-1/4 md:w-1/6"} />
          </div>
          <div className="text-xs lg:text-2xl tracking-tight text-[rgba(61,_61,_61,_0.87)] pb-5 max-w-3xl">
            Responsible to the 63 iwi, 701 hapū and 428 marae in the previous Entity B area in the
            Water Services Reform. Te Manawa ō Ngā Wai is an iwi-led initiative to future-proofing
            our water systems for both present generations and those to come.
          </div>
          <button className="w-[150px] border-solid border-[#255f97] px-6 py-1 cursor-pointer border-2 rounded-[30px] mb-8 whitespace-nowrap">
            <div
              onClick={() => handleClick("who-we-are")}
              className="text-xs md:text-sm font-bold text-textPrimary">
              Learn more
            </div>
          </button>
        </div>
      </article>
      <section className={"relative col-span-2 flex justify-center overflow-hidden"}>
        <img
          onClick={() => handleClick("who-we-are")}
          src={NZMapMobile}
          alt="NZMAP"
          className="col-span-2 absolute top-7 sm:-top-2 md:-top-3 lg:-top-1 lg:max-h-[48vh] cursor-pointer"
        />
      </section>
    </div>
  );
};
