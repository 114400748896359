import { signUp, SignUpInput } from "aws-amplify/auth";
import { generateClient } from "@aws-amplify/api";
import { IAppUser } from "@src/types/model/user";

export const apiSignUpQuery = async (
  email: string,
  password: string,
  first_name: string,
  last_name: string,
  phone_number: string
): Promise<IAppUser> => {
  const client = generateClient();
  // TODO Query to check if the user already exists

  // if (existingUsers.length > 0) {
  //   throw new Error("User already exists with this email");
  // }

  const input: SignUpInput = {
    username: email,
    password: password,
  };

  const signUpResult = await signUp(input);

  console.log("signUpResult", signUpResult);

  return {
    id: signUpResult.userId,
    first_name: first_name,
    last_name: last_name,
    email: email,
    phone_number: phone_number,
    account_verified: false,
  } as IAppUser;
};
