import React from "react";

interface IButtonProps {
  title: string;
  IconComponent: React.ElementType<{
    className?: string;
    "aria-hidden"?: boolean | "true" | "false";
  }>;
  onClick: () => void;
  className?: string;
  bgColor?: string;
}

export const IconButton: React.FC<IButtonProps> = (props) => {
  return (
    <div
      className={`cursor-pointer ${props.bgColor ?? "bg-blue-900"} ml-auto flex items-center gap-x-1 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${props.className}`}
      onClick={props.onClick.bind(this)}>
      <props.IconComponent className="-ml-1.5 h-5 w-5" aria-hidden={true} />
      {props.title}
    </div>
  );
};

export default IconButton;
