import React from "react";
import HeroComponent from "@src/components/ui/hero-component";
import BodyContentContainer from "@src/components/containers/body-content-container";
import { MapComponent } from "@src/app/public-pages/index/modules/map-component";

// images
import WaterHands from "@src/assets/images/home/water-hands.jpg";
import WaikatoRiverGrass from "@src/assets/images/home/waikato-river-grass.jpg";
import GroupPhoto from "@src/assets/images/who-we-are-page/group_photo.png";

const pageContent = [
  {
    title1: "He manawa Atua",
    title2: "The significance of water. The relationship between the water and the people",
    paragraph:
      "Te Manawa o Ngā Wai was established in June 2021 in response to the 2021 Water Services Act. The main purpose of this Act is to ensure that drinking water suppliers provide safe drinking water.",
    cardImage: WaterHands,
    reverseLayout: false,
  },
  {
    title1: "He manawa whenua",
    title2: "The preservation of tribal autonomy over lands and assets",
    paragraph:
      "The work we do supports marae, hapū, and iwi who belong to Mataatua, Te Arawa, Tainui, Aotea, Kurahaupo, Tokomaru, and Takitimu (Tauranga ki te tonga).",
    cardImage: GroupPhoto,
    reverseLayout: true,
  },
  {
    title1: "He manawa tangata",
    title2: "The importance and significance of authority and action",
    cardImage: WaikatoRiverGrass,
    paragraph:
      "Te Manawa o Ngā Wai is now working toward devolving autonomy to the various rohe we work with so that they are in a strong position to advance their aspirations and the opportunities available.",
    reverseLayout: false,
  },
];

export const Index = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-8 lg:grid-rows-2 gap-0 bg-gray-50 mb-20">
      {/* Hero */}
      <section className="font-sans col-span-8 row-span-2 relative flex flex-row items-start py-3 sm:py-5 overflow-hidden lg:h-[46vh]">
        <HeroComponent />
      </section>
      {/* Ko wai tåtou - the section color has opacity, this gives the illusion of a custom gradient*/}
      <section className="col-span-8 row-span-2 bg-gradient-to-r from-white via-white to-[#03b2d927]">
        <BodyContentContainer>
          <div className="mt-3 lg:px-24 sm:h-[26vh] md:h-[30vh] lg:h-[41vh]">
            <MapComponent />
          </div>
        </BodyContentContainer>
      </section>
      {/* Desktop Render */}
      {pageContent.map((i, index) => (
        <section
          className={`hidden col-span-8 font-sans md:block ${
            index % 2 ? "bg-gradient-to-r from-white via-white to-[#03b2d927]" : ""
          }`}>
          <div className={"grid grid-cols-12"}>
            <div className={"col-span-1"} />
            <article className={"col-span-10 p-2"}>
              <div className={`flex gap-4 ${index % 2 ? "flex-row-reverse" : ""}`}>
                <div className="flex-1 p-4 flex justify-center items-center">
                  <div className="inline-flex flex-col items-center">
                    <div className="bg-brand-highlightBlue px-4 py-2">
                      <h3 className="text-2xl lg:text-5xl tracking-tight font-semibold text-gray-700">
                        {i.title1}
                      </h3>
                    </div>
                    <div className="max-w-lg text-center mt-8">
                      <p className="font-semibold text-lg text-gray-700">{i.title2}</p>
                    </div>
                  </div>
                </div>
                <div className={"flex-1"}>
                  <div className={"aspect-w-16 aspect-h-5"}>
                    <img
                      src={i.cardImage}
                      alt="Water Hands"
                      className={"w-full h-full object-cover p-4"}
                    />
                  </div>
                  <div className={"p-4"}>
                    <p
                      className={
                        "text-md tracking-tight text-[rgba(61,_61,_61,_0.87)] max-w-3xl leading-6 text-justify"
                      }>
                      {i.paragraph}
                    </p>
                  </div>
                </div>
              </div>
            </article>
            <div className={" hidden col-span-1"} />
          </div>
        </section>
      ))}
      {/* Mobile Render */}
      {pageContent.map((i) => (
        <section key={i.title1} className={"font-sans col-span-full row-span-2 md:hidden"}>
          <div className={""}>
            <article className={"flex-row-reverse grid grid-rows-3 relative"}>
              <div className="flex-1 w-full max-h-[15vh] lg:max-h-[30vh] overflow-hidden row-span-1">
                <div className="aspect-w-19 aspect-h-9">
                  <img src={i.cardImage} alt="Your Image" className="w-full h-full object-cover" />
                </div>
              </div>
              {/* absolute */}
              <div
                className={
                  "inline-flex bg-brand-highlightBlue px-4 py-2 row-span-1 absolute top-1/4 left-6 mt-3"
                }>
                <h4 className={"text-2xl lg:text-5xl tracking-tight font-semibold text-gray-700"}>
                  {i.title1}
                </h4>
              </div>

              <div
                className={"row-span-1 p-4 bg-gradient-to-r from-white via-white to-[#03b2d927]"}>
                <p className={"font-semibold text-sm mt-12 text-gray-700"}>{i.title2}</p>
              </div>
              <div className={"px-4 pt-4"}>
                <p
                  className={
                    "text-sm tracking-tight text-[rgba(61,_61,_61,_0.87)] max-w-3xl leading-6 text-justify"
                  }>
                  {i.paragraph}
                </p>
              </div>
            </article>
          </div>
        </section>
      ))}
    </div>
  );
};

export default Index;
