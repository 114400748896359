import React from "react";
import PublicNavigation from "@src/components/navigation/public-navigation";
import { PublicFooter } from "@src/components/ui/footer";
import { Outlet } from "react-router-dom";

export const PublicLayout = () => {
  return (
    <div className="flex w-full min-h-screen flex-col justify-between">
      {/* this public nav should always be at the top */}
      <PublicNavigation />
      {/* this is the outlet for the selected page. This should always be at the top of the space between the nav and footer */}
      <div className={"flex-grow"}>
        <Outlet />
      </div>
      {/* This footer should always be at the bottom */}
      <PublicFooter />
    </div>
  );
};

export default PublicLayout;
