import { IBreadCrumbItem } from "@src/components/ui/breadcrumbs";

export const bulletinsMockData = [
  {
    id: 1,
    date: "Mar 07, 2024",
    status: "archived",
    title: "Test",
    description: "ewtfrewgt",
    author: "Jon Snow",
    imageUrl: "",
  },
  {
    id: 2,
    date: "Mar 07, 2024",
    status: "archived",
    title: "Test",
    description: "ewtfrewgt",
    author: "Jon Snow",
    imageUrl: "",
  },
  {
    id: 3,
    date: "Mar 07, 2024",
    status: "live",
    title: "Test",
    description: "ewtfrewgt",
    author: "Jon Snow",
    imageUrl: "",
  },
];

export const bulletinsBreadcrumbItems: IBreadCrumbItem[] = [
  { name: "Home", url: "/user", current: false },
];

export const bulletinDetailsBreadcrumbItems: IBreadCrumbItem[] = [
  { name: "Bulletins", url: "/user", current: false },
  { name: "Details", url: "/user/bulletins", current: true },
];

export const createBulletinsBreadcrumbItems: IBreadCrumbItem[] = [
  { name: "Bulletins", url: "/user", current: false },
  { name: "Create New", url: "/user/bulletins/create", current: true },
];

export const updateBulletinsBreadcrumbItems: IBreadCrumbItem[] = [
  { name: "Bulletins", url: "/user", current: false },
  { name: "Update Bulletin", url: "/user/bulletins/update", current: true },
];

export const createUserBreadcrumbItems: IBreadCrumbItem[] = [
  { name: "User", url: "/admin/users", current: false },
  { name: "Create New User", url: "/admin/users/create", current: true },
];

export const updateUserBreadcrumbItems: IBreadCrumbItem[] = [
  { name: "User", url: "/admin/users", current: false },
  { name: "Update User", url: "/admin/users/update", current: true },
];
