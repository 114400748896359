import { RemoveOutput, getUrl, remove } from "aws-amplify/storage";

export const storageUtils = {
  async getFileAccessUrl(fileUrl: string): Promise<GetUrlResponse> {
    const result = await getUrl({
      key: fileUrl,
      options: {
        accessLevel: "guest", // can be 'private', 'protected', or 'guest' but defaults to `guest`
        validateObjectExistence: false, // defaults to false
        expiresIn: 900, // validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
      },
    });
    return result;
  },
  async deleteFile(fileKey: string): Promise<RemoveOutput> {
    const result = await remove({
      key: fileKey,
    });
    return result;
  },
};

type GetUrlResponse = {
  expiresAt: Date;
  url: URL;
};
