import L from "leaflet";
import { GeoJSONFeatureProperties } from "../map-types";
import { Feature, Geometry } from "geojson";

export const styleFeature = (feature?: Feature<Geometry, any>): L.PathOptions => {
  // Default color and style if the feature is undefined
  if (!feature || !feature.properties) {
    return { color: "white", weight: 2 };
  }

  const properties = feature.properties as GeoJSONFeatureProperties;
  let color;
  switch (properties.Name) {
    case "Waikato Region":
      color = "rgb(32, 166, 186)";
      break;
    case "Bay of Plenty Region":
      color = "rgb(85, 37, 119)";
      break;
    case "Gisborne Region":
      color = "rgb(85, 37, 119)";
      break;
    case "Hawke's Bay Region":
      color = "rgb(85, 37, 119)";
      break;
    case "Manawatū-Whanganui Region":
      color = "rgb(191, 187, 132)";
      break;
    case "Taranaki Region":
      color = "rgb(63, 37, 149)";
      break;
    case "Wellington Region":
      color = "rgb(241, 70, 37)";
      break;
    default:
      color = "white";
      break;
  }

  return { color: color, weight: 10 };
};
