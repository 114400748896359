import { generateClient } from "aws-amplify/api";
import { getIwi, iwisByIwicollectiveID, listIwis } from "@src/graphql/queries";
import { Iwi } from "@src/API";

export const iwiApi = {
  listIwi: async () => {
    const client = generateClient();
    const result = await client.graphql({
      query: listIwis,
      authMode: 'userPool',
    });

    return result.data.listIwis.items;
  },
  getIwi: async (id: string): Promise<Iwi> => {
    const client = generateClient();
    const result = await client.graphql({
      query: getIwi,
      variables: { id },
      authMode: 'userPool',
    });

    return result.data.getIwi as Iwi;
  },
  getIwisByIwicollectiveID: async (iwicollectiveID: string): Promise<Iwi[]> => {
    const client = generateClient();
    const result = await client.graphql({
      query: iwisByIwicollectiveID,
      variables: { iwicollectiveID },
      authMode: 'userPool',
    });
    return result.data?.iwisByIwicollectiveID?.items ?? [];
  },
};
