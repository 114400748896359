import { generateClient } from "@aws-amplify/api";
import {
  CreateAttachmentInput,
  CreateBulletinInput,
  CreateIwiShareInput,
  DeleteIwiShareInput,
  ModelSortDirection,
  UpdateBulletinInput,
} from "@src/API";
import {
  createAttachment,
  createBulletin,
  createIwiShare,
  deleteAttachment,
  deleteBulletin,
  deleteIwiShare,
  updateBulletin,
} from "@src/graphql/mutations";
import { getBulletin } from "@src/graphql/queries";
import {
  ListIwiSharesByIwiID,
  bulletinsByTargetIwiCollective,
  listBulletins,
  returnAttachmentsByBulletinID,
  returnListSharedBulletinsByIdQuery,
} from "./bulletin-queries";
import {
  IBulletin,
  IBulletinDetails,
  listAttachmentsByBulletinID,
  listIwiSharesByBulletinID,
} from "@src/types/model/IBulletin";
import useAuthStore from "@src/stores/auth-store";

type PagedBulletinsResult = {
  data: any;
  nextToken: string | null | undefined;
};
export const bulletinApi = {
  createBulletin: async (payload: CreateBulletinInput) => {
    const client = generateClient();
    const result = await client.graphql({
      query: createBulletin,
      variables: {
        input: { ...payload },
      },
      authMode: "userPool",
    });
    const bulletin = result.data.createBulletin;
    return bulletin;
  },

  addAttachmentToBulletin: async (payload: CreateAttachmentInput) => {
    const client = generateClient();
    const result = await client.graphql({
      query: createAttachment,
      variables: {
        input: payload,
      },
      authMode: "userPool",
    });

    return result.data.createAttachment;
  },

  listAllBulletinsByRole: async (
    role: string,
    limit?: number,
    nextToken?: string
  ): Promise<PagedBulletinsResult> => {
    const { userProfile } = useAuthStore.getState();

    const client = generateClient();
    switch (role) {
      case "Writer":
      case "Router":
        // get all bulletins from bulletin table, filter iwi by iwi collective
        const routerQuery = await client.graphql({
          query: bulletinsByTargetIwiCollective,
          variables: {
            limit,
            nextToken,
            targetIwiCollectiveID: userProfile?.IwiCollective?.id!,
            sortDirection: ModelSortDirection.DESC,
          },
          authMode: "userPool",
        });

        // convert to IBulletins
        const bulletins =
          routerQuery.data.bulletinsByTargetIwiCollective.items.map(parseBulletinToDetails);
        return {
          data: bulletins,
          nextToken: routerQuery.data.bulletinsByTargetIwiCollective.nextToken,
        };
      case "Reader":
        // get all bulletins from SharedIwi table, filter by Iwi ID
        const iwiSharedQuery = await client.graphql({
          query: ListIwiSharesByIwiID,
          variables: {
            limit,
            nextToken,
            iwiID: userProfile?.iwiID!,
            sortDirection: ModelSortDirection.DESC,
          },
          authMode: "userPool",
        });

        console.log("Reader get all: ", iwiSharedQuery.data);

        return {
          data: iwiSharedQuery.data.iwiSharesByIwiID.items,
          nextToken: iwiSharedQuery.data.iwiSharesByIwiID.nextToken,
        };
      default:
        const allBulletinsQuery = await client.graphql({
          query: listBulletins,
          variables: {
            limit,
            nextToken,
          },
          authMode: "userPool",
        });

        const allBulletins = allBulletinsQuery.data.listBulletins.items.map((i) =>
          parseBulletinToDetails(i)
        );
        return { data: allBulletins, nextToken: allBulletinsQuery?.data?.listBulletins?.nextToken };
    }
  },
  shareBulletinWithIwi: async (input: CreateIwiShareInput) => {
    const client = generateClient();
    const result = await client.graphql({
      query: createIwiShare,
      variables: {
        input: input,
      },
      authMode: "userPool",
    });

    return result.data.createIwiShare;
  },

  unShareBulletinWithIwi: async (bulletinId: string, iwiId: string) => {
    const client = generateClient();

    // get the share id
    const shareRecord = await bulletinApi.getBulletinById(bulletinId);

    // input params
    const input: DeleteIwiShareInput = {
      id: shareRecord.id,
    };

    const result = await client.graphql({
      query: deleteIwiShare,
      variables: {
        input: input,
      },
      authMode: "userPool",
    });
  },

  // NOTE: using new models
  getBulletinById: async (id: string): Promise<IBulletinDetails> => {
    const client = generateClient();

    try {
      const bulletinsQuery = await client.graphql({
        query: getBulletin,
        variables: { id },
        authMode: "userPool",
      });

      const iwiSharedQuery = (await client.graphql({
        query: returnListSharedBulletinsByIdQuery(id),
        variables: {
          bulletinID: id,
        },
        authMode: "userPool",
      })) as GraphQLResult<listIwiSharesByBulletinID>;

      const attachmentsQuery = (await client.graphql({
        query: returnAttachmentsByBulletinID,
        variables: {
          bulletinID: id,
        },
        authMode: "userPool",
      })) as GraphQLResult<listAttachmentsByBulletinID>;

      if (
        !bulletinsQuery.data.getBulletin &&
        iwiSharedQuery.data.iwiSharesByBulletinID.items.length < 1
      ) {
        return {} as IBulletinDetails;
      }

      if (iwiSharedQuery.data.iwiSharesByBulletinID.items?.length > 0) {
        const payload: IBulletinDetails = {
          id: iwiSharedQuery.data.iwiSharesByBulletinID.items[0].id,
          userID: iwiSharedQuery.data.iwiSharesByBulletinID.items[0].userID,
          bulletin: {
            ...bulletinsQuery.data.getBulletin,
            attachments: attachmentsQuery.data.attachmentsByBulletinID.items ?? [],
          } as IBulletin,
          bulletinID: bulletinsQuery.data.getBulletin?.id!,
          sharedWithRole: iwiSharedQuery.data.iwiSharesByBulletinID.items[0].sharedWithRole,
          iwi: iwiSharedQuery.data.iwiSharesByBulletinID.items[0].iwi,
        };
        return payload;
      }

      const payload: IBulletinDetails = {
        id: "",
        bulletin: {
          ...bulletinsQuery.data.getBulletin,
          attachments: attachmentsQuery.data.attachmentsByBulletinID.items ?? [],
        } as IBulletin,
        bulletinID: bulletinsQuery.data.getBulletin?.id!,
        sharedWithRole: "",
        userID: bulletinsQuery.data.getBulletin?.User?.id!,
      };

      return payload;
    } catch (e) {
      console.log(e);
      return {} as IBulletinDetails;
    }
  },
  updateBulletin: async (payload: UpdateBulletinInput) => {
    const client = generateClient();
    const result = await client.graphql({
      query: updateBulletin,
      variables: {
        input: payload,
      },
      authMode: "userPool",
    });
    const bulletin = result.data.updateBulletin;
    return bulletin;
  },

  deleteButtetin: async (id: string) => {
    const client = generateClient();
    const result = await client.graphql({
      query: deleteBulletin,
      variables: {
        input: {
          id,
        },
      },
      authMode: "userPool",
    });
    const bulletin = result.data.deleteBulletin;
    console.log(bulletin);
    return bulletin;
  },
  deleteIwiShareBulletin: async (id: string) => {
    const client = generateClient();
    const result = await client.graphql({
      query: deleteIwiShare,
      variables: {
        input: {
          id,
        },
      },
      authMode: "userPool",
    });
    const bulletin = result.data.deleteIwiShare;
    return bulletin;
  },
  deleteBulletinAttachment: async (id: string) => {
    const client = generateClient();
    const result = await client.graphql({
      query: deleteAttachment,
      variables: {
        input: {
          id,
        },
      },
      authMode: "userPool",
    });
    const bulletin = result.data.deleteAttachment;
    return bulletin;
  },
};

// util functions
const parseBulletinToDetails = (bulletin: IBulletin): IBulletinDetails => {
  const item: IBulletinDetails = {
    id: "",
    bulletin: bulletin,
    bulletinID: bulletin.id,
    sharedWithRole: "",
    userID: bulletin.User?.id!,
  };

  return item;
};

export interface GraphQLResult<T> {
  data: T;
}
