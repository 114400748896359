import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const UserFragment = `
id
email
first_name
last_name
avatar_url
phone_number
user_type
title
__typename
`;

const BulletinFragment = `
id
title
description
image_url
state
createdAt
updatedAt
bulletinUserId
targetIwiCollectiveID
User {
      ${UserFragment}
}
targetIwiCollective { 
  id
  name
}
__typename
`;

export const listBulletins = /* GraphQL */ `query ListBulletins(
  $filter: ModelBulletinFilterInput
  $limit: Int
  $nextToken: String
) {
  listBulletins(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      ${BulletinFragment}
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListBulletinsQueryVariables, APITypes.ListBulletinsQuery>;

export const bulletinsByTargetIwiCollective = /* GraphQL */ `query BulletinsByTargetIwiCollective(
  $targetIwiCollectiveID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBulletinFilterInput
  $limit: Int
  $nextToken: String
) {
  bulletinsByTargetIwiCollective(
    targetIwiCollectiveID: $targetIwiCollectiveID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      ${BulletinFragment}
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BulletinsByTargetIwiCollectiveQueryVariables,
  APITypes.BulletinsByTargetIwiCollectiveQuery
>;

// Its a replacement of listIwiSharesByIwiID
export const ListIwiSharesByIwiID =
  /* GraphQL */ `query ListiwiSharesByIwiID($iwiID: ID = "", $sortDirection: ModelSortDirection = DESC) {
  iwiSharesByIwiID(iwiID: $iwiID, sortDirection: $sortDirection) {
    items {
      id
      bulletinID
      userID,
      sharedWithRole
      iwi {
        name
        id
        iwicollectiveID
      }
      bulletin {
        id,
        title,
        description,
        image_url,
        User {
          ${UserFragment}
        }
        state,
        targetIwiCollective {
          id,
          name
        }
        attachments {
        items {
          id
          fileUrl
          fileName
          fileType
          size
        } 
        }
      }
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.IwiSharesByIwiIDQueryVariables, APITypes.IwiSharesByIwiIDQuery>;

export const returnListSharedBulletinsByIdQuery = (bulletinId: string) => {
  return `
  query listIwiSharesByBulletinID {
    iwiSharesByBulletinID (bulletinID: "${bulletinId}") {
    items {
      id
    bulletinID
    userID,
    sharedWithRole,
    iwi {
      id
      name
    }
    bulletin {
      id,
      title,
      description,
      image_url,
      User {
        ${UserFragment}
      }
      state,
      targetIwiCollective {
        id,
        name
      }
      attachments {
       items {
        id
        fileUrl
        fileName
        fileType
        size
       }
      }
    }
    }
  }
}
  `;
};

export const returnAttachmentsByBulletinID = /* GraphQL */ `
  query listAttachmentsByBulletinID($bulletinID: ID!) {
    attachmentsByBulletinID(bulletinID: $bulletinID) {
      items {
        id
        bulletinID
        fileUrl
        fileName
        fileType
        size
      }
    }
  }
`;
