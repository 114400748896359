import React from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { customValidationSchema } from "@src/components/forms/utils/form-util";
import TextInputCombo from "@src/components/forms/text-input-combo";
import Button from "@src/components/ui/button";

const INITIAL_VALUES = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  message: "",
};

const VALIDATION_SCHEMA = yup.object().shape({
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  email: yup.string().email("Valid email required").required("Email is required"),
  phone: customValidationSchema.phoneSchema,
  message: yup.string().required("Message is required"),
});

export const ContactUsForm = () => {
  return (
    <div>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={async (_data, { setSubmitting }) => {
          console.log(_data);
        }}>
        {({ isSubmitting }) => (
          <Form className={"flex flex-col gap-2"}>
            {/* side by side on large screens */}
            <div className={"flex flex-col lg:flex-row lg:gap-2"}>
              <TextInputCombo name={"first_name"} type={"text"} label={"First name"} />
              <TextInputCombo name={"last_name"} type={"text"} label={"Last name"} />
            </div>

            <TextInputCombo name={"email"} type={"email"} label={"Email"} />
            <TextInputCombo name={"phone"} type={"text"} label={"Mobile"} />

            <div className={"mt-4"}>
              <Button label={"Sign In"} type={"submit"} disabled={isSubmitting}></Button>
            </div>
            {/*<FormDebug />*/}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactUsForm;
