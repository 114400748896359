// authStore.ts
import { create } from "zustand";
import { getCurrentUser, fetchAuthSession } from "aws-amplify/auth";
import { devtools, persist } from "zustand/middleware";
import { userApi } from "@src/api/user-api";
import { User } from "@src/API";
import { IAppUserDetails } from "@src/types/model/user";
// import { userApi } from "@src/api/user-api";

/*
  // NOTE: Login / logout is tied to the authStore. So the api calls are handled via api-call hook,
  // NOTE: and the result is stored in the authStore.
  // check the login screen for usage pattern
*/
export interface AuthState {
  userProfile: IAppUserDetails | null;
  userGroups: string[];
  loginSuccess: () => Promise<void>;
  logoutSuccess: () => void;
  isAdmin: () => Promise<boolean>;
}

const useAuthStore = create<AuthState>()(
  devtools(
    persist(
      (set) => ({
        userProfile: null,
        userGroups: [],

        loginSuccess: async () => {
          console.log("login success");
          // get logged in user from cognito
          const cognitoUserObject = await getCurrentUser();

          // get the cognito group memberships
          const session = await fetchAuthSession();
          const groups =
            (session.tokens?.idToken?.payload["cognito:groups"] as Array<string>) || [];

          // get the IAppUser record from the application database
          const userRecord = await userApi.getUser(cognitoUserObject.userId);

          const userDetails = await userApi.getUserDetails(cognitoUserObject.userId);

          set((state) => ({
            ...state,
            userProfile: userDetails,
            userGroups: groups,
          }));
        },

        logoutSuccess: () => {
          set(() => ({
            userProfile: null,
            userGroups: [],
          }));
        },

        isAdmin: async () => {
          // get the cognito group memberships
          const session = await fetchAuthSession();
          const groups =
            (session.tokens?.idToken?.payload["cognito:groups"] as Array<string>) || [];

          return groups.includes("Admins");
        },
      }),
      {
        name: "authStore", // unique name for the persisted state
        getStorage: () => sessionStorage, // function that returns the storage (sessionStorage in this case)
      }
    )
  )
);

export default useAuthStore;
