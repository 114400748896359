import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import FileUploadDropZone, { IReadyFiles } from "@src/components/file-upload-drop-zone";
import { useFileStorage } from "@src/hooks/storage-hooks";
import { bulletinApi } from "@src/api/bulletin-api";
import TextInputCombo from "@src/components/forms/text-input-combo";
import LoadingSpinner from "@src/components/overlays/LoadingSpinner";
import BulletinFilesList from "./bulletin-file-list";
import { iwiCollectiveApi } from "@src/api/iwi-collective-api";
import { IwiCollective } from "@src/API";
import { BulletinState } from "@src/constant/user";
import { useApiCall } from "@src/hooks/api-call";
import { attachmentApi } from "@src/api/attachment-api";
import { IAttachment } from "@src/types/model/IBulletin";
import AlertModal, { AlertType } from "@src/components/overlays/alert-modal";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
});

type FormType = {
  title: string;
  description: string;
  image_url: string;
  state: BulletinState;
  targetIwiCollectiveID: string;
  bulletinUserId: string;
};

const BulletinForm: React.FC = () => {
  const [selectedFiles, setSelectedFiles] = useState<IReadyFiles[]>([]);
  const [iwiCollectives, setIwiCollectives] = useState<IwiCollective[]>([]);
  const [isBulletinLoading, setIsBulletinLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [initialFormValues, setInitialFormValues] = useState<FormType>();
  const [deletedAttachments, setDeletedAttachments] = useState<IAttachment[]>([]);
  const [bulletin, setBulletin] = useState<any>();
  const [message, setMessage] = useState<{ type: AlertType; message: string }>();
  const navigate = useNavigate();

  const { uploadFile } = useFileStorage();
  const { id = "" } = useParams();

  useEffect(() => {
    async function fetchIwiCollectives() {
      try {
        const collectives = await iwiCollectiveApi.listIwiCollectives();
        setIwiCollectives(collectives);
      } catch (error) {
        console.error("Error fetching Iwi Collectives:", error);
      }
    }

    fetchIwiCollectives();
  }, []);

  useEffect(() => {
    const getBulletinById = async () => {
      try {
        setIsBulletinLoading(true);
        if (id) {
          const response = await bulletinApi.getBulletinById(id);
          setBulletin(response?.bulletin);
          setIsBulletinLoading(false);
        }
      } catch (error) {
        console.log("Error getting bulletin by ID:", error);
      } finally {
        setIsBulletinLoading(false);
      }
    };
    getBulletinById();
  }, [id]);

  useEffect(() => {
    if (bulletin) {
      const initialFormValues = {
        title: bulletin.title || "",
        description: bulletin.description || "",
        image_url: bulletin.image_url || "",
        state: BulletinState.Live,
        targetIwiCollectiveID: bulletin.targetIwiCollective?.id || "",
        bulletinUserId: bulletin.User?.id || "",
      };
      setInitialFormValues(initialFormValues);
    }
  }, [bulletin]);

  const { execute: executeUpdateBulletin, isLoading } = useApiCall(bulletinApi.updateBulletin, {
    onSuccess: async (data) => {
      console.log({ data });
    },
    onError: (error) => {
      console.log(JSON.stringify(error));
      setMessage({ type: AlertType.error, message: "Error updating bulletin!" });
    },
  });

  const handleSubmit = async (values: FormType) => {
    // update bulletin
    await executeUpdateBulletin({
      ...values,
      id,
    });

    // upload new attachments
    await attachmentApi.uploadBulletinAttachments(selectedFiles, id, uploadFile);

    // delete attachments that have been removed
    if (deletedAttachments?.length) {
      await Promise.allSettled(deletedAttachments?.map(attachmentApi.deleteBulletinAttachments));
    }
    setMessage({ type: AlertType.success, message: "Bulletin updated successfully!" });
  };

  const handleFileDelete = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleDeleteUploadedFile = (index: number) => {
    const attachment = bulletin.attachments[index];
    setDeletedAttachments((prev) => [...prev, attachment]);
  };

  return (
    <div>
      {(isBulletinLoading || isLoading) && <LoadingSpinner />}
      {initialFormValues ? (
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form>
              {message && (
                <AlertModal
                  message={message.message}
                  title={message.type === AlertType.error ? "Error" : "Success"}
                  onClose={() => {
                    setMessage(undefined);
                    if (message.type === AlertType.success) {
                      setTimeout(() => {
                        navigate(`/user`);
                      }, 200);
                    }
                  }}
                  alertType={message.type}
                />
              )}
              <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-12">
                  <h2 className="text-base font-semibold leading-7 text-gray-900 mt-10">
                    Bulletin
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600">Update bulletin</p>
                  <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-6 max-w-xl">
                      <TextInputCombo label="Title" name="title" type="text" />
                    </div>

                    <div className="sm:col-span-6">
                      <label
                        htmlFor="targetIwiCollectiveID"
                        className="block text-base font-medium leading-6 text-gray-900">
                        Iwi Collective
                      </label>
                      <div className="mt-2">
                        <select
                          id="targetIwiCollectiveID"
                          value={bulletin.targetIwiCollective?.id ?? selectedValue}
                          disabled
                          onChange={(e) => setSelectedValue(e.target.value)}
                          name="targetIwiCollectiveID"
                          className="pl-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                          <option value="0">Select an option</option>
                          {iwiCollectives.map((collective) => (
                            <option key={collective.id} value={collective.id}>
                              {collective.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="sm:col-span-6 max-w-5xl">
                      <TextInputCombo label="Description" name="description" type="text" />
                    </div>
                    <div className="sm:col-span-6 max-w-5xl">
                      <FileUploadDropZone
                        label={"Upload New Attachments"}
                        files={selectedFiles}
                        setFiles={setSelectedFiles}
                        onFileDelete={() =>
                          setSelectedFiles(selectedFiles.filter((file) => file.uuid !== file.uuid))
                        }
                        isMultiple={true}
                      />
                    </div>

                    <article className="sm:col-span-6 max-w-5xl">
                      <section className="max-h-[400px] overflow-y-auto">
                        <label htmlFor="Files" className="block text-sm font-medium text-gray-700">
                          Existing Attachments
                        </label>
                        <div>
                          {/* Render uploaded files */}
                          {bulletin.attachments ? (
                            <BulletinFilesList
                              attachments={bulletin.attachments.filter(
                                (attachment: IAttachment) =>
                                  !deletedAttachments.includes(attachment)
                              )}
                              onDelete={handleDeleteUploadedFile}
                            />
                          ) : null}
                          {/* Render local file */}
                          <BulletinFilesList
                            attachments={selectedFiles}
                            onDelete={handleFileDelete}
                          />
                        </div>
                      </section>
                    </article>
                  </div>
                </div>
                <footer className="max-w-5xl">
                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                      Save
                    </button>
                  </div>
                </footer>
              </div>
            </Form>
          )}
        </Formik>
      ) : null}
    </div>
  );
};

export default BulletinForm;
