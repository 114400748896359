import { create } from "zustand";

export interface AdminUiState {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  selectedSideNavMenuItem: string;
  setSelectedSideNavMenuItem: (menuItem: string) => void;
}

const useAdminUiStore = create<AdminUiState>((set) => ({
  isSidebarOpen: false,
  toggleSidebar: () =>
    set((state) => ({
      isSidebarOpen: !state.isSidebarOpen,
    })),
  selectedSideNavMenuItem: "home",
  setSelectedSideNavMenuItem: (menuItem: string) =>
    set(() => ({
      selectedSideNavMenuItem: menuItem,
    })),
}));

export default useAdminUiStore;
