import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import FileUploadDropZone, { IReadyFiles } from "@src/components/file-upload-drop-zone";
import { useFileStorage } from "@src/hooks/storage-hooks";
import { bulletinApi } from "@src/api/bulletin-api";
import { useApiCall } from "@src/hooks/api-call";
import TextInputCombo from "@src/components/forms/text-input-combo";
import LoadingSpinner from "@src/components/overlays/LoadingSpinner";
import BulletinFilesList from "./bulletin-file-list";
import useAuthStore from "@src/stores/auth-store";
import { iwiCollectiveApi } from "@src/api/iwi-collective-api";
import { IwiCollective } from "@src/API";
import { BulletinState } from "@src/constant/user";
import { attachmentApi } from "@src/api/attachment-api";

const initialValues = {
  title: "",
  description: "",
  image_url: "",
  state: BulletinState.Live,
  targetIwiCollectiveID: "",
  bulletinUserId: "",
};

type FormType = typeof initialValues;

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  // image_url: Yup.string().required('Image is required'),
});

const BulletinForm: React.FC = () => {
  const [primaryImageFiles, setPrimaryImageFiles] = useState<IReadyFiles[]>([]);
  const [iwiCollectives, setIwiCollectives] = useState<IwiCollective[]>([]);
  const [selectedValue, setSelectedValue] = useState("");
  const { uploadFile } = useFileStorage();
  const { userProfile } = useAuthStore();
  const navigate = useNavigate();

  const handleFileDelete = (index: number) => {
    const updatedFiles = [...primaryImageFiles];
    updatedFiles.splice(index, 1);
    setPrimaryImageFiles(updatedFiles);
  };

  const { execute: executeCreateBulletin, isLoading } = useApiCall(bulletinApi.createBulletin, {
    onSuccess: async (data) => {
      console.log({ data });
    },
    onError: (error) => {
      console.log(JSON.stringify(error));
    },
  });

  useEffect(() => {
    async function fetchIwiCollectives() {
      try {
        const collectives = await iwiCollectiveApi.listIwiCollectives();
        setIwiCollectives(collectives);
      } catch (error) {
        console.error("Error fetching Iwi Collectives:", error);
      }
    }

    fetchIwiCollectives();
  }, []);

  const handleSubmit = async (values: FormType) => {
    try {
      values.targetIwiCollectiveID = selectedValue;
      values.bulletinUserId = userProfile?.id!;
      const createBulletinResult = await executeCreateBulletin(values);
      await attachmentApi.uploadBulletinAttachments(
        primaryImageFiles,
        createBulletinResult.data?.id!,
        uploadFile
      );
      navigate("/user");
    } catch (error) {
      console.error("Error creating bulletin:", error);
    }
  };

  useEffect(() => {
    if (userProfile?.IwiCollective?.id) {
      setSelectedValue(userProfile?.IwiCollective?.id);
    }
  }, [userProfile?.IwiCollective?.id]);

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <div className="space-y-12">
              <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900 mt-10">Bulletin</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">Create new bulletin</p>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-6 max-w-xl">
                    <TextInputCombo label="Title" name="title" type="text" />
                  </div>

                  <div className="sm:col-span-6">
                    <label
                      htmlFor="targetIwiCollectiveID"
                      className="block text-base font-medium leading-6 text-gray-900">
                      Iwi Collective
                    </label>
                    <div className="mt-2">
                      <select
                        disabled={userProfile?.IwiCollective?.id ? true : false}
                        id="targetIwiCollectiveID"
                        value={selectedValue}
                        onChange={(e) => setSelectedValue(e.target.value)}
                        name="targetIwiCollectiveID"
                        className="pl-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                        <option value="0">Select an option</option>
                        {iwiCollectives.map((collective) => (
                          <option value={collective.id}>{collective.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="sm:col-span-6 max-w-5xl">
                    <TextInputCombo label="Description" name="description" type="text" />
                  </div>

                  <article className="sm:col-span-6 max-w-5xl">
                    <FileUploadDropZone
                      label={"Attachments"}
                      files={primaryImageFiles}
                      setFiles={setPrimaryImageFiles}
                      onFileDelete={() =>
                        setPrimaryImageFiles(
                          primaryImageFiles.filter((file) => file.uuid !== file.uuid)
                        )
                      }
                      isMultiple={true}
                    />
                  </article>

                  <div className="sm:col-span-5 mt-4">
                    <section className="max-h-[400px] overflow-y-auto">
                      <label htmlFor="Files" className="block text-sm font-medium text-gray-700">
                        Files
                      </label>
                      <div>
                        <BulletinFilesList
                          attachments={primaryImageFiles}
                          onDelete={handleFileDelete}
                        />
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <footer className="max-w-5xl">
                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Save
                  </button>
                </div>
              </footer>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BulletinForm;
