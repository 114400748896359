import { CreateAttachmentInput } from "@src/API";
import { IReadyFiles } from "@src/components/file-upload-drop-zone";
import { ETargetFileType } from "@src/types/storage";
import { bulletinApi } from "./bulletin-api";
import { storageUtils } from "@src/util/storage-utils";
import { IAttachment } from "@src/types/model/IBulletin";

export const attachmentApi = {
  uploadBulletinAttachments: async (
    files: IReadyFiles[],
    bulletinId: string,
    uploadFile: (file: File, fileUrl: string) => Promise<void>
  ) => {
    const ready: { fileUrl: string; file: IReadyFiles }[] = [];
    for (const file of files) {
      const fileName = `${ETargetFileType.BULLETIN_IMAGE}/${bulletinId}/${file.file.name}`;
      // upload the files
      await uploadFile(file.data, fileName);
      ready.push({ fileUrl: fileName, file: file });
    }
    // add each file to attachment table
    for (const item of ready) {
      const input: CreateAttachmentInput = {
        fileUrl: item.fileUrl,
        bulletinID: bulletinId!,
        fileName: item.file.file.name,
        fileType: item.file.file.type,
        size: item.file.file.size.toString(),
      };
      await bulletinApi.addAttachmentToBulletin(input);
    }
  },
  deleteBulletinAttachments: async (attachment: IAttachment) => {
    try {
      // Remove the file from storage
      await storageUtils.deleteFile(attachment.fileUrl);

      // delete attachment records from attachment table if present
      await bulletinApi.deleteBulletinAttachment(attachment.id);
    } catch (error) {
      console.error("Error deleting attachment:", error);
    }
  },
};
