import HeroBg from "@src/assets/DarkBlueBackgroundForHero.svg";
import WaterVector from "@src/assets/FooterWaterVector2.svg";
import React from "react";
import WorkStreamsStack from "./modules/work-streams-stack";
import DualTitle from "../who-we-are/modules/dual-title";
import BodyContentContainer from "@src/components/containers/body-content-container";

const workStreamInfo = [
  {
    serviceName: "He Manawa Atua",
    serviceNameAttribues: ["Te Mana o te Wai", "Relationships & Engagement"],
    serviceOutcomes:
      "Iwi are supported to share information across the Te Manawa o Ngā Wai network around transformational work that embodies Te Mana o te Wai, and provides a step-change to risk catchments, wetlands, restoration, water storage, and allocation through infrastructure.",
    serviceDeliverables: [
      "Te Mana o te Wai Statements developed by each Hapū",
      "Iwi and Hapu lead Freshwater Symposiums",
      "Waka-a-rohe led co-ordinator roles established",
    ],
  },
  {
    serviceName: "He Manawa Whenua",
    serviceNameAttribues: ["Settlement Protection", "Privatisation"],
    serviceOutcomes:
      "Iwi are well positioned to adapt to the changing environment and respond swiftly and effectively regarding their rights, interests and responsibilities.",
    serviceDeliverables: [
      "Development Bespoke Constitution Provisions for iwi and hapū of Te Manawa o Ngā Wai.",
      "Established bottom-lines for each waka-a-rohe",
    ],
  },
  {
    serviceName: "He Manawa Tangata",
    serviceNameAttribues: ["Governance", "Strategy", "Operations", "Transition"],
    serviceOutcomes:
      "Iwi are actively seeking recognition and participation across all levels of the WSE including governance, key policy development around water quality and allocation including Three Water Services, Water Discharge Management and Infrastructure.",
    serviceDeliverables: [
      "Waka based model established",
      "Iwi represented in WSE governance and hapū regional advisory panels",
      "Iwi Transitional Teams established",
      "Iwi co-design of water services plans established",
    ],
  },
];

const WhatWeDoPage: React.FC = () => {
  return (
    <div className="bg-gray-50 mb-12">
      <section className={"md:px-10 md:py-8 mb-12"}>
        <div
          className={
            "font-sans pt-8 pb-1 px-4 bg-gradient-to-r from-white via-white to-[#03b2d927] md:bg-none md:text-right md:flex md:flex-row-reverse md:justify-around"
          }>
          <div
            className={
              "hidden p-4 font-light tracking-widest text-[rgba(61,_61,_61,_0.87)] max-w-3xl leading-6 text-justify md:block md:max-w-[50vw] lg:min-w-[50vw]"
            }>
            <p className={""}>
              Formed in 2021 following a tono from Taa Toby Curtis and Taa Tumu Te Heuheu, Te Manawa
              o Ngā Wai is responsible for ensuring the iwi, hapū and marae it is responsible to,
              are engaged in the
              <a
                className="text-brand-primary font-bold visited:font-extrabold visited:text-brand-secondary px-1"
                href="https://www.waterservicesreform.govt.nz/"
                target="_blank"
                rel="noopener noreferrer">
                Affordable Water Reforms
              </a>
              (previously Three Waters).
            </p>
            <p className="mt-4">
              We’re responsible for ensuring the iwi within Te Manawa o Ngā Wai are kept up to date
              with the Affordable Water reforms and how it impacts them.
            </p>
          </div>
          <div className={"mt-4"}>
            <DualTitle title1={"Tā tātou mahi"} title2={"What we do"} />
          </div>
        </div>
        <div
          className={
            "p-4 text-sm tracking-widest text-[rgba(61,_61,_61,_0.87)] max-w-3xl leading-6 text-justify md:hidden"
          }>
          <p className={""}>
            Formed in 2021 following a tono from Taa Toby Curtis and Taa Tumu Te Heuheu, Te Manawa o
            Ngā Wai is responsible for ensuring the iwi, hapū and marae it is responsible to, are
            engaged in the
            <a
              className="text-brand-primary font-bold visited:font-extrabold visited:text-brand-secondary px-1"
              href="https://www.waterservicesreform.govt.nz/"
              target="_blank"
              rel="noopener noreferrer">
              Affordable Water Reforms
            </a>
            (previously Three Waters).
          </p>
        </div>
      </section>

      <article
        style={{ backgroundImage: `url(${HeroBg})` }}
        className="text-lightPrimary text-center w-full bg-no-repeat bg-cover py-40 relative">
        <div className="flex flex-col  mx-auto pb-10 px-3 sm:px-8 lg:px-12">
          <p
            className="
          text-xl
          sm:text-2xl
          md:text-4xl
          lg:text-5xl
          2xl:text-6xl
          tracking-wide
          "
            style={{ lineHeight: "1.4" }}>
            Our mahi centres around the Te Manawa o Ngā Wai Vision Statement.
          </p>
          {/*QUOTE SIGN*/}
          <div
            className="
          text-2xl
          sm:text-4xl
          my-1
          sm:mt-[1rem]
          text-lightPrimary
          self-center
          w-[35%]
          font-cantora">
            “
          </div>
          <blockquote
            className="
          mb-6
          text-lightSecondary
          text-md
          tracking-widest
          sm:text-lg
          md:text-xl
          lg:text-2xl
          ">
            <p>
              He manawa Atua, he manawa whenua, he manawa tangata, ka pupuu ake mo ake tonu atu.
            </p>
          </blockquote>
        </div>
        <div
          className="absolute bottom-0 w-full bg-no-repeat bg-cover h-[100px] sm:h-[200px]"
          style={{ backgroundImage: `url(${WaterVector})` }}>
          {/* Empty div for the background image */}
        </div>
      </article>
      {workStreamInfo.map((workStream, index) => (
        <div
          className={`bg-no-repeat bg-cover bg-opacity-10 ${
            index % 2 ? "bg-gradient-to-r from-white via-white to-[#03b2d927]" : ""
          }`}>
          <BodyContentContainer>
            <div key={index}>
              <WorkStreamsStack
                serviceName={workStream.serviceName}
                serviceNameAttribues={workStream.serviceNameAttribues}
                serviceOutcomes={workStream.serviceOutcomes}
                serviceDeliverables={workStream.serviceDeliverables}
              />
            </div>
          </BodyContentContainer>
        </div>
      ))}
    </div>
  );
};

export default WhatWeDoPage;
