export const stringUtil = {
  shortenName(firstName: string, lastName: string): string {
    let shortenedFirstName = firstName;
    let shortenedLastName = lastName;

    if (firstName.length >= 12) {
      shortenedFirstName = firstName[0].toUpperCase();
    }

    if (lastName.length >= 12) {
      shortenedLastName = lastName[0].toUpperCase();
    }

    return `${shortenedFirstName} ${shortenedLastName}`;
  },
  truncate: (str: string, maxLength: number): string => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  },
  formatByes: (bytes: string, decimals = 2) => {
    const size = parseInt(bytes, 10);
    if (isNaN(size)) return "0 Bytes";

    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const index = Math.floor(Math.log(size) / Math.log(1024));
    return parseFloat((size / Math.pow(1024, index)).toFixed(decimals)) + " " + sizes[index];
  },
};
