import useAuthStore from "@src/stores/auth-store";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { IAppUserDetails } from "@src/types/model/user";
import ProfileAvatar from "@src/components/ui/ProfileAvatar";
import { storageUtils } from "@src/util/storage-utils";
import { userApi } from "@src/api/user-api";
import LoadingSpinner from "@src/components/overlays/LoadingSpinner";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { userUtil } from "@src/util/user-util";

interface UserTableRowProps {
  user: IAppUserDetails;
}

const UserTableRow: React.FC<UserTableRowProps> = (props: UserTableRowProps) => {
  const { userProfile, userGroups } = useAuthStore((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [avatarUrl, setAvatarUrl] = React.useState("");
  const isAdmin = userGroups.includes("Admins");
  const queryClient = useQueryClient();

  const svgBackgroundStyle: React.CSSProperties = {
    appearance: "none",
    backgroundSize: "25px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50%",
    backgroundImage:
      'url(\'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-12 h-12"%3E%3Cpath fillRule="evenodd" d="M4.5 12a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm6 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm6 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z" clipRule="evenodd" /%3E%3C/svg%3E\')',
  };

  const handleSelectChange = async (event: { target: { value: any } }) => {
    const selectedOption = event.target.value;
    switch (selectedOption) {
      case "edit":
        if (isAdmin) {
          navigate(`/admin/users/update?userId=${props.user.id}`);
        }
        break;
      case "delete":
        if (isAdmin) {
          try {
            setIsLoading(true);
            await userApi.deleteUser(props.user.id);
          } catch (e) {
            console.log(e);
          } finally {
            await queryClient.invalidateQueries({ queryKey: ["listUsers"] });
            setIsLoading(false);
          }
        }
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    const getImageFromKey = async () => {
      const imageFromKey = await storageUtils.getFileAccessUrl(props?.user?.avatar_url ?? "");
      setAvatarUrl(imageFromKey.url.href);
    };
    getImageFromKey();
  }, [userProfile]);

  return (
    <tr>
      {isLoading && <LoadingSpinner />}
      <td className="py-4 pl-4 text-sm leading-6 text-gray-800 text-center"></td>
      <td className="hidden sm:table-cell py-4 pl-4 pr-8 sm:pl-6 lg:pl-8 text-center">
        <div className="flex items-center gap-x-4">
          <div className="hidden sm:table-cell">
            <ProfileAvatar access_image_url={avatarUrl ?? ""} />
          </div>
        </div>
      </td>
      <td className="py-4 pl-0 pr-4 sm:table-cell sm:pr-8 text-center">
        <div className="min-w-0 flex-auto">
          <p className="text-sm font-semibold leading-6 text-gray-900 truncate">
            <a className="hover:underline">{`${props.user?.first_name} ${props.user?.last_name}`}</a>
          </p>
          <p className="mt-1 flex text-xs leading-5 text-gray-500 justify-center">
            <a href={"mailto:" + props.user.email} className="truncate hover:underline">
              {props.user.email}
            </a>
          </p>
        </div>
      </td>
      <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8 text-center">
        <div className="flex justify-center">
          <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-800 ring-1 ring-inset ring-gray-800/20">
            {/* TODO: check this */}
            {props.user.user_type
              ? userUtil.toDisplayUserType(props.user.user_type)
              : "NotSelected"}
          </span>
        </div>
      </td>
      <td className="hidden sm:table-cell py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20 text-center">
        <div className="flex items-center justify-center gap-x-2 sm:justify-center">
          <div className="text-gray-800">
            <span
              className="inline-flex items-center justify-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-green-100 text-green-800 ring-green-500/10 w"
              style={{ minWidth: "80px" }}>
              Active
            </span>
          </div>
        </div>
      </td>
      <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-800 lg:table-cell lg:pr-20 text-center">
        {props.user.IwiCollective?.name && props.user.IwiCollective.name.length > 0
          ? props.user.IwiCollective.name
          : "N/A"}
      </td>
      <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-800 lg:table-cell lg:pr-20 text-center">
        {props.user.iwi?.name && props.user.iwi.name.length > 0 ? props.user.iwi?.name : "N/A"}
      </td>
      <td className="py-4 pl-0 pr-4 text-sm leading-6 text-gray-800 sm:table-cell sm:pr-6 lg:pr-8 text-center">
        <div className="flex items-center">
          <select
            style={svgBackgroundStyle}
            className="inline-flex items-center w-24 rounded-md outline-none text-center text-sm font-light text-gray-800"
            onChange={handleSelectChange}
            value="">
            <option value=""></option>
            <option value="edit">Edit</option>
            <option value="delete">Delete</option>
            {/*<option value="disable">Disable</option>*/}
          </select>
        </div>
      </td>
    </tr>
  );
};

export default UserTableRow;
