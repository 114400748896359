import React from "react";
import HeroBg from "@src/assets/images/home/HeroWaterVectorWithMountain.png";

export const HeroComponent = () => {
  return (
    <div>
      <img
        src={HeroBg}
        alt="Te Manawa o nga Wai Hero Image"
        className="absolute inset-0 w-[100vw] h-full sm:h-full object-cover"
      />
      <div
        className="
      relative
      flex
      flex-col
      gap-1
      pb-10
      pt-5
      sm:py-5
      md:py-8
      lg:py-24
      xl:py-20
      2xl:py-20
      px-3
      sm:px-8
      lg:px-12
      lg:ml-20
      ">
        <div className="text-md sm:text-2xl md:text-4xl lg:text-5xl 2xl:text-6xl font-normal tracking-wide">
          <h1 className="text-lightPrimary font-semibold">Preservation and protection of wai</h1>
          <h1 className="ml-3 mt-1 text-lightSecondary">Hei oranga mo te iwi</h1>
        </div>
        <div className="text-[0.5rem] sm:text-sm md:text-lg font-bold flex flex-col ml-10">
          {/*QUOTE SIGN*/}
          <div className="text-lg sm:text-4xl mb-[-15px] mt-[-0.4rem] sm:mt-[1rem] font-cantora text-white self-center w-[35%]">
            “
          </div>
          <div className="text-lightPrimary">He manawa Atua, he manawa whenua,</div>
          <div className="text-lightSecondary ml-3 pb-5">ka pupū ake mō ake tonu atu.</div>
        </div>
      </div>
    </div>
  );
};

export default HeroComponent;
