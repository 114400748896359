export const userUtil = {
  // to displayUserType
  toDisplayUserType: (userType: string) => {
    switch (userType) {
      case "Writer": {
        return "TMoNW";
      }
      case "Router": {
        return "Iwi Admin";
      }
      case "Reader": {
        return "Iwi/Hapū Member";
      }
      default:
        return "User";
    }
  },
};
