import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { bulletinApi } from "@src/api/bulletin-api";
import LoadingSpinner from "@src/components/overlays/LoadingSpinner";
import Breadcrumbs from "@src/components/ui/breadcrumbs";
import { bulletinDetailsBreadcrumbItems } from "@src/constant";
import { storageUtils } from "@src/util/storage-utils";
import { stringUtil } from "@src/util/string-util";
import downloadFile from "@src/util/download-image-util";
import ShareBulletinModal from "./modules/share-bulletin-modal";
import useAuthStore from "@src/stores/auth-store";
import { UserTypes } from "@src/constant/user";
import AlertModal, { AlertType } from "@src/components/overlays/alert-modal";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import BulletinActions from "@src/app/user-pages/bulletin-details/modules/bulletin-actions";
import ProfileAvatar from "@src/components/ui/ProfileAvatar";
import { Download, File, Image } from "lucide-react";
import { useApiCall } from "@src/hooks/api-call";
import { IAttachment } from "@src/types/model/IBulletin";
import { attachmentApi } from "@src/api/attachment-api";

const BulletinDetails = () => {
  const userProfile = useAuthStore((state) => state.userProfile);
  const [isLoading, setIsLoading] = useState(false);
  const [isBulletinDeleteLoading, setIsBulletinDeleteLoading] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const allowedToShareRoles = ["GlobalAdmin", "Router"];
  const queryClient = useQueryClient();

  // bulletin details query
  const bulletinQuery = useQuery({
    queryKey: ["bulletin", id],
    queryFn: () => bulletinApi.getBulletinById(id!),
    enabled: !!id,
  });

  const { execute: executeDeleteIwiShare } = useApiCall(bulletinApi.deleteIwiShareBulletin, {
    onSuccess: async (data) => {
      console.log({ data });
    },
    onError: (error) => {
      console.log(JSON.stringify(error));
    },
  });

  const { execute: executeDeleteBulletinAttachment } = useApiCall(
    bulletinApi.deleteBulletinAttachment,
    {
      onSuccess: async (data) => {
        console.log({ data });
      },
      onError: (error) => {
        console.log(JSON.stringify(error));
      },
    }
  );
  const { execute: executeDeleteButtetin } = useApiCall(bulletinApi.deleteButtetin, {
    onSuccess: async (data) => {
      console.log({ data });
    },
    onError: (error) => {
      console.log(JSON.stringify(error));
    },
  });

  const createdAt = dayjs(bulletinQuery.data?.bulletin.createdAt).format("MMM DD, YYYY");
  const updatedAt = dayjs(bulletinQuery.data?.bulletin.updatedAt).format("MMM DD, YYYY - hh:mm");

  useEffect(() => {
    const getImageFromKey = async () => {
      const imageFromKey = await storageUtils.getFileAccessUrl(
        bulletinQuery?.data?.bulletin.User?.avatar_url ?? ""
      );
      setAvatarUrl(imageFromKey.url.href);
    };
    getImageFromKey();
  }, [userProfile]);

  const handleDownload = async (attachment: IAttachment) => {
    try {
      const imageUrl = await storageUtils.getFileAccessUrl(attachment.fileUrl);
      await downloadFile(imageUrl.url.href, attachment.fileName);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  const handleModal = () => {
    // check role - only routers should be able to share
    if (userProfile?.user_type !== UserTypes.Router) {
      setErrorMessage("To share bulletins you need to be a Router");
      return;
    }

    setIsOpen(!isOpen);
  };

  const handleUnShare = async () => {
    try {
      setIsLoading(true);
      await bulletinApi.unShareBulletinWithIwi(id!, userProfile?.iwiID!);
      await queryClient.invalidateQueries({
        queryKey: ["bulletin", id],
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsBulletinDeleteLoading(true);
      // delete records from iwiShare table if present
      if (bulletinQuery.data?.id) {
        await executeDeleteIwiShare(bulletinQuery.data?.id!);
      }

      // delete attachment images from storage
      if (bulletinQuery.data?.bulletin.attachments?.length) {
        await Promise.allSettled(
          bulletinQuery.data?.bulletin.attachments?.map(async (attachment) => {
            await attachmentApi.deleteBulletinAttachments(attachment);
          })
        );
      }

      // delete bulletin record
      if (bulletinQuery.data?.bulletin.id) {
        await executeDeleteButtetin(bulletinQuery.data?.bulletin.id!);
      }
      setIsBulletinDeleteLoading(false);
      navigate("/user");
    } catch (error) {
      setIsBulletinDeleteLoading(false);
      console.error("Error deleting bulletin:", error);
    }
  };

  if (isLoading || !bulletinQuery.data) {
    return <LoadingSpinner />;
  }

  if (isBulletinDeleteLoading) {
    return <LoadingSpinner />;
  }

  console.log({ ggg: bulletinQuery.data?.bulletin.attachments });

  return (
    <div className="max-w-7xl">
      {errorMessage && (
        <AlertModal
          title={"Message"}
          alertType={AlertType.warning}
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      )}
      <Breadcrumbs items={bulletinDetailsBreadcrumbItems} />
      <ShareBulletinModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <header className="mt-12 flex flex-col justify-between whitespace-nowrap gap-4 sm:flex-row">
        <div className="flex gap-6 items-center justify-between w-full">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Bulletin Details</h1>
          </div>
          {userProfile?.user_type !== UserTypes.Reader && (
            <div className="flex gap-3 items-center">
              <BulletinActions
                handleEdit={() => navigate(`/user/bulletins/update/${id}`)}
                handleShare={handleModal}
                handleUnShare={handleUnShare}
                bulletinDetails={bulletinQuery.data}
                handleDelete={handleDelete}
              />
            </div>
          )}
        </div>
      </header>
      <main className="mt-5 border border-gray-200 rounded p-5 sm:p-9">
        <div className="flex flex-col gap-10 justify-between ">
          <div className="flex-col flex sm:flex-row justify-between w-full gap-5">
            <div className="flex flex-col gap-3 m-auto w-[50%]">
              <span className="font-semibold text-[17px]">Description</span>
              <span className="font-normal text-base text-[#676767]">
                {bulletinQuery.data?.bulletin?.description}
              </span>
            </div>
            <div className="flex flex-col gap-3 m-auto w-[50%]">
              <span className="font-semibold text-[17px]">Created By</span>
              <div className="flex items-center gap-2">
                <div className="w-10 h-10 items-center">
                  <ProfileAvatar access_image_url={avatarUrl || ""} />
                </div>
                <span className="font-normal text-base text-[#676767]">
                  {" "}
                  {bulletinQuery.data?.bulletin?.User
                    ? `${stringUtil.shortenName(
                        bulletinQuery.data?.bulletin.User?.first_name ?? "",
                        bulletinQuery.data?.bulletin.User?.last_name ?? ""
                      )}`
                    : "Archived User"}
                </span>
              </div>
            </div>
          </div>

          <div className="flex-col flex sm:flex-row justify-between w-full gap-5">
            <div className="flex flex-col gap-3 m-auto w-[50%]">
              <span className="font-semibold text-[17px]">State</span>
              <span className="font-normal text-base text-[#676767]">
                {bulletinQuery.data?.bulletin?.state}
              </span>
            </div>
            <div className="flex flex-col gap-3 m-auto w-[50%]">
              <span className="font-semibold text-[17px]">Created For</span>
              <span className="rounded-[19px] w-fit bg-[#F3F7FF] text-center text-[13px] p-2 text-[#3371EB]">
                {bulletinQuery.data?.bulletin.targetIwiCollective?.name}
              </span>
            </div>
          </div>

          <div className="flex-col flex sm:flex-row justify-between w-full gap-5">
            <div className="flex flex-col gap-3 m-auto w-[50%] ">
              <span className="font-semibold text-[17px]">Created On</span>
              <span className="font-normal text-base text-[#676767]">{createdAt}</span>
            </div>
            <div className="flex flex-col gap-3 m-auto w-[50%] ">
              <span className="font-semibold text-[17px]">Last Updated</span>
              <span className="font-normal text-base text-[#676767]">{updatedAt}</span>
            </div>
          </div>
          <div>
            <h1 className="font-bold text-base mb-5">Attachments</h1>
            <ul role="list" className="flex flex-col gap-3">
              {bulletinQuery.data?.bulletin.attachments?.map((attachment) => {
                let icon;
                if (attachment.fileType === "image/jpeg" || attachment.fileType === "image/png") {
                  icon = <Image />;
                } else if (attachment.fileType === "application/pdf") {
                  icon = (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                      />
                    </svg>
                  );
                } else {
                  icon = <File />;
                }
                return (
                  <li
                    key={attachment?.id}
                    className="pl-3 pr-4 py-3 flex bg-[#F9F9F9] border border-gray-200 rounded-md items-center gap-2 justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                        className="flex-shrink-0 h-5 w-5 text-gray-400">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"></path>
                      </svg> */}
                      {icon}
                      <span className="ml-2 flex-1 w-0 truncate">
                        {stringUtil.truncate(attachment?.fileName || "", 30)}
                      </span>
                      <p className="flex-1 w-0">
                        {stringUtil.formatByes(attachment?.size) ?? "200KB"}
                      </p>
                      <p className="flex-1 w-0">{createdAt}</p>
                    </div>
                    <div className="ml-4 flex-shrink-0 cursor-pointer">
                      <div
                        onClick={() => handleDownload(attachment)}
                        className="font-medium text-brand-primary hover:text-indigo-500">
                        <Download />
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </main>
    </div>
  );
};

export default BulletinDetails;
