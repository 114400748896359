import * as React from "react";

const UserTableHeader: React.FC = () => {
  return (
    <>
      <colgroup>
        <col className="w-1/12" />
        <col className="sm:w-1/12" />
        <col className="sm:w-4/12" />
        <col className="lg:w-4/12" />
        <col className="lg:w-2/12" />
        <col className="lg:w-1/12" />
        <col className="lg:w-1/12" />
      </colgroup>
      <thead className="border-b border-black/10 text-sm  text-gray-900">
        <tr>
          <th scope="col" className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8 text-center"></th>
          <th
            scope="col"
            className="hidden sm:table-cell py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8 text-left">
            User
          </th>
          <th scope="col" className="py-2 pl-0 pr-8 font-semibold sm:table-cell text-center">
            Details
          </th>
          <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell text-center">
            User Type
          </th>
          <th
            scope="col"
            className="hidden sm:table-cell py-2 pl-0 pr-4 font-semibold sm:pr-8 sm:text-center lg:pr-20 text-center">
            Status
          </th>
          <th
            scope="col"
            className="hidden lg:table-cell py-2 pl-0 pr-8 font-semibold lg:pr-20 text-center">
            Iwi Collective
          </th>
          <th
            scope="col"
            className="hidden lg:table-cell py-2 pl-0 pr-8 font-semibold lg:pr-20 text-center">
            Iwi
          </th>
          <th
            scope="col"
            className="py-2 pl-0 pr-4 font-semibold sm:table-cell sm:pr-6 lg:pr-8 text-center">
            Actions
          </th>
        </tr>
      </thead>
    </>
  );
};

export default UserTableHeader;
