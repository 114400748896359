import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import ProfileAvatar from "@src/components/ui/ProfileAvatar";
import { stringUtil } from "@src/util/string-util";
import { useNavigate } from "react-router-dom";
import { IBulletinDetails } from "@src/types/model/IBulletin";
import Chip from "@src/components/ui/chip";
import { useQuery } from "@tanstack/react-query";
import { bulletinApi } from "@src/api/bulletin-api";
import useAuthStore from "@src/stores/auth-store";
import { storageUtils } from "@src/util/storage-utils";

type BulletinCardProps = {
  details: IBulletinDetails;
};

export const BulletinCard = (props: BulletinCardProps) => {
  const navigate = useNavigate();
  const [avatarUrl, setAvatarUrl] = useState("");
  const date = dayjs(props?.details?.bulletin?.createdAt);
  const formattedDate = date.format("DD MMM YY");
  const [isShared, setIsShared] = useState(false);
  const { userProfile } = useAuthStore((state) => state);

  const sharedTableQuery = useQuery({
    queryKey: ["bulletin", props.details.bulletin.id],
    queryFn: () => bulletinApi.getBulletinById(props.details.bulletin.id),
  });

  useEffect(() => {
    switch (userProfile?.user_type) {
      case "Router":
        setIsShared(!!sharedTableQuery.data?.iwi?.id);
        break;
      default:
        setIsShared(false);
        break;
    }
  }, [sharedTableQuery.data]);

  useEffect(() => {
    const getImageFromKey = async () => {
      const imageFromKey = await storageUtils.getFileAccessUrl(
        props?.details?.bulletin?.User?.avatar_url ?? ""
      );
      setAvatarUrl(imageFromKey.url.href);
    };
    getImageFromKey();
  }, [userProfile]);

  return (
    <div
      className="relative customGradient bg-gradient-to-b from-[#EEF3FF] rounded-lg cursor-pointer border border-gray-200 w-full min-h-[262px] max-w-md"
      onClick={() => navigate(`/user/bulletins/${props.details.bulletin.id}`)}
      key={props.details.bulletin.id}>
      {isShared && <Chip title={"shared"} />}
      {/* <div className="flex items-center justify-between mt-4 rounded-t-lg">
        <div className="px-3 py-1 rounded-full text-xs font-medium">{formattedDate}</div>
        <div className="px-3 py-1 rounded-full text-xs font-medium">{formattedTime}</div>
      </div> */}
      <div className="flex items-center gap-1 px-6 pt-6">
        <span className="inline-flex items-center  p-1.5 text-xs font-bold text-[#3371EB] my-1">
          {props.details.bulletin.targetIwiCollective?.name}
        </span>
      </div>
      <div className="p-6 h-36">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-bold mb-2 overflow-hidden overflow-ellipsis truncate">
            {props.details.bulletin.title}
          </h2>
        </div>
        <p className="text-gray-500 dark:text-gray-400 mb-4 truncate-lines-2">
          {props.details.bulletin.description}
        </p>
      </div>

      <div className="w-full">
        <div className="border-t border-gray-300 mx-5 my-2" />
        <div className="flex items-center px-6 mb-4">
          <div className="flex">
            <ProfileAvatar className="w-[24px] h-[24px]" access_image_url={avatarUrl ?? ""} />
          </div>
          <div className="ml-4 text-sm leading-6 flex items-center justify-between w-full">
            <div>
              <p className="font-medium text-xs text-[#383838]">
                {props.details.bulletin.User
                  ? `${stringUtil.shortenName(
                      props.details.bulletin.User?.first_name ?? "Archived",
                      props.details.bulletin.User?.last_name ?? "User"
                    )}`
                  : "Archived User"}
              </p>
            </div>
            <div className="flex items-center gap-1">
              <span className="px-3 py-1 rounded-full text-[11px] text-[#676767] font-medium">
                {formattedDate}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulletinCard;
