import { useState } from "react";
import { getUrl, uploadData, remove, RemoveInput } from "aws-amplify/storage";

interface UploadProgress {
  loaded: number;
  total: number;
}

export const useFileStorage = () => {
  const [uploadProgress, setUploadProgress] = useState<UploadProgress>({ loaded: 0, total: 0 });
  const [uploading, setUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [result, setResult] = useState<any>(null);

  // NOTE: fileUrl is the path to the file in the S3 bucket (e.g., user-profile-images/1234.jpg)
  const uploadFile = async (file: File, fileUrl: string) => {
    setUploading(true);
    setError(null);
    try {
      const result = await uploadData({
        // The key is the path to the file in the S3 bucket (e.g., user-profile-images/1234.jpg)
        key: `${fileUrl}`,
        data: file,
        options: {
          // The access level of the file - protected restricts to authed users. There is additional configuration in amplify to restrict access via IAM groups
          accessLevel: "guest",
          onProgress: (progressEvent) => {
            setUploadProgress({
              loaded: progressEvent.transferredBytes ?? 0,
              total: progressEvent.totalBytes ?? 0,
            });
          },
        },
      });

      setResult(result);
    } catch (error) {
      if (error instanceof Error) {
        setError(error);
      } else {
        setError(new Error("An unknown error occurred."));
      }
    } finally {
      setUploading(false);
    }
  };

  const deleteFile = async (fileUrl: string) => {
    console.log("deleting: ", fileUrl);
    setIsDeleting(true);
    setError(null);
    try {
      // Assuming fileUrl is the key for the file in the S3 bucket
      const input: RemoveInput = {
        key: fileUrl,
        options: { accessLevel: "guest" },
      };
      await remove(input);
    } catch (error) {
      if (error instanceof Error) {
        setError(error);
      } else {
        setError(new Error("An unknown error occurred during file deletion."));
      }
    } finally {
      setIsDeleting(false);
    }
  };

  return { uploadFile, uploading, uploadProgress, error, result, deleteFile, isDeleting };
};
