import { useEffect } from "react";
import { useApiCall } from "@src/hooks/api-call";
import LoadingSpinner from "@src/components/overlays/LoadingSpinner";
import Breadcrumbs, { IBreadCrumbProps } from "@src/components/ui/breadcrumbs";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import IconButton from "@src/components/ui/icon-button";
import { userApi } from "@src/api/user-api";
import UserTable from "./modules/user-table";
import { useNavigate } from "react-router-dom";
import useAuthStore from "@src/stores/auth-store";
import { useQuery } from "@tanstack/react-query";

const pages: IBreadCrumbProps = { items: [{ name: "Users", url: "#", current: true }] };

export const UsersPage = () => {
  const navigate = useNavigate();
  const { userProfile, isAdmin, userGroups } = useAuthStore((state) => state);
  const query = useQuery({
    queryKey: ["listUsers"],
    queryFn: () => userApi.listUsers(),
  });

  return (
    <div>
      {query.isLoading && <LoadingSpinner />}
      <Breadcrumbs items={pages.items} />
      <div className={"p-6 overflow-hidden rounded-lg bg-white shadow"}>
        <div className="flex flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
          <h1 className="text-base font-semibold leading-7 text-gray-900">Portal Users</h1>
          {userGroups.includes("Admins") && (
            <IconButton
              title={"New User"}
              IconComponent={PlusCircleIcon}
              onClick={() => navigate("/admin/users/create")}
            />
          )}
        </div>
      </div>

      <div className={"p-2 overflow-hidden rounded-lg bg-white"}>
        <UserTable data={query.data || []} />
      </div>
    </div>
  );
};

export default UsersPage;
