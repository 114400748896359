import React from "react";
import { Link } from "react-router-dom";

export const LoginButton = () => {
  return (
    <button className="cursor-pointer">
      <Link
        to="/auth/login"
        className="text-center text-sm font-sans text-white bg-brand-secondary flex flex-row w-20 h-8 items-center justify-center px-1 rounded ml-2">
        Log in
      </Link>
    </button>
  );
};
