import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  phone_number: Yup.string().required("Phone number is required"),
  job_title: Yup.string().required("Job title is required"),
  user_type: Yup.string().required("User type is required"),
  iwi_collective: Yup.string().required("Iwi collective is required"),
  iwi: Yup.string(),
  attribute: Yup.string(),
});

export default validationSchema;
