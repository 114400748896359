import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import SelectInput from "@src/components/forms/select-input";
import TextInputCombo from "@src/components/forms/text-input-combo";
import { iwiCollectiveApi } from "@src/api/iwi-collective-api";
import { iwiApi } from "@src/api/iwi-api";
import { Iwi, IwiCollective } from "@src/API";
import { useApiCall } from "@src/hooks/api-call";
import { apiSignUpQuery } from "@src/app/public-pages/auth/sign-up/modules/api-query";
import { userApi } from "@src/api/user-api";
import AlertModal, { AlertType } from "@src/components/overlays/alert-modal";
import LoadingSpinner from "@src/components/overlays/LoadingSpinner";
import validationSchema from "./update-user-validation";
import { UserAttribute, UserTypes } from "@src/constant/user";
import FileUploadDropZone, { IReadyFiles } from "@src/components/file-upload-drop-zone";
import { useFileStorage } from "@src/hooks/storage-hooks";
import { ETargetFileType } from "@src/types/storage";

export type FormValues = {
  title: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  job_title: string;
  user_type?: UserTypes;
  iwi_collective: string;
  iwi: string;
  attribute: string;
  userIwiCollectiveId?: string;
  avatar_url: string;
};

const UserForm: React.FC = () => {
  const { uploadFile } = useFileStorage();
  const [iwiCollectives, setIwiCollectives] = useState<IwiCollective[]>([]);
  const [iwiOptions, setIwiOptions] = useState<Iwi[]>([]);
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [message, setMessage] = useState<{ type: AlertType; message: string }>();
  const [primaryImageFiles, setPrimaryImageFiles] = useState<IReadyFiles[]>([]);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("userId");

  const INITIAL_VALUES: FormValues = {
    title: selectedUser?.title ?? "",
    first_name: selectedUser?.first_name ?? "",
    last_name: selectedUser?.last_name ?? "",
    email: selectedUser?.email ?? "",
    phone_number: selectedUser?.phone_number ?? "",
    job_title: selectedUser?.job_title ?? "",
    iwi_collective: selectedUser?.userIwiCollectiveId ?? "",
    iwi: selectedUser?.iwiID ?? "",
    user_type: selectedUser?.user_type ?? "",
    attribute: selectedUser?.user_attribute ?? "",
    userIwiCollectiveId: selectedUser?.userIwiCollectiveId ?? "",
    avatar_url: selectedUser?.avatar_url ?? "",
  };

  const {
    execute: executeSignUp,
    isLoading: isSignUpLoading,
    errorMessage: signUpErrorMessage,
  } = useApiCall(apiSignUpQuery, {
    onSuccess: () => {},
    onError: (error) => {
      console.log(JSON.stringify(error));
    },
  });

  interface UserPayload {
    id: any;
    email: any;
    first_name: any;
    last_name: any;
    phone_number: any;
    account_verified: any;
    user_type: any;
    job_title: any;
    title: any;
    userIwiCollectiveId: any;
    avatar_url: any;
    user_attribute?: any; // Optional property
    iwiID?: any; // Optional property
  }

  const handleSubmit = async (values: FormValues) => {
    console.log({ values });
    setIsLoading(true);

    const {
      first_name,
      last_name,
      phone_number,
      user_type,
      job_title,
      title,
      attribute: user_attribute,
      iwi,
      iwi_collective,
    } = values;

    if (primaryImageFiles.length > 0) {
      const fileName = `${ETargetFileType.USER_PROFILE_IMAGE}/${primaryImageFiles[0].file.name}`;
      console.log({ fileName });
      await uploadFile(primaryImageFiles[0].data, fileName);
      values.avatar_url = fileName;
    }

    // Initialize the payload with required properties
    const payload: UserPayload = {
      id: selectedUser?.id,
      email: selectedUser.email,
      first_name,
      last_name,
      phone_number,
      account_verified: selectedUser.account_verified,
      user_type,
      job_title,
      title,
      userIwiCollectiveId: iwi_collective,
      avatar_url: values.avatar_url,
      iwiID: iwi,
    };

    // Add optional properties based on user_type
    if (user_type === UserTypes.Reader) {
      payload.user_attribute = user_attribute;
    }
    if (user_type === UserTypes.Writer) {
      payload.iwiID = null;
    }

    console.log(payload);

    try {
      const response = await userApi.updateUserByAdmin(payload);
      setMessage({ type: AlertType.success, message: "Profile updated successfully!" });
      console.log("User profile updated successfully", response);
    } catch (error) {
      console.error("Error updating user:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function fetchIwiCollectives() {
      try {
        const collectives = await iwiCollectiveApi.listIwiCollectives();
        setIwiCollectives(collectives);
      } catch (error) {
        console.error("Error fetching Iwi Collectives:", error);
      }
    }

    fetchIwiCollectives();
  }, []);

  useEffect(() => {
    async function fetchIwiOptions() {
      if (selectedUser?.userIwiCollectiveId || selectedValue) {
        try {
          const iwi = await iwiApi.getIwisByIwicollectiveID(
            selectedUser?.userIwiCollectiveId || selectedValue
          );
          setIwiOptions(iwi);
        } catch (error) {
          console.error("Error fetching Iwi options:", error);
        }
      }
    }

    console.log("Selected Value:", selectedValue);
    fetchIwiOptions();
  }, [selectedValue, selectedUser]);

  useEffect(() => {
    const getUser = async () => {
      if (userId) {
        const getUserDataFromId = await userApi.getUserDetails(userId);
        setSelectedUser(getUserDataFromId);
      }
    };
    getUser();
  }, [userId]);

  return (
    <div>
      {message && (
        <AlertModal
          message={message.message}
          title={message.type === AlertType.error ? "Error" : "Success"}
          onClose={() => {
            setMessage(undefined);
            if (message.type === AlertType.success) {
              setTimeout(() => {
                navigate("/admin/users");
              }, 200);
            }
          }}
          alertType={message.type}
        />
      )}
      {isSignUpLoading && <LoadingSpinner />}
      <Formik
        enableReinitialize
        initialValues={INITIAL_VALUES}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}>
        {({ values, setFieldValue, errors }) => (
          <Form>
            <div className="space-y-12">
              <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900 mt-10">
                  Update User
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Update user through this portal
                </p>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <SelectInput
                      name="title"
                      label="Title"
                      options={[
                        "Mr",
                        "Mrs",
                        "Miss",
                        "Ms",
                        "Dr",
                        "Prof",
                        "Rev",
                        "Sir",
                        "Madam",
                        "Other",
                      ]}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <TextInputCombo name="first_name" type="text" label="First name" />
                  </div>
                  <div className="sm:col-span-3">
                    <TextInputCombo name="last_name" type="text" label="Last name" />
                  </div>
                  <div className="sm:col-span-3">
                    <TextInputCombo name="email" disabled type="email" label="Email" />
                  </div>
                  <div className="sm:col-span-3">
                    <TextInputCombo name="phone_number" type="text" label="Phone" />
                  </div>
                  <div className="sm:col-span-3">
                    <TextInputCombo name="job_title" type="text" label="Job title" />
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-12">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900 mt-10">User Type</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Select user type and affiliations
                </p>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                  <div className="sm:col-span-6">
                    <SelectInput
                      name="user_type"
                      label="User Type"
                      options={Object.values(UserTypes)}
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="iwi-collective"
                      className="block text-base font-medium leading-6 text-gray-900">
                      Iwi Collective
                    </label>
                    <div className="mt-2">
                      <Field
                        as="select"
                        id="iwi-collective"
                        name="iwi_collective"
                        className="pl-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                        onChange={async (e: { target: { value: any } }) => {
                          setFieldValue(
                            "iwi_collective",
                            e.target.value ?? selectedUser?.userIwiCollectiveId
                          );
                          setSelectedValue(e.target.value ?? selectedUser?.userIwiCollectiveId);
                          try {
                            const iwi = await iwiApi.getIwisByIwicollectiveID(
                              e.target.value ?? selectedUser?.userIwiCollectiveId
                            );
                            setIwiOptions(iwi);
                          } catch (error) {
                            console.error("Error fetching Iwi options:", error);
                          }
                        }}>
                        <option value="0">Select an option</option>
                        {iwiCollectives.map((collective) => (
                          <option key={collective.id} value={collective.id}>
                            {collective.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-12">
              <div className="border-b border-gray-900/10 pb-12">
                <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-8 ">
                  {[UserTypes.Reader, UserTypes.Router].includes(values.user_type as UserTypes) ? (
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="iwi"
                        className="block text-base font-medium leading-6 text-gray-900">
                        Iwi
                      </label>
                      <div className="mt-2">
                        <Field
                          as="select"
                          id="iwi"
                          onChange={(e: { target: { value: any } }) =>
                            setFieldValue("iwi", e.target.value ?? selectedUser?.iwiID)
                          }
                          name="iwi"
                          className="pl-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                          <option value="0">Select an option</option>
                          {iwiOptions.map((iwi) => (
                            <option key={iwi.id} value={iwi.id}>
                              {iwi.name}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>
                  ) : null}
                  {values.user_type === UserTypes.Reader ? (
                    <div className="sm:col-span-1">
                      <SelectInput
                        name="attribute"
                        label="User attribute"
                        options={Object.values(UserAttribute)}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="border-b border-gray-900/10 pb-12 py-5">
              <article className="sm:col-span-6 max-w-5xl">
                <FileUploadDropZone
                  label={"Upload Profile Image"}
                  files={primaryImageFiles}
                  isMultiple={false}
                  setFiles={setPrimaryImageFiles}
                  onFileDelete={() =>
                    setPrimaryImageFiles(
                      primaryImageFiles.filter((file) => file.uuid !== file.uuid)
                    )
                  }
                />
              </article>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Save
              </button>
            </div>
            {isLoading && <LoadingSpinner />}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UserForm;
