import React from "react";
import "./App.css";
import BaseRouter from "@src/app/base-router";
import config from "./amplifyconfiguration.json";
import { Amplify } from "aws-amplify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

Amplify.configure(config);
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BaseRouter />
    </QueryClientProvider>
  );
}

export default App;
