import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { X } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

import { UserAttribute } from "@src/constant/user";
import { bulletinApi } from "@src/api/bulletin-api";
import LoadingSpinner from "@src/components/overlays/LoadingSpinner";
import useAuthStore from "@src/stores/auth-store";
import { CreateIwiShareInput } from "@src/API";
import userProfile from "@src/app/user-pages/user-profile";
import { useQueryClient } from "@tanstack/react-query";

type ShareBulletinModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ShareBulletinModal: React.FC<ShareBulletinModalProps> = ({ isOpen, setIsOpen }) => {
  const { id } = useParams();
  const [selectedAttribute, setSelectedAttribute] = useState<UserAttribute>();
  const [isLoading, setIsLoading] = useState(false);
  const { userProfile } = useAuthStore();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleShare = async () => {
    try {
      setIsLoading(true);
      if (id && selectedAttribute && userProfile?.iwiID) {
        const input: CreateIwiShareInput = {
          sharedWithRole: "Reader",
          attribute: "",
          bulletinID: id,
          iwiID: userProfile.iwiID,
          userID: userProfile.id,
        };
        await bulletinApi.shareBulletinWithIwi(input);
        setIsOpen(false);
        navigate("/user");
      }
    } catch (error) {
      console.log(error);
    } finally {
      // // invalidate details query
      // await queryClient.invalidateQueries({
      //   queryKey: ["bulletin", id],
      // });
      //
      // // invalidate dashboard
      // await queryClient.invalidateQueries({
      //   queryKey: ["bulletins", [userProfile?.iwiCollective?.id!]],
      // });
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 rounded-md shadow-lg w-96 h-64">
          <Dialog.Title className="text-lg font-bold mb-2 flex items-center justify-between">
            <p>Share Bulletin</p>
            <X className="cursor-pointer" onClick={() => setIsOpen(false)} />
          </Dialog.Title>

          <div className="flex flex-col justify-center mt-10">
            <select
              className="rounded-md p-1 border"
              onChange={(e) => setSelectedAttribute(e.target.value as UserAttribute)}>
              <option value="">Select an option</option>
              {Object.values(UserAttribute).map((attribute, index) => (
                <option key={index} value={attribute}>
                  {attribute}
                </option>
              ))}
            </select>

            <div className="flex justify-center">
              <button
                onClick={handleShare}
                className="bg-blue-900 mt-10 text-white py-2 px-4 rounded-md hover:bg-blue-600"
                type="submit">
                Share Bulletin
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ShareBulletinModal;
