import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { bulletinApi } from "@src/api/bulletin-api";
import LoadingSpinner from "@src/components/overlays/LoadingSpinner";
import Breadcrumbs from "@src/components/ui/breadcrumbs";
import IconButton from "@src/components/ui/icon-button";
import { bulletinsBreadcrumbItems } from "@src/constant";
import { UserTypes } from "@src/constant/user";
import useAuthStore from "@src/stores/auth-store";
import BulletinCard from "@src/components/ui/bulletin-card";
import { useQuery } from "@tanstack/react-query";
import { IBulletinDetails } from "@src/types/model/IBulletin";
import ReactPaginate from "react-paginate";
import { ChevronLeft, ChevronRight, PlusIcon } from "lucide-react";

export const Index = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userProfile } = useAuthStore((state) => state);
  const [nextToken, setNextToken] = useState<string | null | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [bulletins, setBulletins] = useState<IBulletinDetails[]>([]);
  const limit = 15;
  const [itemsPerPage, setItemsPerPage] = useState<number>(6);

  const queryAll = useQuery({
    queryKey: ["bulletins", [userProfile?.IwiCollective?.id!]],
    queryFn: () => bulletinApi.listAllBulletinsByRole(userProfile?.user_type!, limit),
  });

  useEffect(() => {
    if (queryAll.data) {
      setBulletins(queryAll.data.data);
      setNextToken(queryAll.data.nextToken);
    }
  }, [queryAll.data]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 639) {
        setItemsPerPage(3);
      } else {
        setItemsPerPage(6);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const loadMoreBulletins = async (nextToken: string | undefined) => {
    setIsLoading(true);
    try {
      const data = await bulletinApi.listAllBulletinsByRole(
        userProfile?.user_type!,
        limit,
        nextToken
      );
      setBulletins((prevBulletins: IBulletinDetails[]) => {
        const newBulletins = [];
        for (const bulletin of data.data) {
          if (
            !prevBulletins.some(
              (existingBulletin) => existingBulletin.bulletinID === bulletin.bulletinID
            )
          ) {
            newBulletins.push(bulletin);
          }
        }
        return [...prevBulletins, ...newBulletins];
      });
      setNextToken(data.nextToken);
    } catch (error) {
      console.error("Error loading more bulletins:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageClick = async (selectedItem: { selected: number }) => {
    const selectedPage = selectedItem.selected;
    setCurrentPage(selectedPage);

    const totalLoadedItems = bulletins.length;
    const totalRequiredItems = (selectedPage + 1) * itemsPerPage;

    if (totalRequiredItems > totalLoadedItems && nextToken) {
      await loadMoreBulletins(nextToken);
    }
  };

  return (
    <div className="max-w-7xl h-full">
      <Breadcrumbs items={bulletinsBreadcrumbItems} />

      {(isLoading || queryAll.isLoading) && <LoadingSpinner />}
      <header className="flex flex-col whitespace-nowrap gap-4 sm:flex-row">
        <div className="flex w-full justify-center border-t border-gray-200">
          <div className="flex mt-10 w-full flex-col sm:flex-row justify-center gap-5 sm:justify-between items-center">
            <h1 className="text-3xl font-bold text-gray-900">Bulletin Board</h1>
            {(userProfile?.user_type === UserTypes.Writer) && (
              <div className="">
                <IconButton
                  title={"New Bulletin"}
                  className="bg-indigo-700 hover:bg-indigo-500 focus-visible:outline-indigo-600"
                  IconComponent={PlusIcon}
                  onClick={() => navigate("/user/bulletins/create")}
                />
              </div>
            )}
          </div>
        </div>
      </header>

      <div className="mx-auto max-w-7xl flex flex-col justify-between">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-6 pt-10 sm:mt-5 sm:pt-5 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {bulletins
            ?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
            ?.map((item: IBulletinDetails) => {
              return <BulletinCard key={item.bulletinID} details={item} />;
            })}
        </div>

        {bulletins?.length > 0 ? (
          <div className="w-full flex justify-center py-6">
            <ReactPaginate
              className="flex paginationDots"
              previousLabel={<ChevronLeft size={15} />}
              nextLabel={<ChevronRight size={15} />}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={Math.ceil(bulletins.length / itemsPerPage)}
              marginPagesDisplayed={0.5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageClassName={"page-item"}
              previousClassName={"page-item"}
              nextClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
              forcePage={currentPage}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Index;
