import React from "react";

type ProfileAvatarProps = {
  access_image_url?: string; // Assuming it's an optional string
  height?: number;
  className?: string;
};

export const ProfileAvatar: React.FC<ProfileAvatarProps> = ({
  access_image_url,
  height,
  className,
}) => {
  // const handleImageLoadError = async () => {
  //   // handle when image fails to load
  //   const currentDetails = StateUserProfileService.getUserDetails();
  //   const details = await UserProfileApi.getInstance().getUserDetails(currentDetails.user.id);
  //   StateUserProfileService.updateUserDetails(details);
  // };

  return (
    <span
      className={`inline-block overflow-hidden rounded-full bg-gray-100 ${height ? `h-${height} w-${height}` : "h-9 w-9"
        } ${className}`}>
      {access_image_url ? (
        <img
          className="h-full w-full rounded-full object-cover"
          src={access_image_url}
          alt={""}
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.src =
              "https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg";
          }}
        />
      ) : (
        <DefaultProfileImage />
      )}
    </span>
  );
};

interface IDefaultProfileImageProps {
  className?: string;
}

export const DefaultProfileImage: React.FC<IDefaultProfileImageProps> = (props) => (
  <svg
    className={`${props.className ? props.className : "h-full w-full text-gray-500"}`}
    fill="currentColor"
    viewBox="0 0 24 24">
    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
  </svg>
);

export default ProfileAvatar;
