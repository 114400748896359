import React from "react";
import BulletinForm from "./modules/bulletin-form";
import Breadcrumbs from "@src/components/ui/breadcrumbs";
import { createBulletinsBreadcrumbItems } from "@src/constant";

const CreateBulletins: React.FC = () => {
  return (
    <>
      <Breadcrumbs items={createBulletinsBreadcrumbItems} />
      <BulletinForm />
    </>
  );
};

export default CreateBulletins;
