import React from "react";
// import { ReactComponent as Logo } from "@src/images/logos/logo-blue.svg";
import VerifyForm from "@src/app/public-pages/auth/verify/modules/verify-form";

export const VerifyPage = () => {
  // const navigate = useNavigate();
  return (
    <div className={"flex justify-center"}>
      <div className="flex min-h-full flex-1 relative overflow-hidden">
        <div className="flex flex-1 flex-col justify-center">
          {/*<Logo className={"absolute opacity-5 -z-20 rotate-cw"} />*/}
          <div className="mx-auto w-full max-w-sm">
            <div>
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Verify your account
              </h2>
              <div className={"flex items-center"}>
                <p className="mt-2 text-sm leading-6 text-gray-500">
                  Enter the validation code sent to your email - Check your spam folder
                </p>
                <div
                  className={
                    "mt-2 cursor-pointer px-2 text-sm font-semibold text-brand-primary hover:text-brand-accent"
                  }></div>
              </div>
            </div>

            <div className="mt-10">
              <VerifyForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyPage;
