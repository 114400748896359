import * as yup from "yup";
import phone from "phone";

export const customValidationSchema = {
  phoneSchema: yup
    .string()
    .required("Phone is required")
    // Apply the transformation first
    .transform((value, originalValue) => phoneTransform(originalValue, ["AU", "NZ"]))
    // Then validate the transformed value
    .test("is-valid-phone", "Phone number is not valid", (value) => {
      // Since the transformation already checks validity, just ensure it's in E.164 format
      return /^\+\d{11,15}$/.test(value);
    }),
};

const phoneTransform = (value: any, countries: string[]) => {
  for (const country of countries) {
    const res = phone(value, { country: country, validateMobilePrefix: true });
    if (res.isValid) {
      // Return the formatted E.164 phone number if valid
      return res.phoneNumber;
    }
  }
  // Return the original value if no valid transformation was found
  return value;
};
