import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import geoJson from "./regional-council-2023-generalised.json";
import "leaflet/dist/leaflet.css";
import { UpdateMapZoom } from "./update-map-zoom";
import { styleFeature } from "./style/style-layer";
import { CustomMarker } from "./custom-map-marker";

export const MapComponent: React.FC = () => {
  const [zoom, setZoom] = useState(7);

  useEffect(() => {
    const updateZoom = () => {
      const newZoom = window.innerWidth < 640 ? 6 : 7; // Choose zoom levels as per your requirement
      setZoom(newZoom);
    };

    window.addEventListener("resize", updateZoom);

    updateZoom();

    // Clean up
    return () => window.removeEventListener("resize", updateZoom);
  }, []);

  return (
    // leaflet styles are written with inline styles
    <div
      className="h-[450px] sm:h-[650px]"
      style={{
        width: "100%",
        position: "relative",
        backgroundColor: "white",
      }}>
      {
        <MapContainer
          // @ts-ignore
          center={[-38.6843, 175.5689]}
          zoom={zoom}
          scrollWheelZoom={false}
          style={{ height: "100%", width: "100%", backgroundColor: "white" }}>
          <UpdateMapZoom zoom={zoom} />
          <TileLayer
            // @ts-ignore
            attribution='&copy; <a href="https://carto.com/attributions">CARTO</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
          />
          {geoJson.features.map((feature, index) => (
            <CustomMarker key={index} feature={feature} cssRules={styleFeature(feature as any)} />
          ))}
        </MapContainer>
      }
    </div>
  );
};
