/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getIwiShare = /* GraphQL */ `query GetIwiShare($id: ID!) {
  getIwiShare(id: $id) {
    id
    attribute
    sharedWithRole
    userID
    iwiID
    bulletinID
    iwi {
      id
      name
      iwicollectiveID
      createdAt
      updatedAt
      __typename
    }
    bulletin {
      id
      title
      description
      image_url
      state
      createdAt
      targetIwiCollectiveID
      updatedAt
      bulletinUserId
      __typename
    }
    user {
      id
      email
      first_name
      last_name
      avatar_url
      phone_number
      account_verified
      iwiID
      user_type
      title
      job_title
      user_attribute
      status
      createdAt
      updatedAt
      userIwiCollectiveId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIwiShareQueryVariables,
  APITypes.GetIwiShareQuery
>;
export const listIwiShares = /* GraphQL */ `query ListIwiShares(
  $filter: ModelIwiShareFilterInput
  $limit: Int
  $nextToken: String
) {
  listIwiShares(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      attribute
      sharedWithRole
      userID
      iwiID
      bulletinID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIwiSharesQueryVariables,
  APITypes.ListIwiSharesQuery
>;
export const iwiSharesByIwiID = /* GraphQL */ `query IwiSharesByIwiID(
  $iwiID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelIwiShareFilterInput
  $limit: Int
  $nextToken: String
) {
  iwiSharesByIwiID(
    iwiID: $iwiID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      attribute
      sharedWithRole
      userID
      iwiID
      bulletinID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.IwiSharesByIwiIDQueryVariables,
  APITypes.IwiSharesByIwiIDQuery
>;
export const iwiSharesByBulletinID = /* GraphQL */ `query IwiSharesByBulletinID(
  $bulletinID: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelIwiShareFilterInput
  $limit: Int
  $nextToken: String
) {
  iwiSharesByBulletinID(
    bulletinID: $bulletinID
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      attribute
      sharedWithRole
      userID
      iwiID
      bulletinID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.IwiSharesByBulletinIDQueryVariables,
  APITypes.IwiSharesByBulletinIDQuery
>;
export const getIwiCollectiveShare = /* GraphQL */ `query GetIwiCollectiveShare($id: ID!) {
  getIwiCollectiveShare(id: $id) {
    id
    bulletinID
    bulletin {
      id
      title
      description
      image_url
      state
      createdAt
      targetIwiCollectiveID
      updatedAt
      bulletinUserId
      __typename
    }
    iwiCollectiveID
    iwiCollective {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    User {
      id
      email
      first_name
      last_name
      avatar_url
      phone_number
      account_verified
      iwiID
      user_type
      title
      job_title
      user_attribute
      status
      createdAt
      updatedAt
      userIwiCollectiveId
      __typename
    }
    createdAt
    updatedAt
    iwiCollectiveShareUserId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIwiCollectiveShareQueryVariables,
  APITypes.GetIwiCollectiveShareQuery
>;
export const listIwiCollectiveShares = /* GraphQL */ `query ListIwiCollectiveShares(
  $filter: ModelIwiCollectiveShareFilterInput
  $limit: Int
  $nextToken: String
) {
  listIwiCollectiveShares(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      bulletinID
      iwiCollectiveID
      createdAt
      updatedAt
      iwiCollectiveShareUserId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIwiCollectiveSharesQueryVariables,
  APITypes.ListIwiCollectiveSharesQuery
>;
export const getBulletin = /* GraphQL */ `query GetBulletin($id: ID!) {
  getBulletin(id: $id) {
    id
    title
    description
    image_url
    state
    createdAt
    targetIwiCollectiveID
    User {
      id
      email
      first_name
      last_name
      avatar_url
      phone_number
      account_verified
      iwiID
      user_type
      title
      job_title
      user_attribute
      status
      createdAt
      updatedAt
      userIwiCollectiveId
      __typename
    }
    targetIwiCollective {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    attachments {
      nextToken
      __typename
    }
    iwiShares {
      nextToken
      __typename
    }
    updatedAt
    bulletinUserId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBulletinQueryVariables,
  APITypes.GetBulletinQuery
>;
export const listBulletins = /* GraphQL */ `query ListBulletins(
  $filter: ModelBulletinFilterInput
  $limit: Int
  $nextToken: String
) {
  listBulletins(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      image_url
      state
      createdAt
      targetIwiCollectiveID
      updatedAt
      bulletinUserId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBulletinsQueryVariables,
  APITypes.ListBulletinsQuery
>;
export const bulletinsByTargetIwiCollective = /* GraphQL */ `query BulletinsByTargetIwiCollective(
  $targetIwiCollectiveID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBulletinFilterInput
  $limit: Int
  $nextToken: String
) {
  bulletinsByTargetIwiCollective(
    targetIwiCollectiveID: $targetIwiCollectiveID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      image_url
      state
      createdAt
      targetIwiCollectiveID
      updatedAt
      bulletinUserId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BulletinsByTargetIwiCollectiveQueryVariables,
  APITypes.BulletinsByTargetIwiCollectiveQuery
>;
export const getAttachment = /* GraphQL */ `query GetAttachment($id: ID!) {
  getAttachment(id: $id) {
    id
    fileUrl
    fileName
    fileType
    size
    createdAt
    bulletinID
    bulletin {
      id
      title
      description
      image_url
      state
      createdAt
      targetIwiCollectiveID
      updatedAt
      bulletinUserId
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAttachmentQueryVariables,
  APITypes.GetAttachmentQuery
>;
export const listAttachments = /* GraphQL */ `query ListAttachments(
  $filter: ModelAttachmentFilterInput
  $limit: Int
  $nextToken: String
) {
  listAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      fileUrl
      fileName
      fileType
      size
      createdAt
      bulletinID
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAttachmentsQueryVariables,
  APITypes.ListAttachmentsQuery
>;
export const attachmentsByBulletinID = /* GraphQL */ `query AttachmentsByBulletinID(
  $bulletinID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAttachmentFilterInput
  $limit: Int
  $nextToken: String
) {
  attachmentsByBulletinID(
    bulletinID: $bulletinID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      fileUrl
      fileName
      fileType
      size
      createdAt
      bulletinID
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AttachmentsByBulletinIDQueryVariables,
  APITypes.AttachmentsByBulletinIDQuery
>;
export const getIwiCollective = /* GraphQL */ `query GetIwiCollective($id: ID!) {
  getIwiCollective(id: $id) {
    id
    name
    Iwi {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIwiCollectiveQueryVariables,
  APITypes.GetIwiCollectiveQuery
>;
export const listIwiCollectives = /* GraphQL */ `query ListIwiCollectives(
  $filter: ModelIwiCollectiveFilterInput
  $limit: Int
  $nextToken: String
) {
  listIwiCollectives(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIwiCollectivesQueryVariables,
  APITypes.ListIwiCollectivesQuery
>;
export const getIwi = /* GraphQL */ `query GetIwi($id: ID!) {
  getIwi(id: $id) {
    id
    name
    Users {
      nextToken
      __typename
    }
    iwicollectiveID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetIwiQueryVariables, APITypes.GetIwiQuery>;
export const listIwis = /* GraphQL */ `query ListIwis($filter: ModelIwiFilterInput, $limit: Int, $nextToken: String) {
  listIwis(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      iwicollectiveID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListIwisQueryVariables, APITypes.ListIwisQuery>;
export const iwisByIwicollectiveID = /* GraphQL */ `query IwisByIwicollectiveID(
  $iwicollectiveID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelIwiFilterInput
  $limit: Int
  $nextToken: String
) {
  iwisByIwicollectiveID(
    iwicollectiveID: $iwicollectiveID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      iwicollectiveID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.IwisByIwicollectiveIDQueryVariables,
  APITypes.IwisByIwicollectiveIDQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    email
    first_name
    last_name
    avatar_url
    phone_number
    account_verified
    iwiID
    user_type
    title
    job_title
    user_attribute
    status
    IwiCollective {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    userIwiCollectiveId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      first_name
      last_name
      avatar_url
      phone_number
      account_verified
      iwiID
      user_type
      title
      job_title
      user_attribute
      status
      createdAt
      updatedAt
      userIwiCollectiveId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const usersByIwiID = /* GraphQL */ `query UsersByIwiID(
  $iwiID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  usersByIwiID(
    iwiID: $iwiID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      email
      first_name
      last_name
      avatar_url
      phone_number
      account_verified
      iwiID
      user_type
      title
      job_title
      user_attribute
      status
      createdAt
      updatedAt
      userIwiCollectiveId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsersByIwiIDQueryVariables,
  APITypes.UsersByIwiIDQuery
>;
