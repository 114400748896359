import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import SelectInput from "@src/components/forms/select-input";
import TextInputCombo from "@src/components/forms/text-input-combo";
import { iwiCollectiveApi } from "@src/api/iwi-collective-api";
import { iwiApi } from "@src/api/iwi-api";
import { Iwi, IwiCollective } from "@src/API";
import { useApiCall } from "@src/hooks/api-call";
import { apiSignUpQuery } from "@src/app/public-pages/auth/sign-up/modules/api-query";
import { userApi } from "@src/api/user-api";
import AlertModal, { AlertType } from "@src/components/overlays/alert-modal";
import LoadingSpinner from "@src/components/overlays/LoadingSpinner";
import validationSchema from "./create-user-validation";
import { AppUserObjects, UserAttribute, UserTypes } from "@src/constant/user";
import FileUploadDropZone, { IReadyFiles } from "@src/components/file-upload-drop-zone";
import { useFileStorage } from "@src/hooks/storage-hooks";
import { ETargetFileType } from "@src/types/storage";
import useAuthStore from "@src/stores/auth-store";

export type FormValues = {
  title: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  job_title: string;
  password: string;
  confirm_password: string;
  user_type?: UserTypes;
  iwi_collective: string;
  iwi: string;
  attribute: string;
  userIwiCollectiveId?: string;
  avatar_url: string;
};

const INITIAL_VALUES: FormValues = {
  title: "",
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  job_title: "",
  // Hardcoding because at the end user will reset their password
  password: "xyzXYZ@123#",
  confirm_password: "xyzXYZ@123#",
  iwi_collective: "",
  iwi: "",
  attribute: "",
  userIwiCollectiveId: "",
  avatar_url: "",
};

const UserForm: React.FC = () => {
  const { uploadFile } = useFileStorage();
  const { userProfile } = useAuthStore((state) => state);
  const [iwiCollectives, setIwiCollectives] = useState<IwiCollective[]>([]);
  const [iwiOptions, setIwiOptions] = useState<Iwi[]>([]);
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [message, setMessage] = useState<{ type: AlertType; message: string }>();
  const [primaryImageFiles, setPrimaryImageFiles] = useState<IReadyFiles[]>([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { execute: executeSignUp } = useApiCall(apiSignUpQuery, {
    onSuccess: () => {},
    onError: (error) => {
      console.log(JSON.stringify(error));
    },
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      const {
        first_name,
        last_name,
        phone,
        email,
        password,
        user_type,
        job_title,
        title,
        attribute: user_attribute,
        iwi,
        iwi_collective,
      } = values;
      setIsLoading(true);
      const { data: userResponse, errorMessage } = await executeSignUp(
        email,
        password,
        first_name,
        last_name,
        phone
      );
      if (!userResponse || errorMessage) {
        setMessage({ type: AlertType.error, message: errorMessage ?? "Something went wrong" });
        return;
      }

      if (primaryImageFiles.length > 0) {
        const fileName = `${ETargetFileType.USER_PROFILE_IMAGE}/${userProfile?.id}`;
        console.log({ fileName });
        // upload the files
        await uploadFile(primaryImageFiles[0].data, fileName);
        values.avatar_url = fileName;
      }

      // map user types
      const userType = Object.keys(AppUserObjects).find(
        (key) => AppUserObjects[key as keyof typeof AppUserObjects] === user_type
      );

      const payload = {
        id: userResponse.id,
        email: userResponse.email,
        first_name: userResponse.first_name,
        last_name: userResponse.last_name,
        phone_number: userResponse.phone_number,
        account_verified: userResponse.account_verified,
        user_type,
        job_title,
        title,
        ...(userType === UserTypes.Reader ? { user_attribute } : {}), // readers have user attribute
        ...(userType === UserTypes.Reader || userType === UserTypes.Router ? { iwiID: iwi } : null), // only readers and routers have iwi
        userIwiCollectiveId: iwi_collective,
        avatar_url: values.avatar_url,
      };
      // console.log("Create user payload:", payload);
      await userApi.createUserAdmin(payload);
      await userApi.initializeNewAccount({ id: userResponse.id });

      setMessage({ type: AlertType.success, message: "Profile created successfully!" });
      setIsLoading(false);
    } catch (error) {
      console.error("Error creating user:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function fetchIwiCollectives() {
      try {
        const collectives = await iwiCollectiveApi.listIwiCollectives();
        setIwiCollectives(collectives);
      } catch (error) {
        console.error("Error fetching Iwi Collectives:", error);
      }
    }

    fetchIwiCollectives();
  }, []);

  useEffect(() => {
    async function fetchIwiOptions() {
      if (selectedValue) {
        try {
          const iwi = await iwiApi.getIwisByIwicollectiveID(selectedValue);
          setIwiOptions(iwi);
        } catch (error) {
          console.error("Error fetching Iwi options:", error);
        }
      }
    }

    fetchIwiOptions();
  }, [selectedValue]);

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <Formik
        enableReinitialize
        initialValues={INITIAL_VALUES}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}>
        {({ values, setFieldValue }) => (
          <Form>
            {message && (
              <AlertModal
                message={message.message}
                title={message.type === AlertType.error ? "Error" : "Success"}
                onClose={() => {
                  setMessage(undefined);
                  if (message.type === AlertType.success) {
                    setTimeout(() => {
                      navigate(`/admin/users`);
                    }, 200);
                  }
                }}
                alertType={message.type}
              />
            )}
            <div className="space-y-12">
              <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900 mt-10">
                  Register new user
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">Create a new portal user</p>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <SelectInput
                      name="title"
                      label="Title"
                      options={[
                        "Mr",
                        "Mrs",
                        "Miss",
                        "Ms",
                        "Dr",
                        "Prof",
                        "Rev",
                        "Sir",
                        "Madam",
                        "Other",
                      ]}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <TextInputCombo name="first_name" type="text" label="First name" />
                  </div>
                  <div className="sm:col-span-3">
                    <TextInputCombo name="last_name" type="text" label="Last name" />
                  </div>
                  <div className="sm:col-span-3">
                    <TextInputCombo name="email" type="email" label="Email" />
                  </div>
                  <div className="sm:col-span-3">
                    <TextInputCombo name="phone" type="text" label="Phone" />
                  </div>
                  <div className="sm:col-span-3">
                    <TextInputCombo name="job_title" type="text" label="Job title" />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="space-y-12">
              <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900 mt-10">
                  Credentials
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">Secure the account</p>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <TextInputCombo name="password" type="password" label="Password" />
                  </div>
                  <div className="sm:col-span-3">
                    <TextInputCombo
                      name="confirm_password"
                      type="password"
                      label="Confirm Password"
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="space-y-12">
              <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900 mt-10">User Type</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Select user type and affiliations
                </p>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                  <div className="sm:col-span-6">
                    <SelectInput
                      name="user_type"
                      label="User Type"
                      options={Object.values(AppUserObjects)}
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="iwi-collective"
                      className="block text-base font-medium leading-6 text-gray-900">
                      Iwi Collective
                    </label>
                    <div className="mt-2">
                      <Field
                        as="select"
                        id="iwi-collective"
                        name="iwi_collective"
                        className="pl-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                        onChange={(e: { target: { value: any } }) => {
                          setFieldValue("iwi_collective", e.target.value);
                          setSelectedValue(e.target.value);
                        }}>
                        <option value="0">Select an option</option>
                        {iwiCollectives.map((collective) => (
                          <option key={collective.id} value={collective.id}>
                            {collective.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-12">
              {[UserTypes.Reader, UserTypes.Router].includes(values.user_type as UserTypes) ? (
                <div className="border-b border-gray-900/10 pb-12">
                  <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-8 ">
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="iwi"
                        className="block text-base font-medium leading-6 text-gray-900">
                        Iwi
                      </label>
                      <div className="mt-2">
                        <Field
                          as="select"
                          id="iwi"
                          onChange={(e: { target: { value: any } }) =>
                            setFieldValue("iwi", e.target.value)
                          }
                          name="iwi"
                          className="pl-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                          <option value="0">Select an option</option>
                          {iwiOptions.map((iwi) => (
                            <option key={iwi.id} value={iwi.id}>
                              {iwi.name}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>
                    {values.user_type === UserTypes.Reader ? (
                      <div className="sm:col-span-1">
                        <SelectInput
                          name="attribute"
                          label="User attribute"
                          options={Object.values(UserAttribute)}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="border-b border-gray-900/10 pb-12 py-5">
              <article className="sm:col-span-6 max-w-5xl">
                <FileUploadDropZone
                  label={"Upload Profile Image"}
                  files={primaryImageFiles}
                  isMultiple={false}
                  setFiles={setPrimaryImageFiles}
                  onFileDelete={() =>
                    setPrimaryImageFiles(
                      primaryImageFiles.filter((file) => file.uuid !== file.uuid)
                    )
                  }
                />
              </article>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UserForm;
