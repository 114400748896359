import React from 'react'
import UpdateProfileForm from './modules/update-profile'

const UpdateUserProfile = () => {
    return (
        <div>
            <UpdateProfileForm />
        </div>
    )
}

export default UpdateUserProfile
