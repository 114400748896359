import React from "react";
import { Link } from "react-router-dom";
import { NavigationItem } from "@src/components/navigation/modules/nav-types";

interface MenuItemProps {
  item: NavigationItem;
  isActive: boolean;
}

const MenuItem: React.FC<MenuItemProps> = (props) => {
  const [hover, setHover] = React.useState<boolean>(false);

  return (
    <Link
      to={props.item.url}
      className="text-sm font-normal tracking-tight leading-6 relative px-1 inline-block min-w-[60px] text-center border-b border-transparent mr-1"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <span
        className={`inset-0 flex items-center justify-center transition-opacity duration-300 ${
          hover ? "opacity-0" : "opacity-100"
        } whitespace-nowrap 
         ${props.isActive ? "border-b border-black" : ""}
        `}>
        {props.item.name}
      </span>
      <span
        className={`absolute inset-0 flex items-center justify-center transition-opacity duration-500 ${
          hover ? "opacity-100 border-b border-black" : "opacity-0 border-transparent"
        } whitespace-nowrap`}>
        {props.item.translation}
      </span>
    </Link>
  );
};

export default MenuItem;
