import { ReactComponent as LandscapeLogo } from "@src/assets/logo/te-manawa-ō-ngā-wai-landscape-logo-full-color-rgb.svg";
import { useCallback, useEffect, useState } from "react";
import { signOut } from "aws-amplify/auth";

import LoadingSpinner from "@src/components/overlays/LoadingSpinner";
import LoginForm from "@src/app/public-pages/auth/login/modules/login-form";
import useAuthStore from "@src/stores/auth-store";

export const LoginPage = () => {
  const { logoutSuccess } = useAuthStore((state) => state);
  // modal states
  const [isLoading, setIsLoading] = useState(false);

  const checkUser = useCallback(async () => {
    setIsLoading(true);
    try {
      await signOut();
      logoutSuccess();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [logoutSuccess]);

  useEffect(() => {
    checkUser();
  }, [checkUser]);

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <LandscapeLogo className="mx-auto h-30 w-auto" />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <LoginForm />
        </div>
      </div>
    </>
  );
};

export default LoginPage;
