import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ReactComponent as LandscapeLogo } from "@src/assets/logo/tmonw-landscape-logo-white-rgb.svg";
import {
  ArrowRightStartOnRectangleIcon,
  Cog6ToothIcon,
  DocumentIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import ProfileAvatar from "@src/components/ui/ProfileAvatar";
import { stringUtil } from "@src/util/string-util";
import useAdminUiStore from "@src/stores/admin-ui-store";
import { signOut } from "aws-amplify/auth";
import LoadingSpinner from "@src/components/overlays/LoadingSpinner";
import useAuthStore from "@src/stores/auth-store";
import { storageUtils } from "@src/util/storage-utils";
import { UserTypes } from "@src/constant/user";
import { iwiApi } from "@src/api/iwi-api";
import { User } from "lucide-react";
import { userUtil } from "@src/util/user-util";

const navigation = [
  {
    name: "Whare Mauhanga",
    icon: DocumentIcon,
    url: "/user",
    count: 0,
  },
  // admin menu items
  {
    name: "Users",
    isAdminItem: true,
    icon: UserGroupIcon,
    url: "/admin/users",
    count: 0,
  },
];

const classNames = (...classes: any[]) => {
  return classes.filter(Boolean).join(" ");
};

export const SideNav = () => {
  const navigate = useNavigate();
  const { logoutSuccess, userProfile, userGroups, isAdmin } = useAuthStore((state) => state);
  // NOTE: admin menu items are filtered here
  const [isUserAdmin, setIsUserAdmin] = useState<boolean>(userGroups.includes("Admins"));
  const [isLoading, setIsLoading] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState("");
  const [iwiName, setIwiName] = useState("");
  const { selectedSideNavMenuItem, setSelectedSideNavMenuItem } = useAdminUiStore();
  // Filter the navigation based on admin status
  const filteredNavigation = navigation.filter((item) => !item.isAdminItem || isUserAdmin);

  // TODO: these should happen in api
  useEffect(() => {
    const getImageFromKey = async () => {
      const imageFromKey = await storageUtils.getFileAccessUrl(userProfile?.avatar_url ?? "");
      setAvatarUrl(imageFromKey.url.href);
    };
    getImageFromKey();
  }, [userProfile]);

  useEffect(() => {
    const getIwiById = async () => {
      if (userProfile?.iwiID) {
        const iwi = await iwiApi.getIwi(userProfile?.iwiID);
        setIwiName(iwi?.name);
      }
    };
    getIwiById();
  }, [userProfile]);

  const handleMenuClick = (url: string, selectedItem: string) => {
    setSelectedSideNavMenuItem(selectedItem);
    navigate(url);
  };

  // Select the current menu item based on the current path
  useEffect(() => {
    const path = window.location.pathname;
    const item = navigation.find((item) => path.includes(item.url));
    if (item) {
      setSelectedSideNavMenuItem(item.name);
    }
  }, [setSelectedSideNavMenuItem]);

  useEffect(() => {
    console.log(userProfile);
  }, [userProfile]);

  return (
    <div className="flex-1 flex flex-col min-h-0 bg-brand-primary">
      {isLoading && <LoadingSpinner />}
      <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
        <div className="flex items-center flex-shrink-0 px-4">
          <LandscapeLogo className="h-11 w-auto cursor-pointer" onClick={() => navigate("/user")} />
        </div>
        <nav className="mt-5 flex-1 px-2 space-y-1" aria-label="Sidebar">
          {filteredNavigation.map((item) => (
            <div
              key={item.name}
              className={classNames(
                selectedSideNavMenuItem === item.name
                  ? "bg-indigo-800 text-white"
                  : "text-amber-50 hover:bg-indigo-600 hover:bg-opacity-75",
                "group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer"
              )}
              onClick={() => handleMenuClick(item.url, item.name)}>
              <item.icon className="mr-3 flex-shrink-0 h-6 w-6 text-amber-50" aria-hidden="true" />
              <span className="flex-1">{item.name}</span>
              {item.count ? (
                <span
                  className={classNames(
                    "bg-indigo-800",
                    "ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full"
                  )}>
                  {item.count}
                </span>
              ) : null}
            </div>
          ))}
        </nav>
      </div>

      <div className="flex-shrink-0 flex border-t border-indigo-800 p-4">
        <div className="flex-shrink-0 group block w-full">
          <div className="flex items-center">
            <div
              onClick={() => {
                console.log(userProfile);
                console.log(userGroups);
              }}>
              <ProfileAvatar access_image_url={avatarUrl ?? ""} />
            </div>
            <div className="ml-3">
              <div className="flex items-center justify-between">
                <Link
                  to="/user/user/profile"
                  className="text-sm font-medium text-white ml-1 underline">
                  {stringUtil.shortenName(
                    userProfile?.first_name ?? "",
                    userProfile?.last_name ?? ""
                  )}
                </Link>
              </div>

              {userProfile?.user_type === null ? null : (
                <div className="flex items-center gap-2">
                  <div className="flex items-center gap-1">
                    <span className="inline-flex items-center rounded-md p-1.5 w-14 text-xs font-medium text-indigo-200 ring-1 ring-inset ring-gray-500/40 my-1 cursor-pointer relative">
                      {userProfile?.user_type
                        ? userUtil.toDisplayUserType(userProfile.user_type)
                        : "unknown"}
                      <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-32 bg-black text-white text-center text-xs rounded py-1 opacity-0 transition-opacity duration-300 tooltip-text">
                        {userProfile?.user_type}
                      </span>
                    </span>
                    <span className="inline-flex items-center rounded-md p-1.5 text-xs font-medium text-indigo-200 ring-1 ring-inset ring-gray-500/40 my-1 cursor-pointer relative">
                      {userProfile?.user_type === "Writer"
                        ? userProfile?.IwiCollective?.name
                        : iwiName}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="ml-auto text-indigo-200 hover:text-white">
              <ArrowRightStartOnRectangleIcon
                className="w-6 h-6 cursor-pointer"
                onClick={async () => {
                  try {
                    setIsLoading(true);
                    await signOut();
                    logoutSuccess();
                    navigate("/auth/login");
                  } catch (e) {
                    console.log(e);
                  } finally {
                    setIsLoading(false);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
