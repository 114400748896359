import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";

export interface IReadyFiles {
  fileName?: string;
  id?: string;
  fileType?: string;
  size?: string;
  fileUrl?: string;
  file: {
    name: string;
    size: number;
    type: string;
    preview?: string;
  };
  data: File;
  uuid: string;
  // Other properties as needed
}

/*

files and setFiles are declared in parent
Parent component handles the upload
This component displays the files, and supplies the files to
the parent component for upload

*/

interface IFileUploaderProps {
  label: string;
  files: IReadyFiles[];
  setFiles: Dispatch<SetStateAction<IReadyFiles[]>>;
  isMultiple: boolean;
  speakerAvatarUrl?: string;
  onFileDelete: (uuid: string) => void;
}

const FileUploadDropZone: React.FC<IFileUploaderProps> = ({ label, setFiles, files, isMultiple, onFileDelete, speakerAvatarUrl }) => {

  const [filePreviews, setFilePreviews] = useState<string[]>([]);

  useEffect(() => {
    if (files.length > 0) {
      const previews: string[] = [];
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {
            previews.push(reader.result as string);
            if (previews.length === files.length) {
              setFilePreviews(previews);
            }
          }
        };
        reader.readAsDataURL(file.data);
      });
    } else {
      setFilePreviews([]);
    }
  }, [files]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const mappedFiles = acceptedFiles.map((file) => ({
        file: {
          name: file.name,
          size: file.size,
          type: file.type,
        },
        data: file, // Add this line to store the actual File object
        uuid: uuidv4(),
      }));

      if (isMultiple) {
        setFiles((curr) => [...curr, ...mappedFiles]);
      } else {
        setFiles([mappedFiles[0]]); // Assuming you want just one file, adjust as necessary
      }
    },
    [isMultiple, setFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: isMultiple,
  });

  return (
    <div {...getRootProps()}>
      <label className="block text-sm font-semibold leading-6 text-gray-600 mb-4">{label}</label>
      <div
        id={"file-uploader"}
        className={`mt-1 border-2 border-gray-300 border-dashed rounded-md px-6 pt-5 pb-6 flex justify-center ${isDragActive ? "bg-green-200" : ""}`}>
        <div className="space-y-1 text-center">
          {!isMultiple && filePreviews.length ? filePreviews.slice(-1).map((preview, index) => (
            <img
              key={`${preview}-${speakerAvatarUrl}-${index}`}
              src={preview}
              alt={`Preview ${index}`}
              className="h-40 w-auto mb-4"
            />
          )) : (
            speakerAvatarUrl ? (
              <img
                src={speakerAvatarUrl}
                alt={`Speaker Avatar`}
                className="h-40 w-auto mb-4"
              />
            ) : null
          )}


          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true">
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
              <span>Upload a file</span>
              <input {...getInputProps()} id="file-upload" name="file-upload" type="file" className="sr-only" />
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
        </div>
      </div>
    </div>
  );
};

export default FileUploadDropZone;
