import { IReadyFiles } from "@src/components/file-upload-drop-zone";

interface BulletinFilesListProps {
  attachments: IReadyFiles[];
  onDelete: (index: number) => void;
}

const BulletinFilesList = (props: BulletinFilesListProps) => {
  return (
    <ul className="my-1 divide-y divide-gray-200">
      {props.attachments.map((file, index) => (
        <li key={index} className="py-4">
          <div className="flex items-center justify-between w-full space-x-4 pr-3">
            <div className="flex-shrink-0">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                aria-hidden="true"
                className="text-green-600"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm6.905 9.97a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.72-1.72V18a.75.75 0 001.5 0v-4.19l1.72 1.72a.75.75 0 101.06-1.06l-3-3z"
                  clipRule="evenodd"></path>
                <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z"></path>
              </svg>
            </div>
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-gray-900 truncate">{file.uuid}</p>
              <p className="text-sm text-gray-500 truncate">@{file.file.type}</p>
            </div>
            <div className="flex-shrink-0">
              <div
                className="cursor-pointer inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                onClick={() => props.onDelete(index)}>
                Remove
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default BulletinFilesList;
